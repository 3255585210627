import gql from "graphql-tag";

const ALERTS_QUERY = (clientId) => gql`
query notificationSettings {
    notificationSettings {
        id
        title
        isActive
    }
  }
`;

export default ALERTS_QUERY;


