import React, { useState, useContext, useEffect } from "react"
import Layout from "../../components/layout"
import Indicator from "../../components/entityindicators/indicator"
import Table from "../../components/table/table"
import TopButtons from "../../components/topBtn/top-btn";
import BALANCE_CHECK_QUERY from "../../queries/balance_check";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext";
import { baseEndpoint, convertDayString, formatNumber, formatCellNumber, UnitSymbols } from "../../utils/utils";
import axios from 'axios'
import { useQuery as useReactQuery } from 'react-query'
import TextField from '@material-ui/core/TextField'
import MonthYearPicker from '../../components/monthpicker/monthpicker'
import Skeleton from '@material-ui/lab/Skeleton'
import { ReactTabulator } from 'react-tabulator'
import Button from '@material-ui/core/Button'

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


let cemetref = null; 
let opctref = null; 
var formatCell = function(cell, formatterParams, onRendered){
  //cell - the cell component
  //formatterParams - parameters set for the column
  //onRendered - function to call when the formatter has been rendered
      var field = cell.getField();
      if (cell.getData().bad.includes(field)) {
        cell.getRow().getElement().style.color = "indianred";
        cell.getRow()._row.cells.forEach(c=> c.element.style.color = "indianred")
        cell.getElement().style.color = "red";
      }

  return cell.getValue(); //return the contents of the cell;
}

//CEME Table
let columnsCeme = [
  { title: "Mês", field: "mes_referencia", isActive : true},
  { title: "Ficheiro", field: "file_name", headerFilter:true, isActive : true},
  //,
  { title: "Opc id", field: "opc_id" , headerFilter:true, isActive : true},
  // { title: "opcentityname", field: "opcentityname" , headerFilter:true},
  { title: "Ceme id", field: "ceme_id" , headerFilter:true, isActive : true},
  // { title: "cemeentityname", field: "cemeentityname" , headerFilter:true},
  { title: "Energia total" + UnitSymbols.energy, field: "energia_total", isActive : true, formatter:formatCellNumber },
  { title: "Duração total" + UnitSymbols.duration, field: "duracao_total", isActive : true, formatter:formatCellNumber },
  { title: "Número carregamentos", field: "numero_carregamentos", isActive : true },
  { title: "Preço opc total"+ UnitSymbols.value, field: "preco_opc_total", isActive : true },
];

// OPC Table
let getFullData = (data) =>
{
  if(!data) {
    return []
  }
  data = data.data
  let entities = data.opcEntities

  
  var usages = data.opcs;
  //ids unicos de usage
  var usageIds = [...new Set(usages.map(u=> u.idSubUsage))];

  // var uniques =  usageIds.map(id => {return usages.find(x=>x.idusage == id)});

  //para cada idUsage, ir buscar as desse id
  //fazer pop para obter o ultimo da lista
  var uniques = usageIds
  .map(idSubUsage => {
    var latest = usages.filter(u=> u.idSubUsage == idSubUsage).pop();
    return latest;
  })

  return uniques.map(x =>
  {
      let res = x;
      res.bad = [];
      let entity = entities.find(e => e.code == x.idServiceProvider);
      res.entityname = entity ? entity.name : "";
      res.idDay = x.idDay.substring(0,10)
      if (!entity) { return res; }
      return res;
  })
}

let columnsOpc = [
{ title: "Id utilização", field: "idUsage", headerFilter:true },
{ title: "Id Provedor", field: "idServiceProvider", headerFilter:true },
{ title: "Nº Id Externo", field: "idExternalNumber", headerFilter:true },
{ title: "Nº Id Interno", field: "idInternalNumber", headerFilter:true },
{ title: "Id Carregador", field: "idChargingStation", headerFilter:true },
{ title: "Id EVSE", field: "idEVSE", headerFilter:true },
{ title: "timestamp inicio", field: "startTimestamp", headerFilter:true },
{ title: "timestamp fim", field: "stopTimestamp", headerFilter:true },
{ title: "Duração Total" + UnitSymbols.duration, field: "totalDuration" },
{ title: "Energia Total Transacao" + UnitSymbols.energy, field: "energia_total_transacao" },
{ title: "Id subutilização", field: "idSubUsage" },
{ title: "Id Dia", field: "idDay", headerFilter:true  },
{ title: "Duração Período", field: "periodDuration" },
{ title: "Preco Opc" + UnitSymbols.value, field: "preco_opc" },
{ title: "Preco Unitario Opc Tempo" + UnitSymbols.value, field: "preco_unitario_opc_tempo" },
{ title: "Preco Opc Tempo" + UnitSymbols.value, field: "preco_opc_tempo" },
{ title: "Energia Total Periodo" + UnitSymbols.energy, field: "energia_total_periodo" }
];


const BalanceCheck = () => {
  let columns = [
    { title: "Ficheiro", field: "file_name", headerFilter: true, isActive: true },
    { title: "Data de Emissão", field: "emission_date", isActive: true },
    { title: "NIF", field: "nif", headerFilter: true, isActive: true },
    { title: "CEME", field: "ceme_entity.name", isActive: true },
    // { title: "OPC", field: "opc_entity.name", isActive: true },
    { title: "Preço Total" + UnitSymbols.value, field: "totalPrice", isActive: true },
  ];

  let query = BALANCE_CHECK_QUERY()
  const { data, loading, error } = useQuery(query);

  // OPCTable
  const [state, setState] = useContext(AppContext);
  let clientId = state.currentClient.id
    
  // useEffect(() => {!searchObj.searchdate && changeSearch()})

  let DataOpcQuery = () =>{
    let { isLoading, error, data } = useReactQuery(['opcs', {clientId: clientId}], () =>
    axios(baseEndpoint + '/opcquery?clientId='+clientId, state.queryAuthHeaders()))

    return {data:data, isLoading:isLoading}
  } 
  var dataOpcQuery = DataOpcQuery()
  var dataOpc = dataOpcQuery.data
  var isLoadingOpc = dataOpcQuery.isLoading
  var fulldata = getFullData(dataOpc)

  //search
  const [searchObj, setSearchObj] = useState({})
  const [fatura, setFatura] = useState({})

  
  //CEME Table~
  var changeSearch= (x,i) => {
    setSearchObj({
      searchdate: document.querySelector('input[name=searchdateinput]').value,
      searchceme: document.querySelector('#select-ceme + input').value,
      searchvalue: document.querySelector('input[name=searchvalue]').value,
    })
  }

  var clearInputs= (x,i) => {
    document.querySelector('input[name=searchdateinput]').value = ""
    document.querySelector('#select-ceme + input').value = ""
    document.querySelector('input[name=searchvalue]').value = ""
  }

  let DataCemeQuery = () =>{
    const { isLoading, error, data } = useReactQuery(['cemes', {clientId: clientId}], () =>
    axios(baseEndpoint + '/cemequery?clientId='+clientId, state.queryAuthHeaders()))

    return {data:data, isLoading:isLoading}
  } 
  var dataCemeQuery = DataCemeQuery();
  var dataCeme = dataCemeQuery.data
  var isLoadingCeme = dataCemeQuery.isLoading

  var dataQueryCeme = dataCeme ? {cemes: dataCeme.data.map(ceme => {
    
    ceme.bad = []
    if ((!isNaN(ceme.preco_opc_total) && !isNaN(searchObj.searchvalue))
          && ceme.preco_opc_total != searchObj.searchvalue) {
            ceme.bad.push("preco_opc_total");
    }

    return ceme
  }) } : {cemes: []};
  
  var entidadesCeme = [...new Set(dataQueryCeme.cemes.map(x => x.ceme_id))]
  if(dataQueryCeme.cemes.length)
  {
    dataQueryCeme.cemes = dataQueryCeme.cemes.filter(x=>x.mes_referencia)
  }

  let tableHeightCeme = (dataQueryCeme ? dataQueryCeme.cemes : []).length >= 10 ? 500 : ((dataQueryCeme ? dataQueryCeme.cemes : []).length * 51) //+ 35 + 41 + 10 footer and title header
  let tableHeightCemePx = tableHeightCeme+"px"

  let tableHeightOpc = (fulldata).length >= 10 ? 500 : (fulldata.length * 51) //+ 35 + 41 + 10 footer and title header
  let tableHeightOpcPx = tableHeightOpc+"px"

  function clickBill (row){
    //todo fix
    return;
      setFatura({
        searchdate: row.emission_date,
        searchceme:  row.ceme_entity.code,
        searchvalue: row.totalPrice,
      })
    changeSearch()
  }

  if(searchObj.searchceme){
    fulldata = fulldata.filter(x=>x.idServiceProvider == searchObj.searchceme)
    dataQueryCeme.cemes = dataQueryCeme.cemes.filter(x=> x.ceme_id == searchObj.searchceme)
  }
  if(searchObj.searchdate){
    let currentMes = searchObj.searchdate.substring(0,7).replace('-','')
    fulldata = fulldata.filter(x=> x.idDay.includes(searchObj.searchdate.substring(0,7))) //primeiros 7 são ano e mês: yyyy-mm
    dataQueryCeme.cemes = dataQueryCeme.cemes.filter(x=>x.mes_referencia == currentMes)
  }
  
  let valorTotalOpc = fulldata.length ? fulldata.map(x=>parseFloat(x.preco_opc)).reduce((x, y) => (x + y),0.00) : 0;
  valorTotalOpc = formatNumber(valorTotalOpc);

  return (
  
        <span>
          <div>
            <TopButtons buttons = {['Importar PDF', 'Exportar PDF']}/>
            <Table
              // ref={ref => {(tref = ref);console.log(tref);}}
              isLoading={loading}
              mode="allEnabled"
              boxtitle="Lista de Faturas"
              columns={columns}
              data={data ? data.bills.map(node => {
                return JSON.parse(JSON.stringify(node))
              }) : []}
              itemClicker={clickBill}>
            </Table>
          </div>
          <SearchInputs clearInputs={clearInputs} changeSearch={() => {setFatura({});changeSearch();}} fatura={fatura} entidadesCeme={entidadesCeme}/>
          
          {/* Tabela OPC */}
          <div>
            <div className={"custom-table-margin table padded-box box-shadow no-filter"}>
            <style>
                {
                    `.up-arrow:after{content:'🠕'}
                     .down-arrow:after{content:'🠗'}
                      `
                }
            </style>

            {
              isLoadingOpc ?
              <>
                  <Skeleton className="tableSkeleton" variant="text" />
              </>
              :
              <div>
                <div>
                    <div className="table-title-bar" >

                    <div className="table-title-text">{"OPC Mês"}</div>
                  </div>
                  <div className={valorTotalOpc > searchObj.searchvalue ? "up-arrow" : valorTotalOpc < searchObj.searchvalue ? "down-arrow" : ""} style={{color: searchObj.searchvalue && valorTotalOpc != searchObj.searchvalue ? "red" : "", fontSize:"large"}}>{"Valor total OPC: " + valorTotalOpc + "€ "}</div>

                  <ReactTabulator
                      data={fulldata.filter(x=> x.file_name.includes("FINAL"))}
                      ref={ref => (opctref = ref)}
                      columns={columnsOpc}
                      tooltips={true}
                      options={{
                          layout: "fitDataFill", height: tableHeightOpcPx, pagination: "local",locale:"pt", fitColumns:true,
                          headerFilterPlaceholder:"",
                          downloadDataFormatter: (data) => data,
                          downloadReady: (fileContents, blob) => blob,
                          langs:{
                              "pt":{
                                  // "columns":{
                                  //     "name":"Name", //replace the title of column name with the value "Name";
                                  // },
                                  "pagination":{
                                      "first":"Primeira",
                                      "first_title":"Primeira página",
                                      "last":"Última",
                                      "last_title":"Última página",
                                      "prev":"<",
                                      "prev_title":"Página anterior",
                                      "next":">",
                                      "next_title":"Próxima página",
                                  },
                              }
                          },
                      }}
                  />
                </div>
              </div>
            }
            </div>
          </div>
          {/* Tabela CEME Mês */}
          <div>
            <div className={"custom-table-margin table padded-box box-shadow no-filter"}>
            {
              isLoadingCeme ?
              <>
                  <Skeleton className="tableSkeleton" variant="text" />
              </>
              :
              <div><div className="table-title-bar" >

                <div className="table-title-text">{"Cemes Mês"}</div>
              </div>
              <ReactTabulator
                  data={dataQueryCeme ? dataQueryCeme.cemes : []}
                  ref={ref => (cemetref = ref)}
                  columns={columnsCeme}
                  tooltips={true}
                  options={{
                      layout: "fitDataFill", height: tableHeightCemePx, pagination: "local",locale:"pt", fitColumns:true,
                      headerFilterPlaceholder:"",
                      downloadDataFormatter: (data) => data,
                      downloadReady: (fileContents, blob) => blob,
                      langs:{
                          "pt":{
                              // "columns":{
                              //     "name":"Name", //replace the title of column name with the value "Name";
                              // },
                              "pagination":{
                                  "first":"Primeira",
                                  "first_title":"Primeira página",
                                  "last":"Última",
                                  "last_title":"Última página",
                                  "prev":"<",
                                  "prev_title":"Página anterior",
                                  "next":">",
                                  "next_title":"Próxima página",
                              },
                          }
                      },
                  }}
                  />
              </div>
            }
            </div>
          </div>
          {/* <div className="indicatorParentHolder">
            <Indicator dataQuery={data} isLoading={loading} type={"bill"} title={"Fatura"} hasLeftMargin={false} index={0} />
            <Indicator dataQuery={data} isLoading={loading} hasLeftMargin={false} type={"bill"} title={"CEME"} hasLeftMargin={false} index={1} />
            <Indicator dataQuery={data} isLoading={loading}  hasLeftMargin={false} type={"bill"} title={"OPC"} hasLeftMargin={false} index={2} />
          </div> */}
        </span>
   
  )
}

const SearchInputs = ({changeSearch,clearInputs, fatura, entidadesCeme}) =>{
  return <div className="searchInputs">
    <style>
        {
            `.searchInputs > * {
                margin-left:5px;
              }
              .searchInputs{
                display: inline-flex;
                justify-content: center;
              }
              `
        }
    </style>     
  
  
  
        {/* <TextField id="standard-basic" name="searchdate"  InputLabelProps={{ shrink: true }} onKeyUp={changeSearch} label="Mês" type="date" /> */}
        {/* {console.log(fatura.searchceme)} */}
        <MonthYearPicker name="searchdate" label="Mês e Ano"  defaultValue={fatura.searchdate && new Date(fatura.searchdate) || null} InputLabelProps={{ shrink: true }}   change={changeSearch}/>
        {/* <TextField id="select-basic" name="searchceme" select value={fatura.searchceme}  InputLabelProps={{ shrink: true }} onChange={(event) =>{ document.querySelector('input[name=searchceme]').value = event.target.value; changeSearch()} } label="Código CEME" style={{width:"150px"}}>
        {entidadesCeme.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField> */}

        <FormControl style={{width:"150px"}}>
          <InputLabel shrink  id="select-ceme-label">Código CEME</InputLabel>
          <Select
            labelId="select-ceme-label"
            id="select-ceme"
            // defaultValue=""
            value={fatura.searchceme ? fatura.searchceme + "" : undefined}
            onChange={(event) =>{ document.querySelector('#select-ceme + input').value = event.target.value; changeSearch()}}
            InputLabelProps={{ shrink: true }}
          >
          {entidadesCeme.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          </Select>
      </FormControl>
        <TextField id="standard-basic" name="searchvalue"    value={fatura.searchvalue}  InputLabelProps={{ shrink: true }} onKeyUp={changeSearch} label="Valor" />

     {/* <Button color="primary" onClick={changeSearch}>
      Pesquisar
    </Button> */}
    {/* <Button color="primary" onClick={() => {clearInputs(); changeSearch();}}>
      Limpar
    </Button> */}
  </div>
}


export default BalanceCheck