import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;

export function removeOPC(state , ceme, success, failure) {

    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
      headers: {
        Authorization: currentToken,
      } 
    }

    console.log(currentToken)
    axios.delete(baseEndpoint + endpoints.CREATE_CEME.value + ceme.id, config)
    .then((response) => {
        success()
    })
    .catch((error) => {
        failure(error)
    })
}