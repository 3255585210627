/**
 * Layout component that query some menu holders
 */
import React, { useState } from "react"
import cancelImg from '../../static/cancel.svg'
import addFileImg from '../../static/add_file.svg'
import '../../css/variables.css'
import '../../components/modal/modal.css'
import CancelIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, FormLabel, Grid, Input, IconButton } from '@material-ui/core';

function CustomModal({ title, showModal, setModalShow }) {

    /*const [user, setUser] = ({
        Name: "",
        Email: "",
        PhoneNumber: "",
        Role: "",
        UserID: "",
        Department: ""
    })*/

    function saveUser(){ 

    }

    const handleClose = () => {
        setModalShow(false);
    };

    const dialog = (
        <Dialog
            open={showModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <span className="alert-dialog-title">{title}</span>
                <IconButton className="close-img-filter">
                    <CancelIcon color="primary" fontSize="small" src={cancelImg} onClick={() => handleClose()} />
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content">
                <div className={"container"}>
                    <form style={{ display: "block", width: "100%" }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <div style={{ display: "block", margin: "25px"}}>
                                    <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                                        <FormLabel className={"span-text"}>Nome:</FormLabel>
                                        <Input className="input-txt" type="text" name="Name" />
                                    </div>
                                    <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                                        <FormLabel className={"span-text"}>Email:</FormLabel>
                                        <Input className="input-txt" type="text" name="Email" />
                                    </div>
                                    <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                                        <FormLabel className={"span-text"}>Telemóvel:</FormLabel>
                                        <Input className="input-txt" type="phone" name="Name" />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ display: "inline-flex" }}>
                                    <div style={{ display: "block"}}>
                                        <div style={{ display: "block", margin: "25px" }}>
                                            <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                                                <FormLabel className={"span-text"}>Cargo:</FormLabel>
                                                <Input className="input-txt" type="text" name="Cargo" />
                                            </div>
                                            <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                                                <FormLabel className={"span-text"}>Telemóvel:</FormLabel>
                                                <Input className="input-txt" type="text" name="UserID" />
                                            </div>
                                            <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                                                <FormLabel className={"span-text"}>Departamento:</FormLabel>
                                                <Input className="input-txt" type="text" name="Departamento" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div style={{ display: "inline-flex", cursor: "pointer", margin: "25px", width: "25%" }}>
                            <img className="add-file-img" src={addFileImg} />
                            <input className="add-file-txt" placeholder="Adicionar Ficheiros" type="file" accept="image/png, image/jpeg" name="AddFile" />
                        </div>
                    </form>
                    <div className="divider"></div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="submit-btn" onClick={() => handleClose()} variant="contained" color="primary">Concluir</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {dialog}
        </>
    )
}

export default CustomModal