import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;


export function createDatePrice(data, state, success, failure) {
    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
        headers: {
            Authorization: currentToken
        }
    }
    
    axios.post(baseEndpoint + endpoints.DATE_PRICE.value, data, config)
    .then((data) => {
        success()
    }).catch((error) => {
        failure()
    })
}