import gql from "graphql-tag";

const CHARGERS_QUERY = (clientId) => gql`
query PC {
  pcs (where: 
    { client: 
        {
          id: ${clientId}
        }
    }
  ){
    id
      name
      IdEVSE
  }
}
`;

export default CHARGERS_QUERY;