import React from "react"
import Constants from "../constants"
import cogoToast from 'cogo-toast';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { baseEndpoint } from "../utils/utils";
import { QueryClient, QueryClientProvider } from 'react-query'


// APPState
var appStateHistory = null;
export function initAppState(history) {
  if(!appStateHistory) {
    appStateHistory = history
  }
}
let appState = null;
let userStorage = null;
let user = null;
let clients = null;
let currentToken = null;
let is404 = false;
let isPartnerEmployee = null;
let queryAuthHeaders = () => {
  var authLink = {
      headers: {
        Authorization: currentToken ? `Bearer ${currentToken}` : "",
      }
    };
  return authLink
}

export function initState() {
  console.log("INITING STATE")
  userStorage = localStorage.getItem(Constants.userKey)
  user = userStorage != null ? JSON.parse(userStorage) : null;
  currentToken = user ? user.jwt : null
  //console.log("token: "+currentToken)
  //console.log(user)
  let clients = user != null ? user.clients : null
  let lastClient = JSON.parse(localStorage.getItem(Constants.lastClient))
  isPartnerEmployee = user != null ? user.clients && user.clients.length > 0 && user.partners && user.partners.length > 0 : false
  //console.log(isPartnerEmployee)
  appState = {
    isLoading: false,
    currentUser: user,
    isPartnerEmployee: isPartnerEmployee,
    currentClient: lastClient ? lastClient : (clients && clients.length > 0) ? clients[0] : null,//clients == null ? null : clients.length > 0 ? clients[0] : null
    currentToken: currentToken,
    queryAuthHeaders: queryAuthHeaders
  }
}

if (typeof localStorage !== `undefined`) {
    initState()
}

export function logoutUser(contextState, setState) {
  localStorage.removeItem(Constants.userKey);
  localStorage.removeItem(Constants.lastClient);
  setState({ ...contextState, currentUser: null, currentClient: null, isPartnerEmployee: false })
  appStateHistory.push("/")
  //navigate('/')
}

// TODO Set a show loading hide loading function here
// This function checks if user is unauthorized...
// If so, shows popup says that user has been disconnected
// due to invalid session, and throws user to the login page
export function checkIfUnauthorized(statusCode, contextState, setState) {
  if(statusCode == 401 || statusCode == 403) {
    // We have to sign out user
    logoutUser(contextState, setState)
    cogoToast.warn('Atenção a sua sessão expirou. Por favor façar login novamente');
    return true
  }
  return false
}



// Appolo client
const cache = new InMemoryCache();
var link = new HttpLink({
  uri: `${baseEndpoint}/graphql`
});


export var apolloClient = null;
var authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: currentToken ? `Bearer ${currentToken}` : "",
    }
  }
});
export const getApolloInstance = (state, setState) => {
  if(!apolloClient) {
    apolloClient = new ApolloClient({
      cache: cache,
      link: from([ 
        new ApolloLink((operation, forward) => {
          operation.setContext(({ headers }) => ({ headers: {
            authorization: currentToken ? `Bearer ${currentToken}` : "",
            ...headers
          }}));
          return forward(operation);
        })
        ,
        onError(({ response, operation, networkError, graphQLErrors }) => {
          if(graphQLErrors) {
            var unauthorized = false
            graphQLErrors.forEach( error => {
              if(error.extensions.exception.output) {
                let statusCode = error.extensions.exception.output.statusCode
                if((statusCode == 403 || statusCode == 401) && !unauthorized) {
                  unauthorized = true
                  checkIfUnauthorized(statusCode, state, setState)
                }
              }
            })
          } 
        })
        , link ])
    });
  }
  return apolloClient
}

// query client
// const cache = new InMemoryCache();


export var queryClient = null;

export const getQueryClientInstance = (state, setState) => {
  if(!queryClient) {
    queryClient = new QueryClient(
    // refetch
      {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    }
    )
    
    // queryClient.invalidateQueries({
    //   predicate: query =>
    //     query.queryKey[0] === 'cemes' && query.queryKey[1]?.clientId != clientId,
    // }) // invalida query, devia ser com base no param client
  }
  return queryClient
}


export const AppContext = React.createContext(appState);