import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
import PartnerPcs from '../../pages/comercial/addpc';
const axios = require('axios').default;

export function addPCPartner(partner, data, state, success, failure){

    // We are going to update the partner
    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
        headers: {
            Authorization: currentToken
        }
    }
    //console.log(formData.get("data"))
    axios.put(baseEndpoint + endpoints.PARTNER_ENDPOINT.value + partner.id, data, config)
        .then((data) => {
            success()
        })
        .catch((error) => {
            failure()
        })
}