import gql from 'graphql-tag';

export const OPC_ENTITY = (entityId) => gql`
query opcEntity {
  opcEntities
  (where: 
    { 
      id: ${entityId}
    }
  ) {
        id
        code
        name
        email
        nif
        country
        address
        localization
        date_prices {
          date
          preco_unitario_opc_tempo
      }
    }
  }
`;

export const OPC_ENTITIES = (clientId) => gql`
query opcEntities {
  opcEntities
  (where: 
    { client: 
        {
          id: ${clientId}
        }
    }
  ) {
        id
        code
        name
        email
        nif
        country
        address
        localization
        date_prices {
          date
          preco_unitario_opc_tempo
      }
    }
  }
`;
export default OPC_ENTITIES;