import React, { useContext, useState } from "react"
import Layout from "../../components/layout"
import Indicator from "../../components/entityindicators/indicator"
import TopButtons from "../../components/topBtn/top-btn"
import Table from "../../components/table/table"
import CustomModal from "../../components/modal/modal"
import OPC_ENTITIES_QUERY from "../../queries/opc_entities";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client"
import { AppContext, checkIfUnauthorized } from "../../context/AppContext"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { hasValue } from  "../../utils/validator";
import { removeOPC } from "../../utils/requests/removeOPC"
import cogoToast from "cogo-toast"

const EntitiesOPC = () => {
  const [state, setState] = useContext(AppContext);
  let history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cellDelete, setCellDelete] = useState(null);
  
  let clientId = state.currentClient.id
  let query = OPC_ENTITIES_QUERY(clientId)
  const { data, loading, error, refetch } = useQuery(query);

  let columns = [
    { title: "Código", field: "code", headerFilter: true, isActive: true },
    { title: "Nome", field: "name", headerFilter: true, isActive: true },
    { title: "Email", field: "email", isActive: true },
    { title: "NIF", field: "nif", headerFilter: true, isActive: true },
    { title: "Morada", field: "address", headerFilter: true, isActive: true },
    { title: "Código Postal", field: "postalCodeString", headerFilter: true, isActive: true }, 
    { title: "Localidade", field: "localization", isActive: true },
    { title: "País", field: "country", isActive: true },
    /*{ formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
      setCellDelete(cell)
      setDialogOpen(true);
      e.stopPropagation();
    }}*/
  ];

  function deleteOPC() {
    setState(state => ({ ...state, isLoading: true }))

    let ceme = cellDelete.getRow().getData()
    removeOPC(state, ceme,  (success) => {
      cellDelete.getRow().delete();
      setCellDelete(null)
      setState(state => ({ ...state, isLoading: false }))
      cogoToast.success('CEME removido com sucesso');
      refetch()
    }, (error) => {
      setCellDelete(null)
      setState(state => ({ ...state, isLoading: false }))
      let wasUnauthorized = false
      if (error.response) {
        wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
      }
      if (!wasUnauthorized) {
        cogoToast.error('Erro ao remover OPC');
      }
    })

    setDialogOpen(false);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const deleteOPCDialog = (
    <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">Tem a certeza que deseja remover este parceiro?</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">Não</Button>
        <Button onClick={deleteOPC} color="primary" autoFocus>Sim</Button>
        </DialogActions>
    </Dialog>
  );
  

  function showOPCEntity(opcEntity){
    history.push('/admin/opc_entities/opc_details/'+opcEntity.id, opcEntity)
  }
    
  console.log(loading)
  return (
    <>
      <span>
        <TopButtons buttons = {['Exportar PDF', 'Adicionar Entidade OPC']}/>
        <Table
          isLoading={loading}
          columns={columns}
          data={ data ? data.opcEntities.map( node => {
            return JSON.parse(JSON.stringify(node))
          }) : [] } 
          mode="allEnabled"
          itemClicker={showOPCEntity}
        ></Table>
      </span>

      {deleteOPCDialog}
    </>
  )
}

export default EntitiesOPC