import React, { useState, useEffect } from 'react'
import { ReactTabulator } from 'react-tabulator'
import 'react-tabulator/css/tabulator_simple.min.css'
import './table.css'
import ToggleSearchIcon from '../../static/search-icon.svg'
import ColumnIcon from '../../static/column-icon.svg'
import DownloadIcon from '../../static/download-icon.svg'
import TableIconsStyle from './tableiconstyle'
import Skeleton from '@material-ui/lab/Skeleton'
import Moment from "moment";
import PickerModal from '../picker-modal/picker-modal'
// import "../../components/table/tables_collapsegroupcalc.css"


const Table = ({ columns, data, boxtitle, mode, itemClicker, isLoading, dataLayout, hideButtons, autoColumns, groupBy, collapseGroupCalc, specificHeight, itemsPerPage,hasSecondaryHeader}) => {
    const [showFilter, setShowFilter] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    // const [dataTable, setData] = useState(data)
    var makeVisible = (isVisible) => {
        setShowFilterModal(isVisible)
    }
    let dataTable = data;
    let tableMode = mode

    itemsPerPage = itemsPerPage || 8;
    hasSecondaryHeader = hasSecondaryHeader || false;

    let columnsParam = columns
    columnsParam = columns.map(x => {  x.headerFilter = x.headerFilter == null ? true : x.headerFilter; return x; })

    let tref = null;

    let toggleFilter = () => { setShowFilter(!showFilter); }

    let downloadData = () => {   tref.table.download("csv", "data.csv");  };
    let downloadDataXlsx = () => {   tref.table.download("xlsx", "data.xlsx");  };
    let showColumnFilter = () => {   setShowFilterModal(!showFilterModal);  }
    let refreshHeight = (data) => { 
        if(!tref) { return }
        if(!tref.table) { return }
        tref.table.setHeight(getHeightFromData(data))
        tref.table.redraw()
    };
    
    const[list, setFilterList] = useState(columnsParam)
    let hideColumns = () => {
        columnsParam = list
        columnsParam.forEach(element => {
            if (element.isActive){
                tref.table.showColumn(element.field)
            }else{
                tref.table.hideColumn(element.field)
            }
        });
    }

    function rowClickFunc(event, row) {
        if(itemClicker != null) {
            itemClicker(row.getData())
        }
    }

    function getHeightFromData(data) {
        let tableHeight = data.length >= itemsPerPage ? 500 : (data.length * 51) + 35 + 41 + 10// footer and title header
        if(hasSecondaryHeader)
            tableHeight+=80;
        return tableHeight+"px"
    }

    useEffect(() => {
        if(dataTable.length && !specificHeight){
            refreshHeight(dataTable)
        }       
        setFilterList(columns);
    })

    return (
        <div className={"custom-table-margin table padded-box box-shadow " + (showFilter ? "" : "no-filter")}>
            {
                isLoading ?
                <>
                    <Skeleton className="tableSkeleton" variant="text" />
                </>
                :
                <>
                    <TableIconsStyle/>
                    <div className="table-title-bar" >
                        <div className="table-title-text">{boxtitle}</div>
                        {
                            hideButtons ? 
                             <></>
                             :
                             <div className="table-title-buttons" style = {{display: tableMode == "allEnabled" ? "block" : "none"}}>
                                <div className={"table-title-button"} onClick={toggleFilter}>
                                    <img className="table-icon" src={ToggleSearchIcon}></img>
                                    <span>Pesquisar variável</span>
                                </div>
                                <div className="table-title-button" style={{display: columnsParam.length == 1 ? 'none': 'block'}} onClick={showColumnFilter}>
                                    <img className="table-icon" src={ColumnIcon}></img>
                                    <span>Colunas</span>
                                </div>
                                {/* versão csv
                                <div className="table-title-button" onClick={downloadData}>
                                    <img className="table-icon" src={DownloadIcon}></img>
                                    <span>Download CSV</span>
                                </div>
                                */}
                                <div className="table-title-button" onClick={downloadDataXlsx}>
                                    <img className="table-icon" src={DownloadIcon}></img>
                                    <span>Download XLSX</span>
                                </div>
                            </div>
                        
                        }
                        
                    </div>
                    <ReactTabulator
                        data={dataTable}
                        ref={ref => (tref = ref)}
                        columns={columnsParam}
                        maxHeight="100%"
                        tooltips={true}
                        options={{
                            layout: dataLayout ? dataLayout : "fitDataFill", height:(specificHeight || getHeightFromData(dataTable)), pagination: "local",locale:"pt", fitColumns:true, autoColumns:autoColumns,
                            groupBy:groupBy,
                            movableColumns:true,
                            groupClosedShowCalcs:true,
                            columnCalcs:"both",
                            groupStartOpen:false,
                            headerFilterPlaceholder:"",
                            downloadDataFormatter: (dataTable) => dataTable,
                            downloadReady: (fileContents, blob) => blob,
                            langs:{
                                "pt":{
                                    // "columns":{
                                    //     "name":"Name", //replace the title of column name with the value "Name";
                                    // },
                                    "pagination":{
                                        "first":"Primeira",
                                        "first_title":"Primeira página",
                                        "last":"Última",
                                        "last_title":"Última página",
                                        "prev":"<",
                                        "prev_title":"Página anterior",
                                        "next":">",
                                        "next_title":"Próxima página",
                                    },
                                }
                            },
                        }}
                        rowClick={rowClickFunc}
                    />                   
                    <PickerModal filterElements={columnsParam} showFilter = {showFilterModal} setShowFilter = {setShowFilterModal} setColumns = {hideColumns} listToFilter = {setFilterList} />
                </>
            }
            
        </div>
    )
}

export default Table;