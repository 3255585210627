import React, { useState, useContext } from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import cogoToast from 'cogo-toast';
import "../comercial/create.css";
import { createOPC } from "../../utils/requests/createOpc"
import { validEmail, validPhoneNumber, validPassword, hasLength, hasValue , isValidName , isValidAddress , isValidPostalCode , isValidNIF ,isValidCountry } from  "../../utils/validator";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";


function CreateOPCPage({location}) {
    let history = useHistory()


    const [state, setState] = useContext(AppContext);
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [code, setCode] = useState ('')
    const [email, setEmail] = useState('')
    const [localization, setLocalization] = useState('')
    const [nif, setNIF] = useState('')
    const [hasError, setError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [postalCodeStringError, setpostalCodeStringError] = useState(false);
    const [countryError, setCountryError] = useState(false)
    const [localizationError, setLocalizationError] = useState(false);
    const [nifError, setNifError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    function goBack() {
        history.goBack()
    }

    function addOPCEntity() {
        var registerError = false
        
        if (!hasLength(name) || !isValidName(name)) {
            setNameError('Insira um nome válido por favor')
            registerError = true
        } else {
            setNameError(null)
        }

        if (!hasLength(address) || !isValidAddress(address)) {
            setAddressError("Insira morada válida por favor")
            registerError = true
        } else {
            setAddressError(null)
        }

        if (!hasLength(email) || !validEmail(email)) {
            setEmailError("Insira email válido por favor")
            registerError = true
        } else {
            setEmailError(null)
        }

        if (!hasLength(code) || !isValidName(code)) {
            setCodeError("Insira um código válido")
            registerError = true
        } else {
            setCodeError(null)
        }

        if (!hasLength(localization) || !isValidName(localization)) {
            setLocalizationError("Insira uma localização válida")
            registerError = true
        } else {
            setLocalizationError(null)
        }

        if(!hasLength(nif) || !isValidNIF(nif)) {
            setNifError("Indique um NIF")
            registerError = true
        }else {
            setNifError(null)
        }
        
        if(!hasLength(country) || !isValidCountry(country)){
            setCountryError("Indique um país válido")
            registerError = true
        }else {
            setCountryError(null)
        }

        if (registerError) {
            setError(true)
            return;
        }

        const formData = new FormData();
        let opcObject = {}
        opcObject.name = name
        opcObject.address = address
        opcObject.code = code
        opcObject.email = email
        opcObject.country = country
        opcObject.localization = localization
        opcObject.nif = nif
        opcObject.client = state.currentClient
        formData.append('data', JSON.stringify(opcObject))
    
        setState(state => ({ ...state, isLoading: true }))
        createOPC(formData, state, (success) => {
            history.goBack()
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success('Entidade OPC registada com sucesso!');
        }, (error) => {
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.error('Erro ao fazer registar entidade');
        })
    }

    // Text state and helpers
    var initCode = ((value => {
        setCode(value)
        setError(false)
    }))

    var initName = ((value => {
        setName(value)
        setError(false)
    }))
    var initEmail = ((value) => {
        setEmail(value)
        setError(false)
    })
    
    var initAddress = ((value => {
        setAddress(value)
        setError(false)
    }))

    var initLocalization = ((value => {
        setLocalization(value)
        setError(false)
    }))

    var initNIF = ((value => {
        setNIF(value)
        setError(false)
    }))

    var initCountry = ((value => {
        setCountry(value)
        setError(false)
    }))

    return (
        <span>
            {
                <>
                    <h2>Dados OPC</h2>
                    { /* Name */}
                    <TextField
                        className="inputField"
                        id="name"
                        label="Nome"
                        variant="outlined"
                        value={name}
                        onInput={e => initName(e.target.value)}
                        error={nameError ? true : null}
                        helperText={nameError ? nameError : null}
                    />

                    { /* Address */}
                    <TextField
                        className="inputField"
                        id="address"
                        label="Morada"
                        variant="outlined"
                        value={address}
                        onInput={e => initAddress(e.target.value)}
                        error={addressError ? true : null}
                        helperText={addressError ? addressError : null}
                    />

                    { /* Country */}
                    <TextField
                        className="inputField"
                        id="country"
                        label="País"
                        variant="outlined"
                        value={country}
                        onInput={e => initCountry(e.target.value)}
                        error={countryError ? true : null}
                        helperText={countryError ? countryError : null} />

                    { /* Email */}
                    <TextField
                        className="inputField"
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onInput={e => initEmail(e.target.value)}
                        error={emailError ? true : null}
                        helperText={emailError ? emailError : null} />

                    { /* Code */}
                    <TextField
                        className="inputField"
                        id="code"
                        label="Código"
                        variant="outlined"
                        value={code}
                        onInput={e => initCode(e.target.value)}
                        error={codeError ? true : null}
                        helperText={codeError ? codeError : null} />

                    { /* Localization */}
                    <TextField
                        className="inputField"
                        id="localization"
                        label="Localização"
                        variant="outlined"
                        value={localization}
                        onInput={e => initLocalization(e.target.value)}
                        error={localizationError ? true : null}
                        helperText={localizationError ? localizationError : null} />

                    { /* Nif */}
                    <TextField
                        className="inputField"
                        id="nif"
                        label="NIF"
                        variant="outlined"
                        value={nif}
                        onInput={e => initNIF(e.target.value)}
                        error={nifError ? true : null}
                        helperText={nifError ? nifError : null} />
                    {
                        <div style={{marginBottom: "20px"}}>
                            <Button className="simple-button" onClick={goBack}>Voltar</Button>
                            <Button style={{ float: "right" }} className="simple-button" onClick={addOPCEntity}>Adicionar Entidade OPC</Button>
                        </div>
                    }
                </>
            }
        </span>
    )
}

export default CreateOPCPage