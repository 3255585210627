import React, { useContext } from "react"

import CHARGERS_QUERY from '../../queries/opcchargers'
import Table from "../../components/table/table";
import TopButtons from "../../components/topBtn/top-btn";
import DetailHeader from "../../components/detail/detailHeader";
import cogoToast from 'cogo-toast';
import { AppContext, checkIfUnauthorized } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import { baseEndpoint, topCalcFormatNumber, UnitSymbols } from "../../utils/utils"
import "../../utils/text_field.css"
import { Input, TextField } from "@material-ui/core";
import axios from 'axios'
import { useQuery } from 'react-query'
import { Card } from "@material-ui/core";

function CemeDetails({ location }) {
    const [state, setState] = useContext(AppContext);
    let cemeObject = location.state

    let clientId = state.currentClient.id;

    // Get charger usages
    let usageoptions = state.queryAuthHeaders();
    // usageoptions.params = {cemeId: cemeObject.id};
    const { isLoading:loadingUsages, error:errorUsages, data:dataUsages } = useQuery(['pc', {cemeId: cemeObject.code}], () =>
    axios(baseEndpoint + '/pcopcquery?cemeId='+cemeObject.code, usageoptions))
   


    if(loadingUsages){
        return (<></>)
    }
    console.log(dataUsages);
    var chargerusages = dataUsages.data.pcs || [];

    // vai buscar as bocas pelo set de nomes idEVSE
    var chargernames = [...new Set(chargerusages.map(x=> x.idChargingStation))];

    var chargers = chargernames.map(x=> chargersmap(x,chargerusages))

    var allUsages = [];
    chargers.forEach(x => allUsages = allUsages.concat(x.usages));


    function removeCEME() {

    }

    let topBtnClick = {
        'Remover CEME': removeCEME
    }

    console.log(cemeObject)
    return (
        <div>
            <span style={{display:"block"}}>
                {/* <TopButtons buttons={['Remover CEME']} onClickers={topBtnClick} />*/}
                <TextField className="custom-text-margin smaller" readOnly label="Código" value={cemeObject.code !== undefined && cemeObject.code !== "" ? cemeObject.code : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="Nome" value={cemeObject.name !== undefined && cemeObject.name !== "" ? cemeObject.name : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="País" value={cemeObject.country !== undefined && cemeObject.country !== "" ? cemeObject.country : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="Morada" value={cemeObject.address !== undefined && cemeObject.address !== "" ? cemeObject.address : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="Pessoa Contacto" value={cemeObject.contactName !== undefined && cemeObject.contactName !== ""? cemeObject.contactName : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="NIF" value={cemeObject.nif !== undefined && cemeObject.nif !== ""? cemeObject.nif : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="Número de Telemóvel" value={cemeObject.phoneNumber !== undefined && cemeObject.phoneNumber !== "" ? cemeObject.phoneNumber : "N/A"} />
                <TextField className="custom-text-margin smaller" readOnly label="Local" value={cemeObject.place !== undefined && cemeObject.place !== "" ? cemeObject.place : "N/A"} />
                <TextField className="custom-text-margin smaller"readOnly label="Codigo Postal" value={cemeObject.postalCodeString !== undefined && cemeObject.postalCodeString !== "" ? cemeObject.postalCodeString : "N/A"} />
            </span>
            <div>

        <Card>
          <Table
                groupBy={["idChargingStation"]}
                // groupBy={["idChargingStation","idEVSE"]}
                isLoading={loadingUsages}
                columns={columns}
                boxtitle={'Carregadores associados'}
                mode="allEnabled"
                data={allUsages}
                specificHeight={"900px"}
                >
              </Table>
        </Card>
    </div>
        </div>
    )
}
export default CemeDetails

//colunas comentadas podem só levar visible:false para poderem ser escolhidas
let columns = [
    // {"title":"id","field":"id"},
    {"title":"ID","field":"idUsage", width:200},
    // {"title":"idExternalNumber","field":"idExternalNumber"},
    {"title":"Data Hora","field":"stopTimestamp"},
    // {"title":"idInternalNumber","field":"idInternalNumber"},
    // {"title":"idNetworkOperator","field":"idNetworkOperator"},
    // {"title":"idChargingStation","field":"idChargingStation"},
    // {"title":"idEVSE","field":"idEVSE"},
    {"title":"Duração" + UnitSymbols.duration,"field":"totalDuration", topCalc:topCalcFormatNumber},
    {"title":"Energia" + UnitSymbols.energy,"field":"energia_total_transacao", topCalc:topCalcFormatNumber},
    {"title":"Valor" + UnitSymbols.value,"field":"preco_opc", topCalc:topCalcFormatNumber},
    {"title":"Ficheiro","field":"file_name",width:0},
  // {"title":"startTimestamp","field":"startTimestamp"},
  ]

var chargersmap = (name,chargerusages) => 
{
  //usages da boca
  var usages = chargerusages.filter(x=> x.idChargingStation == name);

  //ids unicos de usage
  var usageIds = [...new Set(usages.map(x=> x.idUsage))];

  // var uniques =  usageIds.map(id => {return usages.find(x=>x.idusage == id)});

  //para cada idUsage, ir buscar as desse id
  //fazer pop para obter o ultimo da lista
  var uniques = usageIds.map(idUsage => {
    var latest = usages.filter(x=> x.idUsage == idUsage).pop();
    return latest;
  })

  //tirados porque agora calculamos na tabela
  // var energia_total_transacao_sum = usages.map(u=> u.energia_total_transacao).reduce(sumFunc);
  // var totalDuration_sum = usages.map(u=> u.energia_total_transacao).reduce(sumFunc);
  
  return {name:name, usages: uniques, 
    //tirados porque agora calculamos na tabela
    //energia_total_transacao_sum: energia_total_transacao_sum, totalDuration_sum: totalDuration_sum
  };
}

function onlyUniqueNames(data){
    return [...new Set(data.pcs.map(x=> x.name))].map(x=> {return {name:x}})
}
