import React, {useState, useEffect} from "react"
import { ResponsiveBar } from '@nivo/bar'
import { BarChart, CartesianGrid, Tooltip, Legend, Bar, XAxis, YAxis, ResponsiveContainer, LineChart, Line } from 'recharts'
// Type -> bar / line
function ChartGraph({data, graphKeys, type}) {
    const theme = {
        axis: {
            ticks: {
                text: {
                    fill: '#93969D',
                    fontSize: 13,
                }
            }
        },
        grid: {
            line: {
                stroke: '#DFE0E2',
                strokeWidth: 1,
            }
        }
    }

    useEffect(() => {
       
    });

    function getChartFromType() {
        if(type == "bar") {
            return(
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data} margin={{ top: 20, right: 30, left: -8, bottom: 15 }}>
                        <Tooltip wrapperStyle={{ width: 150, backgroundColor: '#ccc' }} />
                        <CartesianGrid  vertical={false} stroke='#DFE0E2'/>
                        <XAxis tickLine={false} dataKey="time" stroke='#93969D'/>
                        <YAxis tickLine={false}  stroke='#93969D'/>
                        <Tooltip coordinate={{ y: -150 }}/>
                    
                        <Legend 
                            wrapperStyle={{
                                paddingLeft: "20px",
                                  bottom: "40px"
                              }}
                               iconType="square" i
                               iconSize={10} 
                               layout="vertical" 
                               align="right" 
                               verticalAlign="bottom" 
                        />
                        
                        {
                            graphKeys.map( key => {
                                return(
                                    <Bar dataKey={key.name} fill={key.color} />
                                )
                            })
                        }
                    </BarChart>
                </ResponsiveContainer>
            )
        } else if (type == "line") {
            return(
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data} margin={{ top: 20, right: 30, left: 2, bottom: 15 }}>
                        <Tooltip wrapperStyle={{ backgroundColor: '#ccc' }} />
                        <CartesianGrid  vertical={false} stroke='#DFE0E2'/>
                        <XAxis tickLine={false} dataKey="time" stroke='#93969D'/>
                        <YAxis tickLine={false}  stroke='#93969D'/>
                        <Legend 
                            wrapperStyle={{
                                paddingLeft: "20px",
                                  bottom: "45px"
                              }}
                               iconType="square" i
                               iconSize={10} 
                               layout="vertical" 
                               align="right" 
                               verticalAlign="bottom" 
                        />
                        {
                            graphKeys.map( key => {
                                return(
                                    <Line connectNulls={true} type="monotone" dataKey={key.name} stroke={key.color} />
                                )
                            })
                        }
                    </LineChart>
                </ResponsiveContainer>
            )
        } else {
            return <></>
        }
    }

    return (    
        <>
            {getChartFromType()}
        </>
    )
}
export default ChartGraph