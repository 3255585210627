export function validEmail(value){
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return re.test(String(value).toLowerCase());
}

export function validPhoneNumber(value){
    return value.length == 9;
}

export function validPassword(value){
    var password = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    return value.match(password);
}

export function isValidName(value) {
    const nameRegExp = /^[A-Za-z\s]{2,50}$/;
  return nameRegExp.test(value);
}


export function isValidAddress(value) {
    const addressRegExp = /^[A-Za-z0-9\sº,\.]{2,100}$/;
  return addressRegExp.test(value);
}
export function isValidPostalCode(value) {
    const isValidPostalCode = /^[0-9]{4}-[0-9]{3}$/u;
  return isValidPostalCode.test(value);
}


export function hasLength(value) {
    return value.length > 0
}

export function hasValue(value){
    return value != null
}

export function isValidCountry(value){
    const validCountryNames = [
        "Afeganistão",
        "África do Sul",
        "Albânia",
        "Alemanha",
        "Andorra",
        "Angola",
        "Antígua e Barbuda",
        "Arábia Saudita",
        "Argélia",
        "Argentina",
        "Armênia",
        "Austrália",
        "Áustria",
        "Azerbaijão",
        "Bahamas",
        "Bangladesh",
        "Barbados",
        "Barein",
        "Bélgica",
        "Belize",
        "Benin",
        "Bielo-Rússia",
        "Bolívia",
        "Bósnia e Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunei",
        "Bulgária",
        "Burkina Faso",
        "Burundi",
        "Butão",
        "Cabo Verde",
        "Camarões",
        "Camboja",
        "Canadá",
        "Catar",
        "Cazaquistão",
        "Chade",
        "Chile",
        "China",
        "Chipre",
        "Colômbia",
        "Comores",
        "Congo",
        "Coreia do Norte",
        "Coreia do Sul",
        "Costa do Marfim",
        "Costa Rica",
        "Croácia",
        "Cuba",
        "Dinamarca",
        "Djibuti",
        "Dominica",
        "Egito",
        "El Salvador",
        "Emirados Árabes Unidos",
        "Equador",
        "Eritreia",
        "Eslováquia",
        "Eslovênia",
        "Espanha",
        "Estados Unidos",
        "Estônia",
        "Eswatini",
        "Etiópia",
        "Fiji",
        "Filipinas",
        "Finlândia",
        "França",
        "Gabão",
        "Gâmbia",
        "Gana",
        "Geórgia",
        "Granada",
        "Grécia",
        "Guatemala",
        "Guiana",
        "Guiné",
        "Guiné Equatorial",
        "Guiné-Bissau",
        "Haiti",
        "Holanda",
        "Honduras",
        "Hungria",
        "Iêmen",
        "Ilhas Marshall",
        "Ilhas Maurício",
        "Índia",
        "Indonésia",
        "Irã",
        "Iraque",
        "Irlanda",
        "Islândia",
        "Israel",
        "Itália",
        "Jamaica",
        "Japão",
        "Jordânia",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letônia",
        "Líbano",
        "Libéria",
        "Líbia",
        "Liechtenstein",
        "Lituânia",
        "Luxemburgo",
        "Madagascar",
        "Malásia",
        "Malavi",
        "Maldivas",
        "Mali",
        "Malta",
        "Marrocos",
        "Mauritânia",
        "México",
        "Mianmar",
        "Micronésia",
        "Moçambique",
        "Moldávia",
        "Mônaco",
        "Mongólia",
        "Montenegro",
        "Namíbia",
        "Nauru",
        "Nepal",
        "Nicarágua",
        "Níger",
        "Nigéria",
        "Noruega",
        "Nova Zelândia",
        "Omã",
        "Palau",
        "Panamá",
        "Papua-Nova Guiné",
        "Paquistão",
        "Paraguai",
        "Peru",
        "Polônia",
        "Portugal",
        "Quênia",
        "Quirguistão",
        "Reino Unido",
        "República Centro-Africana",
        "República Democrática do Congo",
        "República Dominicana",
        "República Tcheca",
        "Romênia",
        "Ruanda",
        "Rússia",
        "Samoa",
        "San Marino",
        "Santa Lúcia",
        "São Cristóvão e Nevis",
        "São Tomé e Príncipe",
        "São Vicente e Granadinas",
        "Seicheles",
        "Senegal",
        "Serra Leoa",
        "Sérvia",
        "Singapura",
        "Síria",
        "Somália",
        "Sri Lanka",
        "Suazilândia",
        "Sudão",
        "Sudão do Sul",
        "Suécia",
        "Suíça",
        "Suriname",
        "Tadjiquistão",
        "Tailândia",
        "Taiwan",
        "Tanzânia",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad e Tobago",
        "Tunísia",
        "Turcomenistão",
        "Turquia",
        "Tuvalu",
        "Ucrânia",
        "Uganda",
        "Uruguai",
        "Usbequistão",
        "Vanuatu",
        "Vaticano",
        "Venezuela",
        "Vietnã",
        "Zâmbia",
        "Zimbábue"
    ];
    value = value.toLowerCase()
    value = value.charAt(0).toUpperCase() + value.slice(1);

    return validCountryNames.includes(value);
      
}
export function isValidNIF(nif) {
    let isOnlyDigits = /^\d+$/.test(nif)
    return isOnlyDigits && nif.length == 9
}