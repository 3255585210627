import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;

export function forgotPass(email, success, failure){
    axios.post(baseEndpoint + endpoints.FORGOT_ENDPOINT.value, {
        email: email
    }).then((data) => {
        success(data)
    }).catch((error) => {
        failure()
    })
}