import React, { useState, useContext , useEffect} from "react"
import './login.css'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import logo from "../static/Logo.png"
import { FormGroup, Paper } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import cogoToast from 'cogo-toast';
import Constants from '../constants';
import { useHistory } from "react-router-dom";
import { initState } from "../context/AppContext";
import { tokenRequest } from "../utils/requests/tokenRequest"
const axios = require('axios').default;


function LoginPage({ }) {
    const [state, setState] = useContext(AppContext);
    var initPassword = ((pass) => {
        setPassword(pass)
        setError(false)
    })
    var initEmail = ((email => {
        setEmail(email)
        setError(false)
    }))
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hasError, setError] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    let history = useHistory();

    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    const goToReset = () => {
        history.push("/forgot")
    }

    function setLoggedUser(user) {
        let stringUser = JSON.stringify(user)
        localStorage.setItem(Constants.userKey, stringUser);
        initState()
        let pE = user != null ? user.clients && user.clients.length > 0 && user.partners && user.partners.length > 0 : false
        setState(state => ({ ...state, currentUser: user, currentClient: user.clients[0], isLoading: false, isPartnerEmployee :  pE}))
    }

    function handleSubmit(event) {
        event.preventDefault();
        var loginError = false
        if (email.length == 0) {
            setEmailError("Insira email")
            loginError = true
        } else {
            setEmailError(null)
        }
        if (password.length == 0) {
            setPasswordError("Insira password")
            loginError = true
        } else {
            setPasswordError(null)
        }

        if (loginError) {
            setError(true)
            return
        }
        setState(state => ({ ...state, isLoading: true }))
        
        tokenRequest(email, password, (data) => {
            setError(false)
            data.data.user.jwt = data.data.jwt
            setLoggedUser(data.data.user)
            
            cogoToast.success('Login efetuado com sucesso!');
            setState(state => ({ ...state, isLoading: false }))
        }, (failure) => {
            //setError(false)
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.error('Erro ao fazer login');
        })
    }

    useEffect(() => {
      });

    return (
        <>
            <div className="formContainerGrid">
                <div id="imgContainer">
                    <div />
                    <img style={{ width: "200px", height: "200px" }} src={logo}></img>
                </div>

                <form margin='dense' id="formLogin" autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl>

                        {/* Email */}
                        <TextField
                            className="inputLogin"
                            id="loginEmail"
                            label="Email"
                            variant="outlined"
                            value={email}
                            onInput={e => initEmail(e.target.value)}
                            error={hasError ? true : null}
                            helperText={hasError ? emailError : null}
                        />


                        {/* Password */}
                        <TextField
                            className="inputLogin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClick}
                                            edge="end">
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            id="loginPassword"
                            label="Password"
                            value={password}
                            onInput={e => initPassword(e.target.value)}
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            error={hasError ? true : null}
                            helperText={hasError ? passwordError : null}
                        />

                        <Button
                            type="submit"
                            id="loginBtn"
                            variant="contained"
                            color="primary">Login</Button>

                        <span className="forgotSpan" onClick={goToReset}>Repor palavra-passe</span>
                    </FormControl>

                </form>
            </div>
        </>
    )
};

export default LoginPage
