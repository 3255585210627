import React, { useContext, useState , useEffect } from "react"

import Table from "../../components/table/table";
import TopButtons from "../../components/topBtn/top-btn";
import DetailHeader from "../../components/detail/detailHeader";
import cogoToast from 'cogo-toast';
import { AppContext, checkIfUnauthorized } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import { baseEndpoint, convertDayString, formatCellNumber, UnitSymbols } from "../../utils/utils"
import { removePartner } from "../../utils/requests/removePartner"
import "./detail.css";
import { Button, Grid, TextField } from "@material-ui/core";
import { PARTNER_ID } from "../../queries/partners";
import { useQuery as useQueryApollo } from "@apollo/client";
import { useQuery } from "react-query";
import Moment from "moment";
import { createDatePercentage } from "../../utils/requests/createDatePercentage"
import axios from 'axios'
import Constants from '../../constants';
import MonthYearPicker from '../../components/monthpicker/monthpicker'
import { parse } from "date-fns";
import Skeleton from '@material-ui/lab/Skeleton'

function DetailPartner({match}) {
  let history = useHistory();
  const [state, setState] = useContext(AppContext);

  const [selectedFilterDate, setSelectedFilterDate] = useState(Moment().format("YYYYMM"));

  const [selectedDate, setSelectedDate] = useState(Moment().format("YYYY/MM/DD"));
  const [percentageError, setPercentageError] = useState(false);
  const [dateObject, setDateObject] = useState({date: '', percentage: ''})
  const [dateError, setDateError] = useState(false);

  var setFilterDate = (datestring) => {console.log(datestring); setSelectedFilterDate(datestring);} 

  let clientId = state.currentClient.id
  
  let user = state.currentUser;
  var partner = {};

  let partnerid = match.params.partnerid ||user.partners[0].id.toString();

  let query = PARTNER_ID(partnerid)

  // Get partner
  const { data:partnerdata, loading, error , refetch} = useQueryApollo(query, {fetchPolicy: "no-cache" });

  //get partner cemes
  const { isLoading, error:cemeserror, data} = useQuery(['pcqueryByPartner', {partnerId: partnerid}], () =>
  axios(baseEndpoint + '/pcopcquery?partnerId='+partnerid, state.queryAuthHeaders()))
  
  if(isLoading || loading){
    return (<Skeleton className="tableSkeleton" variant="text" />)
  }
  // usar dados unicos
  var usages = data.data.pcs;
  let usageIds = [...new Set(usages.map((x) => x.idSubUsage))];
  let uniques = usageIds.map((idSubUsage) => {
      return usages.filter((x) => x.idSubUsage === idSubUsage).pop();
  })
  
  //remove activation cost
  uniques = uniques.map(x => { x.preco_opc_sem_ativacao = x.preco_opc - x.preco_unitario_opc_ativacao; return x;})


  partner = partnerdata.partners[0];

  // Partner
  let partnerLogo = partner.logo ? baseEndpoint+partner.logo.url : null
  let employeesData = partner.users
  let currentClients = partner.clients
  let partnerMainItems = [partner.name]
  let partnerItems = {}
  partnerItems["Endereço"] = partner.address
  partnerItems["NIF"] = partner.nif
  partnerItems["Parceria"] = (() => {
    let partnerEnum = partner.partnershipType
    if(!partnerEnum) {
      return "Não definida"
    } else if(partnerEnum == Constants.partnerTypeIncome) {
      return Constants.partnerTypeIncomeTitle
    } else if(partnerEnum == Constants.partnerTypeUsage) {
      return Constants.partnerTypeUsageTitle
    }
  })()

  
  // Employees
  let columnsEmployees = [
    { title: "Nome", field: "username", headerFilter:true, isActive : true},
    { title: "Email", field: "email" , headerFilter:true, isActive : true}
  ];

  // Clients
  let columnsClients = [
    { title: "Nome", field: "name", headerFilter:true, isActive : true}
  ];

  // Pcs
  let columnsPcs = [
    { title: "Carregador", field: "name", headerFilter:true, isActive : true},
    { title: "Boca", field: "IdEVSE" , headerFilter:true, isActive : true},
    { title: "Percentagem mês selec.", field: "currentPercentage" , headerFilter:true, isActive : true, formatter:(cell)=>{return cell.getValue()+"%";}},
    { title: "Valor (sem ativação) / mês selec." + UnitSymbols.value, field: "totalValue" , headerFilter:true, isActive : true, formatter: formatCellNumber},
    { title: "Comissão (sem ativação) / mês selec." + UnitSymbols.value, field: "comissionValue" , headerFilter:true, isActive : true, formatter: formatCellNumber},
  ]
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  
  var currentMonthString = selectedFilterDate.replace("-","").substring(0,6);



  // gets pc current percentage
  let dataPcs = partnerdata.partners[0].pcs.map(pc =>
  {
    var pct = pc.date_percentages && pc.date_percentages.reverse().find(x=>  parseInt(currentMonthString) >= parseInt(x.date.replace("-","").substring(0,6))) || {percentage:0};
    pc.currentPercentage = pct.percentage;

    var pcopc = uniques.filter(x=> x.idEVSE == pc.IdEVSE)
    .filter(x=> x.startTimestamp.substring(0,6) == currentMonthString);
    pc.totalValue = pcopc.length ? pcopc.map(x=> x.preco_opc_sem_ativacao).reduce(reducer) : 0;
    pc.comissionValue = pc.totalValue * (pct.percentage / 100);
    return pc;
  } );

  // DatePercentage
  let percentageData = partner.date_percentages; 

  //converts date
  // gets current percentage's pc
  let percentageDataTable = partner.date_percentages
  

  let datePercentageColumns = [
    { title: "Data", field: "date", isActive: true , formatter:(cell)=>{return convertDayString(cell.getValue());} },
    { title: "Percentagem", field: "percentage", isActive: true , formatter:(cell)=>{return cell.getValue()+"%";}},
    { title: "Carregador", field: "pc.IdEVSE", isActive: true }
  ]



  function deletePartner() {
    setState({ ...state, isLoading: true })
    removePartner(state, partner, (success) => {
      setState(state => ({ ...state, isLoading: false }))
      history.goBack()
      cogoToast.success('Parceiro removido com sucesso');
      refetch()
    }, (failure) => {
      setState({ ...state, isLoading: false })
      let wasUnauthorized = false
      if (failure.response) {
        wasUnauthorized = checkIfUnauthorized(failure.response.status, state, setState, history)
      }
      if (!wasUnauthorized) {
        cogoToast.error('Erro ao remover parceiro');
      }
    })
  }

  function addEmployee() {
    history.push('/comercial/detail/'+partnerid+'/create', {isPartner: true,  partner: partner })
  }

  function addPC() {
    history.push('/comercial/addpc', { partner: partner })
  }

  let topBtnClick = {
    'Adicionar Funcionário': addEmployee,
    'Remover Parceiro': deletePartner
  }

  // Date Price insertion
  var initUnitPercentage = ((value => {
    dateObject.percentage = value
    setDateObject(dateObject) 
  }))

  const handleDateChange = (date) => {
    let dateObjectFormatted = Moment(new Date(date)).format("YYYY-MM-DD");
    
    dateObject.date = dateObjectFormatted
    
    if(!checkDateAfterLastDate(dateObjectFormatted)) {
      setDateError("Data tem de ser posterior")
    } else {
      setDateError(null)
      setDateObject(dateObject)
      setSelectedDate(dateObjectFormatted);
    }
  };

  function checkDateAfterLastDate(date) {
    let lastDate = percentageData && percentageData.length > 0 ? percentageData[percentageData.length - 1].date : null
    if (lastDate) {
      return date > lastDate
    } else {
      return true
    }
  }
  function updateDatePrice() {
    var updateError = false

    if(!dateObject.date.length > 0){
      setDateError("Por favor insira uma data válida")
      updateError = true
    } else if (!checkDateAfterLastDate(dateObject.date)) {
      setDateError("Por favor insira uma data válida")
      updateError = true
    } else {
      setDateError(null)
    }

    if(!dateObject.percentage.length > 0){
      setPercentageError("Por favor insira um valor válido")
      updateError = true
    } else if(dateObject.percentage < 0 || dateObject.percentage > 100) {
      setPercentageError("Insira percentagem válida 0...100")
      updateError = true
    } else {
      setPercentageError(null)
    }

    if (updateError) {
        return;
    }

    let object = {}
    object.percentage = dateObject.percentage
    object.date = dateObject.date
    object.partner = partner.id
    createDatePercentage(object, state, (success) => {
        console.log("refetching")
        setState(state => ({ ...state, isLoading: false }))
        cogoToast.success('Valores adicionados com sucesso');
        refetch()
    }, (error) => {
        setState(state => ({ ...state, isLoading: false }))
        let wasUnauthorized = false
        if (error && error.response) {
            wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
        }
        if (!wasUnauthorized) {
            cogoToast.error('Erro ao adicionar valores. Tente outra vez.');
        }
    })
  }

  return (
      <span>
        {
          !user.partners.length ? //só mostra se não for user de partner
          <TopButtons buttons = {['Remover Parceiro', 'Adicionar Funcionário']} onClickers={topBtnClick}/> : <></>
        }
        <DetailHeader img={partnerLogo} mainItems={partnerMainItems} items={partnerItems}/>

        { /* Employees Table */}
        <Table
          columns={columnsEmployees}
          data={employeesData}
          boxtitle="Funcionários">
        </Table>

        { /* PCS Table */ }
        <div style={{width:"200px"}}>
          <MonthYearPicker name="searchdate" label="Mês e Ano" change={setFilterDate}  /* defaultValue={} change={changeSearch}*//>
        </div>
        <Table
          columns={columnsPcs}
          data={dataPcs}
          boxtitle="Carregadores">
        </Table>
        {/* <Button className="addPCBtn" onClick={addPC} color="primary">Adicionar Carregador</Button> */}


        <Table
            boxtitle="Percentuais"
            columns={datePercentageColumns}
            data={percentageDataTable}
            mode="">
        </Table>
        {/* <span className="dateLine">
            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data"
                  className="dateDate"
                  value={selectedDate}
                  onChange={handleDateChange}
                  error={ dateError? dateError : null}
                  helperText={dateError ? dateError : null}
                  KeyboardButtonProps={{
                      "aria-label": "change date"
                  }}
                  autoOk
              />
            </MuiPickersUtilsProvider>

            <TextField
              type={"number"}
              id="standard-basic"
              label="Valor percentual"
              className="textDate"
              //value={dateObject.percentage}
              onInput={e => initUnitPercentage(e.target.value)}
              error={ percentageError? percentageError : null}
              helperText={percentageError ? percentageError : null}
            />

            <Button className="addDateBtn" onClick={updateDatePrice} color="primary">Adicionar Percentual</Button>
        </span> */}
      </span>
  )
}

export default DetailPartner