/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */


import React, {useState, useEffect, useContext} from "react"
import PropTypes from "prop-types"
import Header from "./header"
import SideMenu from "../components/menu/side-menu.js"
import TopBar from "../components/bar/top-bar"
import { AppContext, logoutUser, initAppState } from "../context/AppContext"
import { useMediaQuery } from 'react-responsive'
import LAYOUT_QUERY from "../queries/layout";
import "./layout.css"
import "../css/main.css"
import "../css/scrollbar.css"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { useQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import SEO from "./seo"
import { baseEndpoint } from "../utils/utils"

const Layout =  ({ children }) => {  
  const [logoutDialogOpen, openLogoutDialog] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)'});
  const [state, setState] = useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false); 
  let history = useHistory()
  
  // query
  const { data, loading, error } = useQuery(LAYOUT_QUERY);


  useEffect(() => {
    checkAppStateHistory()
    
    if(!isMobile && menuOpen) {
      setMenuOpen(false)
    }

  });

  function checkAppStateHistory() {
    initAppState(history)
  }
  
  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }
  
 
  let currentTitle = window.location.pathname == "/profile" ? "Perfil" : ""
  let currentClients = data ? data.clients : null;
  let currentMenus = data ? data.menuHolders
  .filter(element => {
    if(state.isPartnerEmployee) {
      return element.showPartner
    }
    return true
  })
  .map(element => {
    let currentPath = window.location.pathname
    let id = element.id
    let imageUrl = baseEndpoint+element.image.url
    let title = element.title
    let path = element.pathName
    let pathSplit = currentPath.split("/")
  
    let firstPathElement = pathSplit[1]
    let isSelected = firstPathElement.includes(path);
    if(isSelected) {
      currentTitle = title
    }
    let submenus = element.submenus.map(submenu => {
      let submenupath = path+"/"+submenu.pathName
      let subMenuIsSelected = currentPath.includes("/"+submenupath)
      if(subMenuIsSelected) {
        currentTitle = submenu.title
      }
      return { id: submenu.id, path: submenupath, title: submenu.title, isSelected: subMenuIsSelected}
    })
    
    return { id: id, image: imageUrl, path: path, title: title, submenus: submenus, isSelected: isSelected, hasSubmenus: submenus.length, Order: element.Order }
  }) : null;

  currentMenus && currentMenus.sort(function(a, b) {return a.Order - b.Order;})
  
  // LOGOUT
  function handleLogout() {
    openLogoutDialog(true);
  }

  function goLogout() {
      logoutUser(state, setState, history)
      openLogoutDialog(false);
  }

  const handleClose = () => {
    openLogoutDialog(false);
  };

  const logoutDialog = (
    <Dialog
        open={logoutDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">Deseja terminar a sua sessão?</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">Não</Button>
        <Button onClick={goLogout} color="primary" autoFocus>Sim</Button>
        </DialogActions>
    </Dialog>
  );

  // LAYOUT
  return (
    <>
      <SEO/>
      <div className="mainContainer">
        <SideMenu isLoading={loading} clientData={currentClients} menuEntries = {currentMenus} isMobile={isMobile} menuOpen={menuOpen} toggleMenu={toggleMenu} logoutUser={handleLogout}/>
        <TopBar title = {currentTitle} toggleMenu={toggleMenu} logoutUser={handleLogout}/>

        <main className="main">
          {children}
        </main>

        {/* LOGOUT DIALOG */}
        {logoutDialog}
      </div>
    </>
  )
}

export default Layout
