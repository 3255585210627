import "./detailHeader.css"
import React, { useRef, useState } from "react"
import { Button, Paper, IconButton, makeStyles, Avatar } from "@material-ui/core"
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import AddPhotoImg from '../../static/add_photo_img.png';
import cogoToast from 'cogo-toast';
import { insertImage } from '../../utils/requests/insertImage'
const axios = require('axios').default;

// Img -> Is the left img : String
// MainItems -> Are the items that show next to the img: [String]
// Items -> remaining items, makes a grid-> [{title: value}]
function DetailHeader({ img, mainItems, items, haveMargin }) {
    const [imageFile, setPhoto] = useState(null)


    var fileSelectedHandler = (event) => {
        setPhoto(event.target.files[0])

        const imageData = new FormData();
        imageData.append('image', imageFile)
        insertImage(imageData, (success) => {
            cogoToast.success('Imagem carregada com sucesso');
        }, (failure) => {
            cogoToast.error('Erro ao fazer load da imagem. Tente outra vez.');
        })
    }

    function deleteFileImg() {

    }


    return (
        <Paper className="detailHeader">
            <span className={haveMargin ? "paperHolder paperHolderMargin" : "paperHolder"}>
                {/* Image  */}
                {
                    img == null ?
                        <div>
                            <label className="upload-img" htmlFor="upload-photo">
                                <IconButton className="camera-icon" aria-label="upload picture" component="span">
                                    <PhotoCamera className="camera-img" />
                                </IconButton>
                            </label>
                            <input accept="image/*" style={{ display: "none" }} onChange={fileSelectedHandler} id="upload-photo" type="file"></input>
                            <Avatar fontSize="large" className="photoImg" />
                        </div>
                        :
                        <img className="detailHeaderImg" src={img}></img>
                }

                {/* Main Items */}
                {
                    mainItems == null ? <></> :
                    <span className="detailMainItemsHolder">
                    { 
                        mainItems.map(item => {
                            return ( <span>{item}</span> )
                        })
                    }
                    </span>
                }

                {/* Items */}
                {
                    items == null ? <></> :
                    <span className="detailItemsHolder">
                        { 
                            Object.entries(items).map(item => {
                                return ( 
                                    <span className="detailItemHolder">
                                        <span className="detailItemOne">{item[0]}</span>
                                        <span className="detailItemTwo">{item[1]}</span>
                                    </span> 
                                )
                            })
                        }
                    </span>
                }
            </span>
        </Paper>
    )
}

export default DetailHeader