import gql from "graphql-tag";

const PROFILE_QUERY = gql`
query notifications {
    notifications {
        id
        title
        description
        Date
    }
  }
`;

export default PROFILE_QUERY;