import React, { useState, useContext } from "react"
import ptLocale from "date-fns/locale/pt-BR"; // sorry no pt-PT
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

// example: <MonthYearPicker name="searchdate" label="Mês e Ano" defaultValue={new Date()}   change={changeSearch}/>
const MonthYearPicker = ({defaultValue, change, ...props}) => {
    const [selectedDate, handleDateChange] = useState(defaultValue);
    defaultValue && selectedDate && defaultValue.toString() != selectedDate.toString() && handleDateChange(defaultValue);
    let date = defaultValue || selectedDate;
    
    return(<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <input name={props.name + "input"} hidden={"true"} value={date && date.toISOString()}/>
          <DatePicker
              {...props}
  
              variant="inline"
              openTo="year"
              views={["year", "month"]}
              value={date}
              onChange={(x) => {handleDateChange(x); document.querySelector("[name="+props.name + "input"+"]").value=x.toISOString(); change && change(x.toISOString());} }
            />
       </MuiPickersUtilsProvider>)
  }

  export default MonthYearPicker;