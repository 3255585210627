import gql from 'graphql-tag';

const BALANCE_CHECK = (clientId) => gql`
query allStrapiBills {
  bills {
      file_name
      nif
      emission_date
      ceme_entity {
        name
        code
      }
      opc_entity {
        name
      }
      totalPrice
  }
}
`;
export default BALANCE_CHECK;