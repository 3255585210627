import React, { useState, useContext, useEffect } from "react"
import './forgot.css'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import logo from "../static/Logo.png"
import { FormGroup, Paper } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import cogoToast from 'cogo-toast';
import Constants from '../constants';
import { Redirect, useHistory } from "react-router-dom";
import { initState } from "../context/AppContext";
const axios = require('axios').default;



function ConfirmPage({ location }) {
    const [state, setState] = useContext(AppContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showSecondPass, setShowSecondPass] = useState(false);
    const [password, setPassword] = useState('');
    const [secondPassword, setSecondPass] = useState('');
    const [hasError, setError] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [secondPasswordError, setSecondPassError] = useState('');

    
    var token = (() => {
        if(!location|| !location.search) {
            return null
        }
        return location.search.substring(14)
    })()
    
    var initPassword = ((pass) => {
        setPassword(pass)
        setError(false)
    })

    function handleSubmit(event) {
        event.preventDefault();
        var forgotError = false

        if (password.length == 0) {
            setPasswordError("Insira password")
            forgotError = true
        } else {
            setPasswordError(null)
        }

        if (secondPassword.length == 0) {
            setSecondPassError("Insira password")
            forgotError = true
        } else {
            setSecondPassError(null)
        }

        if (password != secondPassword){
            setPasswordError("Insira password")
            setSecondPassError("Insira password")
            forgotError = true
        }

        if (forgotError) {
            setError(true)
            return
        }
        setState(state => ({ ...state, isLoading: true }))
        /*axios.post(baseEndpoint + '/auth/reset-password', {
            code: 'privateCode', // code contained in the reset link of step 3.
            password: 'userNewPassword',
            passwordConfirmation: 'userNewPassword',
        }).then((data) => {
            setError(false)

        }).catch((error) => {
            console.log(error)
            setState(state => ({ ...state, isLoading: false }))
        })*/

    }

    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    useEffect(() => {
    });

    console.log(token)
    if (!token) {
        return <Redirect to="/404.html" />
    }

    return (
        <>
            <div className="formContainerPassword">
                <form margin='dense' id="formLogin" autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl>
                        {/* Password */}
                        <TextField
                            className="inputLogin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClick}
                                            edge="end">
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            id="loginPassword"
                            label="Password"
                            value={password}
                            onInput={e => initPassword(e.target.value)}
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            error={hasError ? true : null}
                            helperText={hasError ? passwordError : null}
                        />

                        {/* Password */}
                        <TextField
                            className="inputLogin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClick}
                                            edge="end">
                                            {showSecondPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            id="loginPassword"
                            label="Repita a Password"
                            value={secondPassword}
                            onInput={e => initPassword(e.target.value)}
                            variant="outlined"
                            type={showSecondPass ? 'text' : 'password'}
                            error={hasError ? true : null}
                            helperText={hasError ? secondPasswordError : null}
                        />

                        <Button
                            type="submit"
                            id="loginBtn"
                            variant="contained"
                            color="primary">Confirmar Palavra-Passe</Button>
                    </FormControl>
                </form>
            </div>
        </>
    )
}


export default ConfirmPage