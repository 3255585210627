/**
 * Layout component that query some menu holders
 */
import React, { useState, useEffect } from "react"
import Layout from "../layout"
import { Button, DialogActions, FormControlLabel, Grid, IconButton, makeStyles, Switch, TextField, withStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import CancelIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import black_arrow_down from "../../static/black_arrow.png"
import DialogTitle from '@material-ui/core/DialogTitle';
import cancelImg from '../../static/cancel.svg'
import '../../css/variables.css'
import './picker-modal.css'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt";
import { blue, red } from "@material-ui/core/colors";
import CustomDropDown from "../drop/customDropdown";
import Moment from "moment";

function PickerModal({ filterElements, showFilter, setShowFilter, setColumns, listToFilter, cemeEntities, opcEntities, updateFilterObject }) {
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [switchItem, setSwitchItem] = useState({ id: '', isChecked: false })
    const closeModal = () => {
        setShowFilter(false);
    };

    var handleChange = (item, index) => {
        var filterItem = filterElements[index]
        var isChecked = filterItem.isActive
        filterItem.isActive = !isChecked
        setSwitchItem({ id: index, isChecked: filterItem.isActive })
        listToFilter(filterElements)
        setColumns()
    };
    
    /* State for Object */
    const [filterObject, setFilterObject] = useState({ cemeId: '', opcId: '', date: '', searchBoth: false });
    var setCemeObject = (name) => {
        filterObject.cemeId = name
        setFilterObject(filterObject)
    }

    var setOpcObject = (name) => {
        filterObject.opcId = name
        setFilterObject(filterObject)
    }

    var setObjectDate = (date) => {
        filterObject.date = date
        setSelectedDate(date)
        setFilterObject(filterObject)
    }

    var applyFilter = () => {
        if(filterObject.cemeId !== "" && filterObject.opcId !== ""){
            filterObject.searchBoth = true
        }
        updateFilterObject(filterObject)
        closeModal()
    }

    var clearFilter = () => {
        setSelectedDate("")
        setFilterObject({})
        updateFilterObject({})
        closeModal()
    }

    /* State for Date*/
    const handleDateChange = (date) => {
        let dateResult = Moment(new Date(date)).format("DD/MM/YYYY");
        setObjectDate(dateResult);
        setSelectedDate(Moment(dateResult).format("DD/MM/YYYY"));
    };

    function getCurrentDate() {
        return Moment().format("MM/DD/YYYY");
    }

    const CustomSwitch = withStyles({
        switchBase: {
            color: '#00CCF2',
            '&$checked': {
                color: '#2D62ED',
            },
            '&$checked + $track': {
                backgroundColor: blue[50],
            },
        },
        thumb: {
            width: 18,
            height: 18,

        },
        checked: {},
        track: {
            width: 108,
            height: 22,
            borderRadius: 26 / 2,
            backgroundColor: blue[50],
            opacity: 1,
        },
    })(Switch);

    const filterIndicatorModal = (
        <Dialog
            open={showFilter}
            onClose={closeModal}
            className="filter-dialog"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <span className="alert-dialog-title">{"Filtros"}</span>
                <IconButton className="close-img-filter">
                    <CancelIcon color="primary" fontSize="small" src={cancelImg} onClick={() => closeModal()} />
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content" style={{ width: "445px" }}>
                <div style={{ display: "inline-flex", flexDirection: "column" }}>
                    <div style={{ display: "inline-flex", marginBottom: "15px" }}>
                        <CustomDropDown currentOption={"Selecionar CEME"} options={cemeEntities} updateOption={(option) => setCemeObject(option)} showPopover={false} />
                    </div>
                    <div style={{ display: "inline-flex", marginBottom: "15px" }}>
                        <CustomDropDown currentOption={"Selecionar OPC"} options={opcEntities} updateOption={(option) => setOpcObject(option)} showPopover={false} />
                    </div>
                    <div style={{ display: "inline-grid", marginBottom: "15px" }}>
                        <span className="indicator-filter-title" style={{ color: "#272D3B" }} >Definir Data</span>
                        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="static"
                                format="dd/MM/yyyy"
                                id="date-picker-static"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                autoOk
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={clearFilter} color="primary">Limpar</Button>
                <Button onClick={applyFilter} color="primary">Confirmar</Button>
            </DialogActions>
        </Dialog>
    );

    const filter = (
        <Dialog
            open={showFilter}
            onClose={closeModal}
            className="filter-dialog"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <span className="alert-dialog-title">{"Colunas"}</span>
                <IconButton className="close-img-filter">
                    <CancelIcon color="primary" fontSize="small" src={cancelImg} onClick={() => closeModal()} />
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content">
                {
                    filterElements == null ?
                        { filterIndicatorModal }
                        :
                        filterElements.map((item, index) => (
                            <div key={item.title} style={{ display: "flex", alignItems: "center" }}>
                                <span className="filter-title">{item.title}</span>
                                <CustomSwitch
                                    className="custom-switch"
                                    checked={switchItem.id === index ? switchItem.isChecked : (item.isActive || typeof item.isActive == 'undefined')}
                                    onChange={() => handleChange(item, index)}
                                    name={item.title}
                                />
                            </div>
                        ))
                }
            </DialogContent>
        </Dialog>
    );

    return (
        <>
            <div className="filter-modal">
                {
                    filterElements == null ?
                        filterIndicatorModal
                        :
                        filter
                }
            </div>
        </>
    )
}
export default PickerModal