import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;

export function tokenRequest(email, password, success, failure) {
    axios.post(baseEndpoint+ endpoints.TOKEN_ENDPOINT.value, {
        identifier: email,
        password: password,
    })
        .then((data) => {
            success(data)
        })
        .catch((error) => {
            failure()
        })
}