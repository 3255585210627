/**
 * Layout component that query some menu holders
 */
import React, { useState, useContext } from "react"
import Indicator from "../components/entityindicators/indicator"
import Table from "../components/table/table"
import Chart from "../components/charts/chart"
import { AppContext } from "../context/AppContext";
import OPCS_QUERY from "../queries/opcs";
import TopButtons from "../components/topBtn/top-btn"
import axios from 'axios'
import { useQuery } from 'react-query'
import { baseEndpoint, convertDayString, parseFloatNew, topCalcFormatNumber, UnitSymbols  } from "../utils/utils";
import Layout from "../components/layout"


var formatCell = function(cell, formatterParams, onRendered){
  //cell - the cell component
  //formatterParams - parameters set for the column
  //onRendered - function to call when the formatter has been rendered
      var field = cell.getField();
      if (cell.getData().bad.includes(field)) {
        cell.getRow().getElement().style.color = "indianred";
        cell.getRow()._row.cells.forEach(c=> c.element.style.color = "indianred")
        cell.getElement().style.color = "red";
      }

  return cell.getValue(); //return the contents of the cell;
}

let columns = [
  { title: "Dia Referência", field: "dia_referencia", headerFilter:true , isActive: true, sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
    let dateA = new Date(a.substring(6,10), a.substring(3,5) - 1, a.substring(0,2));
    let dateB = new Date(b.substring(6,10), b.substring(3,5) - 1, b.substring(0,2));
    
    return dateA - dateB
  }},
  { title: "Id Provedor", field: "idServiceProvider", headerFilter:true },
  // {//create column group
  //   title:"Dados Subutilização (unidade carregamento)",
  //   columns:[  
      { title: "Id subutilização", field: "idSubUsage", headerFilter:true },
      // { title: "Id Dia", field: "idDay", headerFilter:true  },
      { title: "Preco Opc" + UnitSymbols.value, field: "preco_opc", topCalc:topCalcFormatNumber },
      { title: "Duração Período" + UnitSymbols.duration, field: "periodDuration" },
      { title: "Preco Unitario Opc Tempo" + UnitSymbols.value, field: "preco_unitario_opc_tempo", formatter:formatCell },
      { title: "Preco Unitario Opc Energia" + UnitSymbols.value, field: "preco_unitario_opc_energia", formatter:formatCell },
      { title: "Preco Unitario Opc Ativação" + UnitSymbols.value, field: "preco_unitario_opc_ativacao", formatter:formatCell },
      { title: "Preco Opc Tempo" + UnitSymbols.value, field: "preco_opc_tempo" },
      { title: "Preco Opc Energia" + UnitSymbols.value, field: "preco_opc_energia" },
      { title: "Preco Opc Ativação" + UnitSymbols.value, field: "preco_opc_ativacao" },
      { title: "Energia Total Periodo" + UnitSymbols.energy, field: "energia_total_periodo" },
  // ]
  // },
  // {//create column group
  //   title:"Dados Sessão Utilização (agregador superior)",
  //   columns:[
  //     { title: "Id utilização", field: "idUsage", headerFilter:true },
  //     { title: "Ficheiro", field: "file_name", headerFilter:true },
  //     { title: "Entidade", field: "entityname", headerFilter:true },
  //     { title: "Nº Id Externo", field: "idExternalNumber", headerFilter:true },
  //     { title: "Nº Id Interno", field: "idInternalNumber", headerFilter:true },
  //     // { title: "Operador", field: "idNetworkOperator", headerFilter:true },
  //     { title: "Id Carregador", field: "idChargingStation", headerFilter:true },
  //     { title: "Id EVSE", field: "idEVSE", headerFilter:true },
  //     { title: "timestamp inicio", field: "startTimestamp", headerFilter:true, },
  //     { title: "timestamp fim", field: "stopTimestamp", headerFilter:true },
  //     { title: "Duração Total" + UnitSymbols.duration, field: "totalDuration" },
  //     { title: "Energia Total Transacao" + UnitSymbols.energy, field: "energia_total_transacao"},

  //     ]
  // }
  
  
];

function getSubUsages(data){
  let elements = [];
  data.forEach(file => 
    {
      file.Usages.forEach(usage=>{
        usage.SubUsage.forEach(sub=>{
          var el = JSON.parse(JSON.stringify(sub));
          el.file_name = file.file_name;
          Object.keys(usage).forEach(k => el[k] = usage[k])
          delete el.SubUsage
          elements.push(el)
        })
      })
    }

    )
  return elements;
}

const OpcPage = () => {
  const [state, setState] = useContext(AppContext);
  let clientId = state.currentClient.id
    
  const { isLoading, error, data } = useQuery(['opcs', {clientId: clientId}], () =>
  axios(baseEndpoint + '/opcfiltered?clientId='+clientId, state.queryAuthHeaders()))
  var dataQuery = data ? data.data.opcs : {opcs: []};

  return (
   
    <span>
      <TopButtons buttons = {['Exportar PDF']}/>
      {/* <div className="indicatorParentHolder">
        <Indicator dataQuery={dataQuery} isLoading={isLoading} type={"opc"} title={"Energia Total"} entityName="PRIO"/>
        <Indicator dataQuery={dataQuery} isLoading={isLoading} type={"opc"} title={"Duração Total"}/>
      </div> */}
      <Table
        isLoading={isLoading}
        columns={columns}
        data={dataQuery}
        boxtitle="OPC Final"
        mode="allEnabled"
      ></Table>
{/* 
      <Chart dataQuery={dataQuery} isLoading={isLoading} entityType="opc" title="Energia Total" type="bar" entityName="PRIO"/>
      <Chart dataQuery={dataQuery} isLoading={isLoading} entityType="opc" title="Duração Total" type="line" entityName="PRIO"/>*/}
    </span> 
    
  )
}

export default OpcPage
