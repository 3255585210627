import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import Checkbox from '@material-ui/core/Checkbox';
import './checkboxlist.css'
import { Radio, RadioGroup } from "@material-ui/core";
// types -> "ceme" / "opc"
// ({callback, entities, setCurrentList, setShowingEntities})
// ATENCAO
// É necessario props.entities no seguinte formato { nome: true, nome: false }
const CheckboxList = forwardRef((props, ref) => {
    var view = props.viewType
    const [state, setState] = React.useState(props.entities)
    const [error, setError] = React.useState(null)
    const handleChange = (event, keepData) => {     
        var data = keepData ? state : null;        
        setState({...data, [event.target.name]: event.target.checked});
    };

    function callCheckBoxFunc() {
        let nrSelected = Object.values(state).filter(x => x === true).length
        // eslint-disable-next-line default-case
        switch (view) {
            case "graph":
                if (nrSelected == 0) {
                    setError("Tem de seleccionar pelo menos uma entidade")
                } else if (nrSelected > 6) {
                    setError("Seleccione no máximo 6 entidades")
                } else {
                    setError(null)
                    props.setFiltered(state)
                    props.toggleList()
                }
                break;
            case "indicator":
                if (nrSelected == 0) {
                    setError("Seleccione entidade")
                }else if(nrSelected == 1){
                    setError(null)
                    props.setFiltered(state)
                    props.toggleList()
                } else if (nrSelected > 1) {
                    setError("Só pode selecionar uma entidade")
                }
                break;
        }
    }

    useImperativeHandle(ref, () => ({
        getAlert() {
            callCheckBoxFunc();
        }
    }));

    return (
        <span >
            {
                error != null ? <span className="checkError">{error}</span> : <></>
            }
            <span className= {view == "graph" ? "checkHolder": "checkHolder"}>                      
                {
                   
                    Object.entries(props.entities)
                        .sort((a, b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0))
                        .map(item => {
                          
                            return (
                               
                                <span className="checkItem">
                                    <Checkbox
                                        checked={!!state[item[0]]}
                                        onChange={(e) => handleChange(e,view=="graph")}
                                        name={item[0]}
                                        color="primary"
                                    />
                                    <a className={"title-name"}>{item[0]}</a>                            

                                </span>
                            )
                        })
                }           
            </span>
        </span>
    )
});

export default CheckboxList