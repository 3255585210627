import React, {useState, useContext, useEffect} from "react"
import Layout from "../components/layout"
import TopButtons from "../components/topBtn/top-btn";
import Table from "../components/table/table"
import { useQuery } from "@apollo/client";
import { PARTNERS_COMERCIAL_QUERY } from "../queries/partners";
import { useHistory } from "react-router-dom";
import { AppContext, checkIfUnauthorized } from "../context/AppContext";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import cogoToast from 'cogo-toast';
import { removePartner } from "../utils/requests/removePartner"

const axios = require('axios').default;

const ComercialPage = () => {
  const [state, setState] = useContext(AppContext);

  let clientId = state.currentClient.id
  let query = PARTNERS_COMERCIAL_QUERY(clientId)
  let history = useHistory()
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data, loading, error , refetch} = useQuery(query, {fetchPolicy: "no-cache"});
  const [cellDelete, setCellDelete] = useState(null);

  let columns = [
    { title: "NIF", field: "nif", headerFilter:true, isActive : true},
    { title: "Nome", field: "name" , headerFilter:true, isActive : true},
    { title: "Endereço", field: "address" , headerFilter:true, isActive : true},
    { title: "", field: "",formatter:"buttonCross", isActive : true, width:40, align:"center", cellClick:function(e, cell){
      setCellDelete(cell)
      //cell.getRow().delete();
      //rowJson = cell.getRow().getData();
      //let partnerId = cell.getRow().getData().id;
      setDialogOpen(true);
      e.stopPropagation();
    }}
  ];

  
  function goBack() {
    history.goBack()
  }

  function showDetail(partner) {
    history.push('/comercial/detail/'+partner.id, partner)
  }

  function deletePartner() {
    setState(state => ({ ...state, isLoading: true }))

    // We are going to update the partner client list
    // We don't delete the partner, just cut the relation 
    let partner = cellDelete.getRow().getData()
    removePartner(state, partner,  (success) => {
      cellDelete.getRow().delete();
      setCellDelete(null)
      setState(state => ({ ...state, isLoading: false }))
      cogoToast.success('Parceiro removido com sucesso');
      refetch()
    }, (error) => {
      setCellDelete(null)
      setState(state => ({ ...state, isLoading: false }))
      let wasUnauthorized = false
      if (error.response) {
        wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
      }
      if (!wasUnauthorized) {
        cogoToast.error('Erro ao adicionar parceiro');
      }
    })

    setDialogOpen(false);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const deletePartnerDialog = (
    <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">Tem a certeza que deseja remover este parceiro?</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">Não</Button>
        <Button onClick={deletePartner} color="primary" autoFocus>Sim</Button>
        </DialogActions>
    </Dialog>
  );
  return (
  
      <span>
        {
          /* <TopButtons buttons={['Exportar PDF', 'Adicionar Parceiro']}/>*/
        }
        <TopButtons buttons = {['Exportar PDF', 'Adicionar Parceiro']}/>
        <Table
          isLoading={loading}
          mode="allEnabled"
          columns={columns}
          data={ data && data.partners.length > 0 ? data.partners.map( node => {
            return JSON.parse(JSON.stringify(node))
          }) : [] } 
          boxtitle="Parceiro"
          itemClicker={showDetail}
          dataLayout="fitColumns">
        </Table>

        {/* Delete partner dialog */}
        {deletePartnerDialog}
      </span>
   
  )
}
export default ComercialPage
