import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;


export function createUser(formData, state, success, failure) {
    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
        headers: {
            Authorization: currentToken,
            'Content-Type': 'multipart/form-data'
        }
    }
    
    console.log(state)
    axios.post(baseEndpoint + endpoints.REGISTER_ENDPOINT.value, formData, config)
    .then((data) => {
        success()
    }).catch((error) => {
        failure()
    })
}