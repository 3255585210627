import React, {useContext}  from "react"
import ProfileIcon from '@material-ui/icons/AccountCircle';
import "./profile-item.css"
import { AppContext  } from "../../../context/AppContext"
import { baseEndpoint } from "../../../utils/utils"
function ProfileItem() {
    let isSelected = window.location.pathname == "/profile"
    const [state, setState] = useContext(AppContext);

    let userProfileImg = (()=> {
        let profileImg = state.currentUser.profileImg
        if(!profileImg) {
            return null
        }
        return baseEndpoint+profileImg.formats.thumbnail.url
    })()

    return (
        <>
            <div className="menu-holder">
                <a className={isSelected ? "menu-selected" : "menu-not-selected"} 
                    style={{cursor: "pointer", display: "inline-flex", marginBottom: '20px', marginTop: '20px'}}>

                    <div className={isSelected ? "border-show-profile" : "border"}/>
                    {
                        userProfileImg ? 
                            <img className="userPhoto" src={userProfileImg}></img>
                        :
                            <ProfileIcon fontSize="large" className="profileIcon"/>
                    }
                    <span className="title profileSpan" style={{ color: "white" }}>Perfil</span> 
                </a>
            </div>
        </>
    )
}

export default ProfileItem