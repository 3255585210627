import React from "react"
import "./logout-item.css"
import logout_icon from "../../../static/logout_white.png"
import { Icon } from "@material-ui/core";

function LogoutItem() {
    const logoutIcon = (
        <Icon className="logoutIcon">
          <img  src={logout_icon}  />
        </Icon>
      );

    return (
        <>
            <div className="menu-holder">
                <a className={"menu-not-selected"} 
                    style={{cursor: "pointer", display: "inline-flex", marginBottom: '20px', marginTop: '20px'}}>

                    <div className={"border"}/>
                    {logoutIcon} 
                    <span className="title profileSpan" style={{ color: "white" }}>Terminar Sessão</span> 
                </a>
            </div>
        </>
    )
}

export default LogoutItem