/**
 * Layout component that for indicator
 */
import React, { useState, useRef } from "react"
import './indicator.css'
import settings_img from "../../static/options_img.png"
import notification from "../../static/notification.png"
import arrowUpImg from "../../static/icon-arrow-up-circle.png"
import arrowDownImg from "../../static/icon-arrow-down-circle.png"
import arrowSameImg from "../../static/icon-arrow-same-circle.png"
import CustomDropDown from "../drop/customDropdown"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';
import FilterIcon from '@material-ui/icons/FilterList';
import CheckboxList from '../checkboxlist/checkboxlist';
import DoneIcon from '@material-ui/icons/Done';
import { IconButton } from "@material-ui/core"
import { element, node } from "prop-types"
import PickerModal from '../picker-modal/picker-modal'
import moment from 'moment';
import {formatNumber} from '../../utils/utils'
import '../../components/charts/chart.css'
import Skeleton from '@material-ui/lab/Skeleton';

function Indicator({ dataQuery, isLoading, type, title, index , clientId, entityName}) {
  var filtered = {};
  // TODO why have two separate, and why even do it different here and in chart
  var filtered = {};
    if(entityName){
        filtered[entityName] = true;
    }
  const lastDay = "Últimas 24h";
  const lastWeek = "Última semana";
  const lastMonth = "Último mês";
  const lastYear = "Último ano";
  var isCeme = type === "ceme"
  var isOPC = type === "opc"
  let options = getOptions()
  let cemeList = []
  let opcList = []
  const [currentOption, setCurrentOption] = useState(isCeme ? options[2] : options[2]);
  const [showingEntities, setShowingEntities] = useState(false);
  const [showingFilter, setShowingFilter] = useState(false);
  const [filteredEntityList, setFiltered] = useState(filtered)
  const [entityFiltered, setEntityFiltered] = useState({})

  var previousData = [];
  var data = [];
  let selectedObjectList = {}
  var mostQuantityEntity = null
  var entitySelected = getKeyByValue(filteredEntityList, true)
  let dateDictionary = {}
  var endDate = ""
  let dataArray = {}
  var entitiesList = []
  let hasInfo = false
  var totalPreviousCounter = 0;
  var totalCounter = 0
  var differencePercentage = 0;


  function getOptions() {
    if(isCeme){
      return [lastWeek, lastMonth, lastYear]
    }else if(isOPC){
      return [lastWeek, lastMonth, lastYear]
    }else {
      return []
    }
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  function toggleSeeingEntities() {
    setShowingEntities(!showingEntities)
  }

  function setShowIndicatorFilter(){
    setShowingFilter(!showingFilter)
  }

  Date.prototype.standardPrint = function () {
    var testMonth = this.getMonth() + 1;

    if (testMonth < 10) {
      testMonth = '0' + testMonth;
    }

    var testDay = this.getDate();
    if (testDay < 10) {
      testDay = '0' + testDay;
    }
    //return [this.getFullYear(), testMonth, testDay].join('-');
    return [testDay, testMonth, this.getFullYear()].join('/');
  }

  function getDateFromUsage(usageDate) {
    return new Date(usageDate.slice(0, -10), // year
      usageDate.slice(4, -8), // month
      usageDate.slice(6, -6), // day
      usageDate.slice(8, -4), // hours
      usageDate.slice(10, -2), // minutes
      usageDate.slice(12, 0) // seconds
    )
  }

  Date.prototype.printDateWithHours = function () {
    var testMonth = this.getMonth() + 1;

    if (testMonth < 10) {
      testMonth = '0' + testMonth;
    }

    var testDay = this.getDate();
    if (testDay < 10) {
      testDay = '0' + testDay;
    }

    var testHours = this.getHours();
    if (testHours < 10) {
      testHours = '0' + testHours;
    }
    return [this.getFullYear(), testMonth, testDay, testHours].join('-');
  }

  function daysInYear(year){
    return isLeapYear(year) ? 366 : 365;
  }

  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  function getLastDay() {
    var result = [];
    var date = new Date();
    var day = date.setDate(date.getDate() - 1);
    result.push({ date: day })
    return result.reverse();
  }

  function getLastWeek() {
    var result = [];

    // Last week
    var resultPrevious = []
    var lessOneWeek = moment().subtract(1, 'week');
    var startOfPreviousWeek = lessOneWeek.startOf('week').toDate();
    var endOfPreviousWeek = lessOneWeek.endOf('week').toDate();
  
    while(startOfPreviousWeek < endOfPreviousWeek) {
      var d = startOfPreviousWeek
      let day = d.toLocaleString('default', { weekday: 'short' })
      let month = d.toLocaleString('default', { month: 'short' }).slice(0, -1)
      resultPrevious.push({ date: new Date(d.getTime()), toString: day + " " + month, dateFormat: d.standardPrint() })
      startOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 1);
    }
    result.push(resultPrevious)

    // Current week
    var resultCurrent = []
    var startOfWeek = moment().startOf('week').toDate();
    var today  = moment().toDate();
    while(startOfWeek < today) {
      var d = startOfWeek
      let day = d.toLocaleString('default', { weekday: 'short' })
      let month = d.toLocaleString('default', { month: 'short' }).slice(0, -1)
      resultCurrent.push({ date: new Date(d.getTime()), toString: day + " " + month, dateFormat: d.standardPrint() })
      startOfWeek.setDate(startOfWeek.getDate() + 1);
    }
    result.push(resultCurrent)
    return result
  }

  function getLastMonth() {
    var result = [];

    // Last Month
    var resultPrevious = []
    var previousMonthDate = new Date();
    previousMonthDate.setDate(0); // 0 will result in the last day of the previous month
    previousMonthDate.setDate(1); // 1 will result in the first day of the month
    let previousMonthYear = previousMonthDate.getFullYear()
    let previousMonth = previousMonthDate.getMonth()
    let previousMonthNrDays = new Date(previousMonthYear, previousMonth+1, 0).getDate();
    for (var i = 1; i <= previousMonthNrDays; i++) {
      var d = new Date(previousMonthYear, previousMonth, i);
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' }).slice(0, -1)
      resultPrevious.push({ date: d, toString: day + " " + month, dateFormat: d.standardPrint() })
    }
    result.push(resultPrevious)

    // Current month
    var resultCurrent = []
    var currentDate = new Date();
    let currentMonthYear = currentDate.getFullYear()
    let currentMonthNr = currentDate.getMonth()
    let currentMonthNrDays = new Date(currentMonthYear, currentMonthNr+1, 0).getDate();
    for (var i = 1; i <= currentMonthNrDays; i++) {
      var d = new Date(currentMonthYear, currentMonthNr, i);
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' }).slice(0, -1)
      resultCurrent.push({ date: d, toString: day + " " + month, dateFormat: d.standardPrint() })
    }
    result.push(resultCurrent)
    return result;
  }

  function getLastYear() {
    var result = [];

    // Last one
    var resultPrevious = []
    var newDate = new Date(new Date().getFullYear() - 1, 0, 1);
    
    var previousYear = newDate.getFullYear()
    for (var i = 1; i <= daysInYear(previousYear); i++) {
      var d = new Date(previousYear, 0, i);
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' }).slice(0, -1)
      resultPrevious.push({ date: d, toString: day + " " + month, dateFormat: d.standardPrint() })
    }
    result.push(resultPrevious)

    // Current
    var resultCurrent = []
    var today = new Date();
    var todayYear = today.getFullYear()
    for (var i = 1; i <= daysInYear(todayYear); i++) {
      var d = new Date(todayYear, 0, i);
      
      if(d > today) {
        break;
      }
      let day = d.getDate();
      let month = d.toLocaleString('default', { month: 'short' }).slice(0, -1)
      resultCurrent.push({ date: d, toString: day + " " + month, dateFormat: d.standardPrint() })
    }
    result.push(resultCurrent)
    return result;
  }

  // This function will be returning the current time interval and the previous
  // ex: Year current year is 2020 -> returns [2019, 2020]
  // ex: Month current month is 12/2020 -> returns [11/2020, 12/2020]
  // ex: Day current day is 3/12/2020 -> returns [2/12/2020, 3/12/2020]
  // ex: Week current is ex: 49 returns [48, 49]
  // This will be returning all the days regarding each timeinterval, ex: [2019, 2020] ->[[all the days of 2019], [all the days of 2020]]
  function getDateFilter() {
    switch (currentOption) {
      case lastDay:
        return getLastDay()
      case lastWeek:
        return getLastWeek()
      case lastMonth:
        return getLastMonth()
      case lastYear:
        return getLastYear()
    }
  }

  function prepareData() {
    var dateArray = getDateFilter()
    switch (type) {
      case "ceme":
        prepareCemeData(dateArray)
        break;
      case "opc":
        prepareOpcData(dateArray)
        break;
      case "bill":
        prepareBillData()
        break;

    }
  }

  function getQuantityFromTitleType(node) {
    let value = null
    if (type == "opc") {
      switch (title) {
        case "Energia Total":
          value = node.energia_total_transacao
          break;
        case "Duração Total":
          value = node.totalDuration
          break;
      }
    } else if (type == "ceme") {
      switch (title) {
        case "Carregamentos":
          value = node.numero_carregamentos
          break;
        case "Energia":
          value = node.energia_total
          break;
        case "Duração":
          value = node.duracao_total
          break;
        case "Preço Total":
          value = node.preco_opc_total
          break;
      }
    } else if (type == "bill"){
      value = node.totalPrice
    }
    
    return parseFloat(value.toString().replace(',','.'))
  }

  function getTitle() {
    if (entityFiltered == null && title == null) {
      return ""
    }

    var cemeId = entityFiltered.cemeId
    var opcId = entityFiltered.opcId
    var date = entityFiltered.date
    var multipleFilter = entityFiltered.searchBoth

    if(multipleFilter){
      return "Fatura"
    }else if(cemeId !== undefined && cemeId !== ""){
      return "CEME"
    }else if(opcId !== undefined && opcId !== ""){
      return "OPC"
    }else if(date !== undefined && date !== ""){
      return "Fatura"
    }else if(title !== ""){
      return title
    } 
  }

  function prepareBillData() {
    if (!dataQuery) {
      return;
    }

    var billList = []
    var cemeObjects = []

    dataQuery.bills.forEach(bill => {
      let cemeName = bill.ceme_entity.name
      let opcName = bill.opc_entity.name
      let billPrice = getQuantityFromTitleType(bill)
      let billDate = bill.emission_date
      var bill = { cemeName, opcName, billPrice, billDate }
      if (!(cemeList.includes(cemeName))) {
        cemeList.push(cemeName)
      }
      if (cemeObjects.length <= 3) {
        cemeObjects.push(bill)
      }

      if (!(opcList.includes(opcName))) {
        opcList.push(opcName)
      }
     
      var bill = { cemeName, opcName, billPrice, billDate }
      billList.push(bill)
    })
    var filteredBills = []
    if (Object.entries(entityFiltered).length !== 0) {
      var filteredCeme = entityFiltered.cemeId
      var filteredOPC = entityFiltered.opcId
      var filteredDate = entityFiltered.date
      billList.forEach(billElement => {
        var billCeme = billElement.cemeName
        var billOPC = billElement.opcName
        if (filteredCeme !== "" && filteredOPC !== "") {
          if (filteredCeme == billCeme && filteredOPC == billOPC) {
            filteredBills.push(billElement)
          }
        } else if (filteredCeme !== "") {
          if (filteredCeme == billCeme) {
            filteredBills.push(billElement)
          }
        } else if (filteredOPC !== "") {
          if (filteredOPC == billOPC) {
            filteredBills.push(billElement)
          }
        } else if (filteredDate !== "") {
          var billDate = billElement.billDate;
          endDate = filteredDate;
          var dateFiltered = moment(filteredDate).format('YYYY-DD-MM');
          if (moment(billDate).isSameOrBefore(dateFiltered)) {
            filteredBills.push(billElement)
          }
        }
      })
      data = filteredBills
      data.forEach(element => {
        totalCounter += (element.billPrice)
      })
    } else {
      if(cemeObjects.length == 1){
        index = 0
      }
      var billElement = cemeObjects[index]
      var billCeme = billElement.cemeName
      var billPrice = billElement.billPrice
      entityFiltered.cemeId = billCeme
      entityFiltered.billPrice = billPrice
      filteredBills.push(entityFiltered)
      data = filteredBills
      data.forEach(element => {
        totalCounter = element.billPrice
      })
    }
    
  }

  function prepareCemeData(dateArray) {
    if (!dataQuery) {
      return;
    }

    dataQuery.cemes.forEach(ceme => {
      let dateString = (ceme.dia_referencia || "").slice(0, 10) // todo: date comes f-ed up(in ISO), fix some other way
      if (dateDictionary[dateString] == null) {
        dateDictionary[dateString] = []
      }

      // For all entries, prepare an object dateDictionary with date: { name, quantity}
      let name = ceme.ceme_id
      let quantity = getQuantityFromTitleType(ceme)
      if (!(dataArray.hasOwnProperty(name))) {
        dataArray[name] = null
      }
      dateDictionary[dateString].push({ name: name, quantity: quantity })
    })

    
    // dateArray contains all the dates within the time filter selected
    // This sections checks the last entry
    if (filteredEntityList == null || Object.keys(filteredEntityList).length === 0) {
      let entityCounter = {}
      
      if (dateArray != null && dateArray.length == 2 && dateArray[1].length > 0) {
        for(let timeNode of dateArray[1].reverse()) {
          if(dateDictionary[timeNode.dateFormat] != null) {
            dateDictionary[timeNode.dateFormat].forEach(cemeCounter => {
              if (entityCounter[cemeCounter.name] == null) {
                entityCounter[cemeCounter.name] = 0
              }
              entityCounter[cemeCounter.name] = entityCounter[cemeCounter.name] + cemeCounter.quantity
              // Removed break here so we could get with the highest counter
            })
          }
        }
        var currentHighestValue = 0
        for (var [key, value] of Object.entries(entityCounter)) {
          if (value > currentHighestValue) {
            currentHighestValue = value
            mostQuantityEntity = key
          }
        }
      }
    }
  

    // Iterate all the dates within the time filter
    // check if that entry exists in dateDictionary
    // If so, add it to an object that contains all the values for the current
    // time interval
    var indexDate = 0
    dateArray.forEach(dateInterval => {
      let newDataArray = []
      dateInterval.forEach(timeNode => {
        if (dateDictionary[timeNode.dateFormat] != null) {
          let newObj = {}
          newObj["day"] = timeNode.toString
          dateDictionary[timeNode.dateFormat].forEach(cemeCounter => {
            
            if (mostQuantityEntity != null) {
              if (mostQuantityEntity == cemeCounter.name) {
                selectedObjectList[cemeCounter.name] = true
                newObj[cemeCounter.name] = cemeCounter.quantity
              }
            } else {
              if (filteredEntityList[cemeCounter.name] === true) {
                newObj[cemeCounter.name] = cemeCounter.quantity
              }
            }
            selectedObjectList[cemeCounter.name] = filteredEntityList[cemeCounter.name]
          })
          newDataArray.push(newObj);
        }
        
      })
      if(indexDate == 0) {
        previousData = newDataArray;
        indexDate += 1;
      } else {
        data = newDataArray;
      }
    })
    

    if (mostQuantityEntity != null) {
      selectedObjectList[mostQuantityEntity] = true
      entitySelected = getKeyByValue(selectedObjectList, true)
    }
    entitiesList = selectedObjectList;

    // Previous
    previousData.forEach(element => {
      if(!isNaN(element[entitySelected])) {
        totalPreviousCounter += element[entitySelected]
      }
    })

    // Current
    data.forEach(element => {
      if(!isNaN(element[entitySelected])) {
        totalCounter += element[entitySelected]
      }
    })

    if(totalCounter == null) {
      totalCounter = 0
      totalPreviousCounter = 0
    }
    totalCounter = totalCounter
    totalPreviousCounter= totalPreviousCounter
    if(totalPreviousCounter == 0) {
      differencePercentage = 0
    } else {
      differencePercentage = 100 *  ( totalCounter - totalPreviousCounter ) / totalPreviousCounter
    }
    
    if(totalCounter == 0 && totalPreviousCounter == 0) {
      differencePercentage = 0
    }
  }

  function prepareOpcData(dateArray) {
    if (!dataQuery) {
      return;
    }
    dataQuery.opcs.forEach(usage => {
        let date = getDateFromUsage(usage.startTimestamp)
        let startTimeStamp = null
        startTimeStamp = currentOption == lastDay ? date.printDateWithHours() : date.standardPrint()

        if (dateDictionary[startTimeStamp] == null) { dateDictionary[startTimeStamp] = [] }

        let name = usage.idServiceProvider
        let quantity = getQuantityFromTitleType(usage)
        dateDictionary[startTimeStamp].push({ name: name, quantity: quantity })
    })

    // dateArray contains all the dates within the time filter selected
    // This sections checks the last entry
    if (filteredEntityList == null || Object.keys(filteredEntityList).length === 0) {
      let counter = {}
      if (dateArray != null && dateArray.length == 2 && dateArray[1].length > 0) {
        for(let timeNode of dateArray[1].reverse()) {
          if (dateDictionary[timeNode.dateFormat] != null) {
            dateDictionary[timeNode.dateFormat].forEach(usage => {
              if (counter[usage.name] == null) {
                counter[usage.name] = 0
              }
              counter[usage.name] = counter[usage.name] + usage.quantity
            })
          }
        }

        var currentHighestValue = 0
        for (var [key, value] of Object.entries(counter)) {
          if (value > currentHighestValue) {
            currentHighestValue = value
            mostQuantityEntity = key
          }
        }
      }
    }

    // Iterate all the dates within the time filter
    // check if that entry exists in dateDictionary
    // If so, add it to an object that contains all the values for the current
    // time interval
    var indexDate = 0
    dateArray.forEach(dateInterval => {
      let newDataArray = []
      dateInterval.forEach(timeNode => {
        if (dateDictionary[timeNode.dateFormat] != null) {
          let newObj = {}
          newObj["time"] = timeNode.toString
          dateDictionary[timeNode.dateFormat].forEach(usage => {
            if (mostQuantityEntity != null) {
              if (mostQuantityEntity == usage.name) {
                selectedObjectList[usage.name] = true
                newObj[usage.name] = usage.quantity
              }
            } else {
              if (filteredEntityList[usage.name] === true) {
                newObj[usage.name] = usage.quantity
              }
            }
            selectedObjectList[usage.name] = filteredEntityList[usage.name]
          })
          newDataArray.push(newObj);
        }
      })
      if(indexDate == 0) {
        previousData = newDataArray;
        indexDate += 1;
      } else {
        data = newDataArray;
      }
    })

    if (mostQuantityEntity != null) {
      selectedObjectList[mostQuantityEntity] = true
      entitySelected = getKeyByValue(selectedObjectList, true)
    }
    entitiesList = selectedObjectList;

    // Previous
    previousData.forEach(element => {
      let value = element[entitySelected]
      if(!isNaN(value)) {
        totalPreviousCounter += value
      }
    })

    // Current
    data.forEach(element => {
      let value = element[entitySelected]
      if(!isNaN(value)) {
        totalCounter += value
      }
    })

    if(totalCounter == null) {
      totalCounter = 0
      totalPreviousCounter = 0
    }
    
    differencePercentage = 100 *  ( totalCounter - totalPreviousCounter ) / totalPreviousCounter
    if(totalCounter == 0 && totalPreviousCounter == 0) {
      differencePercentage = 0
    }
  }

  function checkStyle(param) {
    if(param !== "" || param !== undefined){
      return "block"
    }else {
      return "none"
    }
  }
  const BottomCardInfo = () => {
    let colorClass = null
    let imgName = null
    let preDifferenceSymbol = null
    if (differencePercentage > 0) {
      colorClass = "greenPercentage"
      imgName = arrowUpImg
      preDifferenceSymbol = "+"
    } else if(differencePercentage == 0) {
      colorClass = "bluePercentage"
      imgName = arrowSameImg
      preDifferenceSymbol = ""
    } else {
      colorClass = "redPercentage"
      imgName = arrowDownImg
      preDifferenceSymbol = ""
    }
    colorClass = colorClass + " percentage-txt"
    return (
      <span style={{ display: "flex", flexDirection:"column"}}>
        <div className="main-indicator-title">{showingEntities ? "Entidades" : getTitle()}</div>
        <span className="sub-indicator-title">{entitySelected}</span>
        { type === "bill" ?
          <span>
            <span className="main-indicator-subtitle" style={{display: checkStyle(entityFiltered.cemeId)}}>{entityFiltered.cemeId}</span>
            <span className="main-indicator-subtitle" style={{display: checkStyle(entityFiltered.opcId)}}>{entityFiltered.opcId}</span>
            <span className="main-indicator-date">
            {
              endDate !== "" ? 
                <span>A visualizar informação até {endDate} </span>
              :
              <></>
            }
            </span>
            {
              totalCounter != 0 ?
              <span className="value-txt">{formatNumber(parseFloat(totalCounter - totalPreviousCounter))}</span>
              :
              <></>
            }
          </span>
          :
          <div className="percentageDiv">
            <img className="status-img" src={imgName}></img>
            {
              totalCounter != 0 ?
              <span className="value-txt">{formatNumber(parseFloat(totalCounter - totalPreviousCounter))}</span>
              :
              <></>
            }
            {
              
              <span className={colorClass}>{preDifferenceSymbol + formatNumber(parseFloat(differencePercentage)) + ' %'}</span>
            }
  
          </div>
        }
      </span>
    )
  }

  prepareData()
  const childRef = useRef();
  return (
    <>
    {
      
      <div className="indicator-card box-shadow" style={{ marginLeft: '0px' }}>
        {
          isLoading || (!dataQuery) ?
          <Skeleton className="indicatorSkeleton" variant="text" />
          :
          <>
            { /* TOP LINE */ }
            <div className="topHolder">
              {showingEntities ?
                <IconButton className="backBtnChart" onClick={toggleSeeingEntities}> <ArrowBackIcon /> </IconButton>
                :
                <></>
              }
    
              {
                showingEntities ? <div className="table-title-text chart-title">Entidades</div> :<></>
              }
    
              {showingEntities ?
                <></>
                :
                <CustomDropDown currentOption={currentOption} options={options} updateOption={setCurrentOption} showPopover = {true} />
              }
    
              {showingEntities ?
                <IconButton onClick={() => childRef.current.getAlert()}> <DoneIcon /> </IconButton>
                :
                type === "bill" ?
                  <IconButton onClick={setShowIndicatorFilter}> <FilterIcon /> </IconButton>
                  :
                  <IconButton onClick={toggleSeeingEntities}> <SettingsIcon /> </IconButton>
              }
            </div>
    
            { /* Content -> Checkboxlist / Indicator Content */ }
            <div style={{ display: 'inline-block',height: "128px" }}>
              {
                showingEntities ?
                  <CheckboxList ref={childRef} viewType={"indicator"} type={isCeme ? "ceme" : "opc"} entities={entitiesList} toggleList={toggleSeeingEntities} setFiltered={setFiltered} />
                :
                (totalCounter == 0 && totalPreviousCounter == 0) ? 
                  <div className="noContentHolder">
                      <span className="noContentTitle">Sem conteúdo a apresentar</span>
                  </div>
                  :
                  <span style={{ marginLeft: "25px", display: 'inline-block', marginRight: "25px"}}>
                    <BottomCardInfo />
                  </span>
              }
            </div>
    
            {
              showingFilter ? 
                <PickerModal showFilter = {showingFilter} setShowFilter = {setShowIndicatorFilter} cemeEntities={cemeList} opcEntities={opcList} updateFilterObject={setEntityFiltered}  />
              :
                <></>
            }
          </>
        }
      </div>
    }
    </>
  )
};

export default Indicator