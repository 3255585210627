import gql from "graphql-tag";

const OPCS_QUERY = (clientId) => gql`
query opcs {
  opcs(where: 
    { client: 
        {
          id: ${clientId}
        }
    }
  )
  {
    file_name
    Usages {
        energia_total_transacao
        idChargingStation
        idExternalNumber
        idEVSE
        idInternalNumber
        idUsage
        idNetworkOperator
        idServiceProvider
        startTimestamp
        stopTimestamp
        totalDuration
        SubUsage {
        energia_total_periodo
        idDay
        idSubUsage
        preco_opc
        periodDuration
        preco_opc_ativacao
        preco_opc_energia
        preco_opc_tempo
        preco_unitario_opc_ativacao
        preco_unitario_opc_energia
        preco_unitario_opc_tempo
        }
    }
  }

  opcEntities
  {
    id
    code
    name
    date_prices {
        date
        preco_unitario_opc_tempo
    }
  }
}
`;

export default OPCS_QUERY;