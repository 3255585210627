/**
 * Layout component that query some menu holders
 */
import React, { useContext, useState } from "react"
import { Button, IconButton } from '@material-ui/core';
import '../../css/variables.css'
import "./top-btn.css"
import { AppContext } from "../../context/AppContext";
import cogoToast from "cogo-toast";
import { exportPDFPage, baseEndpoint } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import axios from 'axios';
// TODO: fix canvas and uncomment
// import {PDFExtract, PDFExtractOptions} from "pdf.js-extract"; // se não correr, tem de se copiar utils/pdf.js-extract para node_modules
// const pdfExtract = new PDFExtract();
// const options = {}; /* see below */



function TopButtons({ buttons, changeTitle, setModalVisible, onClickers }) {
    const [state, setState] = useContext(AppContext);
    let clientId = state.currentClient.id;
    // const [currentFile, setCurrentFile] = useState(null)
    const [list, selectOption] = useState({ options: buttons });
    let history = useHistory()
    let exportPDF = () => {
        exportPDFPage()
    };

    
    var fileRef = React.createRef();
    var fileSelectedHandler = (event) => {
        let file = event.target.files[0];
        uploadFile(file);
    }

    // TODO finish up pdf data extraction and submission
    function uploadFile(file) {
        if(file == null){
            return;
        }
        var file_name = file.name;
        var reader = new FileReader();
        reader.onload = function() {
      
          var arrayBuffer = this.result,
          array = new Uint8Array(arrayBuffer),
          binaryString = String.fromCharCode.apply(null, array);
        // TODO: fix canvas and uncomment

        //   pdfExtract.extractBuffer(arrayBuffer, options)
        //     .then(data => {
        //         var words = data.pages[0].content.map(x=> x.str); 
                
        //         var total = words[words.findIndex(x=> x.includes("TOTAL"))+1];
        //         var date = words[words.findIndex(x=> x.includes("EMISSÃO"))+1];
        //         date = Moment(date, "DD.MM.YYYY").toISOString();
        //         var code = words[words.findIndex(x=> x.includes("NIPC"))].split(': ')[1];
        //         var bill = {totalPrice:total, emission_date:date, nif:code, client:clientId, file_name:file_name};
        //         var headers = state.queryAuthHeaders();
        //         axios.post(baseEndpoint + '/bills/', bill, headers)
        //         .then((data) => {
        //             cogoToast.success('Ficheiro Importado! É necessário atualizar a página.');
        //         })
        //         .catch((error) => {
        //             cogoToast.error('Erro ao importar ficheiro');
        //         })
        //     })
        //     .catch(err=> console.log(err));
      
        }
        reader.readAsArrayBuffer(file);

        

        /*
        setState(state => ({ ...state, isLoading: true }))

        axios.post('http://localhost:5000/ceme-entities', currentFile)
        .then((data) => {
            setError(false)
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success('Ficheiro Importado!');
        })
        .catch((error) => {
            cogoToast.error('Erro ao importar ficheiro');
        })*/
    }
    
    function openAddPartner() {
        history.push('/comercial/create')
    }

    function openAddUser() {
        history.push('/admin/crud_users/create')
    }

    function openAddOPC() {
        history.push('/admin/opc_entities/create_opc')
    }

    function openAddCEME() {
        history.push('/admin/ceme_entities/create_ceme')
    }


    function clickAction(item) {
        if(onClickers && onClickers[item]) {
            onClickers[item]()
            return
        } 
        switch (item) {
            case "Novo Alerta":
                changeTitle("Novo Alerta")
                setModalVisible(true)
                break;
            case "Novo User":
                openAddUser()
                break;
            case "Exportar PDF":
                exportPDF()
                break;
            case "Importar PDF":
                fileRef.current.click()
                break;
            break;
            case "Inserir Fatura":
                fileRef.current.click()
                break;
            case "Inserir Entidades CEME":
                fileRef.current.click()
                break;
            case "Inserir Entidades OPC":
                fileRef.current.click()
                break;
            case "Adicionar Parceiro":
                openAddPartner()
                break;
            case "Adicionar Entidade OPC":
                openAddOPC()
                break;
            case "Adicionar Entidade CEME":
                openAddCEME()
                break;
        }
    }

    return (
        <>
            <div id="top-buttons" className="top-btns">
                {
                    buttons.map(item => (
                        <span>
                        {
                            item == "Importar PDF" ? 
                            <label for="upload-file">
                                <Button className="button-item" onClick={(e) => e.currentTarget.parentElement.click() /* workaround to click parent label*/}>{item}</Button>
                                <input accept="application/pdf" style={{ display: "none" }} onChange={fileSelectedHandler} id="upload-file" ref={fileRef} type="file"></input>
                            </label>
                            :
                            <Button key={item} className="button-item" onClick={() => clickAction(item)} >{item}</Button>
                        }
                        
                        </span>
                    ))
                }
            </div>
        </>
    )
}
export default TopButtons