import React, {useContext, useState} from "react";
import {useQuery} from "react-query";
import {baseEndpoint, formatCellNumber, UnitSymbols,} from "../../utils/utils";
import Table from "../../components/table/table";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {AppContext} from "../../context/AppContext";
import Moment from "moment";
import MyDatePicker from "../../components/datepicker/datepicker";
import Chart from "../../components/charts/chart";
import {eachMonthOfInterval} from "date-fns";
import {pt} from "date-fns/locale";

const ChargersPage = () => {
    function onlyUniqueNames(data) {
        return [...new Set(data.data.pcs.map((x) => x.name))].map((x) => {
            return {name: x};
        });
    }

    let sumFunc = (a, b) => a + b;

    function separateSomeVariables(usages) {
        let usageIds = [...new Set(usages.map((x) => x.idSubUsage))];
        let uniques = usageIds.map((idSubUsage) => {
            return usages.filter((x) => x.idSubUsage === idSubUsage).pop();
        })
        return {
            uniques,
            energia_total_transacao_sum: (uniques.length && uniques.map((u) => u.energia_total_periodo).reduce(sumFunc)) || 0,
            totalDuration_sum: (uniques.length && uniques.map((u) => u.periodDuration).reduce(sumFunc)) || 0,
            totalValue: (uniques.length && uniques.map((u) => u.preco_opc).reduce(sumFunc)) || 0
        }
    }

    let history = useHistory();
    const [state, setState] = useContext(AppContext);
    let clientId = state.currentClient.id;

    const [selectedFilterDateFrom, setSelectedFilterDateFrom] = useState(Moment().subtract(2,'months').startOf("month"));
    const [selectedFilterDateTo, setSelectedFilterDateTo] = useState(Moment().endOf("month"));
    let setFilterDateFrom = (dateString) => {
        let date = Moment(dateString).startOf("month");
        if (date <= selectedFilterDateTo)
            setSelectedFilterDateFrom(date);
    };

    let setFilterDateTo = (dateString) => {
        let date = Moment(dateString).endOf("month");
        if (date >= selectedFilterDateFrom)
            setSelectedFilterDateTo(date);
    };

    let allMonthsInterval = eachMonthOfInterval({
        start: new Date(selectedFilterDateFrom.format("YYYY-MM-DD")),
        end: new Date(selectedFilterDateTo.format("YYYY-MM-DD")),
    });

    function filterBySelectedDate(array) {
        return array.filter((x) => Moment(x.startTimestamp, "YYYYMMDDHHmmss").isAfter(selectedFilterDateFrom) && Moment(x.idDay, "YYYYMMDDHHmmss").isBefore(selectedFilterDateTo));
    }

    let user = state.currentUser;
    let paramName = user.partners.length ? "partnerId=" : "clientId=";
    let paramValue = user.partners.length ? user.partners[0].id : clientId;
    let cacheparams = {};
    cacheparams[paramName] = paramValue;

    // Get charger
    const {loading, error, data} = useQuery(["pc", cacheparams], () =>
        axios(baseEndpoint + "/pcopcquery?" + paramName + paramValue, state.queryAuthHeaders())
    );
    let chargerUsages = data ? data.data.pcs : [];
    let chartData = {chargers: filterBySelectedDate(chargerUsages), months: allMonthsInterval};
    /*let columns = [
        {title: "Nome", field: "name", headerFilter: true},
        {
            title: "Energia" + UnitSymbols.energy,
            field: "energia_total_transacao_sum",
            headerFilter: true,
            formatter: formatCellNumber,
        },
        {
            title: "Duração" + UnitSymbols.duration,
            field: "totalDuration_sum",
            headerFilter: true,
            formatter: formatCellNumber,
        },
        {
            title: "Valor" + UnitSymbols.value,
            field: "totalValue",
            headerFilter: true,
            formatter: formatCellNumber,
        },
    ];*/
    let columnsTableByMonths = [];
    let dataByMonth = [];
    // vai carregadores pelo set de nomes idChargingStation
    let chargersNames = [...new Set(chargerUsages.map((x) => x.idChargingStation))];
   /* let chargers = chargersNames.map((name) => {
        //usages da boca
        let usages = chargerUsages.filter((x) => x.idChargingStation === name);
        usages = filterBySelectedDate(usages)
        //ids unicos de usage
        let {uniques, energia_total_transacao_sum, totalDuration_sum, totalValue} = separateSomeVariables(usages);
        return {
            name,
            totalValue,
            energia_total_transacao_sum,
            totalDuration_sum,
            usages: uniques,
        };
    });*/

    function dataToExport(year, month) {
        chargersNames.map((name) => {
            //usages da boca
            let usagesExport = chargerUsages
                .filter((x) => x.idChargingStation === name)
                .filter((x) => Moment(x.startTimestamp, "YYYYMMDDHHmmss").month() >= month - 1 && //startdate
                    Moment(x.idDay, "YYYYMMDDHHmmss").month() < month && //enddate
                    Moment(x.idDay, "YYYYMMDDHHmmss").year() === year
                );

            let {
                uniques,
                energia_total_transacao_sum,
                totalDuration_sum,
                totalValue
            } = separateSomeVariables(usagesExport);


            let index = dataByMonth.findIndex((x) => x.name === name);
            if (index === -1) {
                dataByMonth.push({
                    name: name,
                    ["energia_total_transacao_sum_" + month]: energia_total_transacao_sum,
                    ["totalDuration_sum_" + month]: totalDuration_sum,
                    ["totalValue_" + month]: totalValue,
                });
            } else {
                dataByMonth[index]["energia_total_transacao_sum_" + month] = energia_total_transacao_sum;
                dataByMonth[index]["totalDuration_sum_" + month] = totalDuration_sum;
                dataByMonth[index]["totalValue_" + month] = totalValue;
            }
        });
    }


    /* make columns and rows */
    columnsTableByMonths.push({columns: []})
    columnsTableByMonths[0].columns.push({title: "Nome", field: "name"});

    for (let i = 0; i < allMonthsInterval.length; i++) {
        let month = allMonthsInterval[i].getMonth() + 1;
        dataToExport(allMonthsInterval[i].getFullYear(), month);
        columnsTableByMonths[0].columns.push({
            title: allMonthsInterval[i].toLocaleString("pt", {month: "long"}),
            columns: [
                {
                    title: "Energia" + UnitSymbols.energy,
                    field: "energia_total_transacao_sum_" + month,
                    headerFilter: true,
                    formatter: formatCellNumber,
                },
                {
                    title: "Duração" + UnitSymbols.duration,
                    field: "totalDuration_sum_" + month,
                    headerFilter: true,
                    formatter: formatCellNumber,
                },
                {
                    title: "Valor" + UnitSymbols.value,
                    field: "totalValue_" + month,
                    headerFilter: true,
                    formatter: formatCellNumber,
                },
            ],
        });
    }


    function openCharger(charger) {
        history.push("/chargers/detail/" + charger.name, charger);
    }


    return (
        <>
            <div style={{width: "250px", paddingBottom: "25px"}}>
                <MyDatePicker
                    name="searchdatefrom"
                    label="Desde início de"
                    change={setFilterDateFrom}
                    isTo={false}
                    compareDate={selectedFilterDateTo}
                    defaultValue={new Moment().subtract(2,'months').startOf("month")}
                />
                <MyDatePicker
                    name="searchdateto"
                    label="Até final de"
                    change={setFilterDateTo}
                    isTo={true}
                    compareDate={selectedFilterDateFrom}
                    defaultValue={new Moment().endOf("month")} /* defaultValue={} change={changeSearch}*/
                />
            </div>
            <div>
                <Chart
                    dataQuery={chartData}
                    isLoading={!data}
                    showDropdownDate={false}
                    entityType="charger"
                    title="Valor Carregador/mês "
                    type="line"
                />
            </div>
            <div>
                <Table
                    itemsPerPage={50}
                    isLoading={!data}
                    hasSecondaryHeader = {true}
                    columns={columnsTableByMonths}
                    boxtitle="Carregadores/mês"
                    mode="allEnabled"
                    data={dataByMonth}
                    itemClicker={openCharger}
                />
            </div>
         {/*  <div>
                <Table
                    itemsPerPage={25}
                    isLoading={!data}
                    columns={columns}
                    boxtitle="Carregadores"
                    mode="allEnabled"
                    data={chargers}
                    itemClicker={openCharger}
                />
            </div> */} 
        </>
    );
};

export default ChargersPage;
