import gql from "graphql-tag";


const CEME_ENTITIES_QUERY = (clientId) => gql`
query cemeEntities {
  cemeEntities
  (where: 
    { client: 
        {
          id: ${clientId}
        }
    }
  ){
      code
      name
      email
      address
      contactName
      nif
      place
      country
      postalCodeString
      phoneNumber
  }
}
`;

export default CEME_ENTITIES_QUERY;