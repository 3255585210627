/**
 * Layout component that query some menu holders
 */
import React, {useState, useContext, useEffect} from "react"
import Layout from "../components/layout"
import Indicator from "../components/entityindicators/indicator"
import TopButtons from "../components/topBtn/top-btn"
import CustomModal from "../components/modal/modal"
import Table from "../components/table/table"
import Chart from "../components/charts/chart"
import { AppContext } from "../context/AppContext";
import CEMES_QUERY from "../queries/cemes";
import axios from 'axios'
import { useQuery } from 'react-query'
import { baseEndpoint, convertDayString, parseFloatNew } from "../utils/utils";
import Moment from "moment";

let columns = [
  { title: "Dia Referencia", field: "dia_referencia", headerFilter:true , sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
    let dateA = new Date(a.substring(6,10), a.substring(3,5) - 1, a.substring(0,2));
    let dateB = new Date(b.substring(6,10), b.substring(3,5) - 1, b.substring(0,2));
    
    return dateA - dateB
  }},
  { title: "ficheiro", field: "file_name", headerFilter:true, isActive : true},
  //,
  { title: "opc_id", field: "opc_id" , headerFilter:true, isActive : true},
  // { title: "opcentityname", field: "opcentityname" , headerFilter:true},
  { title: "ceme_id", field: "ceme_id" , headerFilter:true, isActive : true},
  // { title: "cemeentityname", field: "cemeentityname" , headerFilter:true},
  { title: "energia_total", field: "energia_total", isActive : true },
  { title: "duracao_total", field: "duracao_total", isActive : true },
  { title: "numero_carregamentos", field: "numero_carregamentos", isActive : true },
  { title: "preco_opc_total", field: "preco_opc_total", isActive : true },
];

let columnsMes = [
  { title: "Mes_referencia", field: "mes_referencia"},

  { title: "ficheiro", field: "file_name", headerFilter:true, isActive : true},
  //,
  { title: "opc_id", field: "opc_id" , headerFilter:true, isActive : true},
  // { title: "opcentityname", field: "opcentityname" , headerFilter:true},
  { title: "ceme_id", field: "ceme_id" , headerFilter:true, isActive : true},
  // { title: "cemeentityname", field: "cemeentityname" , headerFilter:true},
  { title: "energia_total", field: "energia_total", isActive : true },
  { title: "duracao_total", field: "duracao_total", isActive : true },
  { title: "numero_carregamentos", field: "numero_carregamentos", isActive : true },
  { title: "preco_opc_total", field: "preco_opc_total", isActive : true },
];

const CemePage = () => {
  const [state, setState] = useContext(AppContext);
  let clientId = state.currentClient.id
  const { isLoading, error, data } = useQuery(['cemes', {clientId: clientId}], () =>
  axios(baseEndpoint + '/cemequery?clientId='+clientId, state.queryAuthHeaders()))

var dataQuery = data ? {cemes: data.data.map(ceme => {
  // Decimal point
  ceme.energia_total = parseFloatNew(ceme.energia_total)
  ceme.duracao_total = parseFloatNew(ceme.duracao_total)
  ceme.preco_opc_total = parseFloatNew(ceme.preco_opc_total)
  if(ceme.dia_referencia && ceme.dia_referencia != "Invalid Date") {
    //console.log(ceme.dia_referencia)
    ceme.dia_referencia = convertDayString(ceme.dia_referencia);
  } else {
    ceme.dia_referencia = ""
  }
  
  //console.log(ceme.dia_referencia)
  return ceme
}) } : {cemes: []};
  return(  
    

    <span>
      <TopButtons buttons = {['Exportar PDF']}/>
      {/* <div className="indicatorParentHolder">
        <Indicator dataQuery={dataQuery} isLoading={isLoading} type={"ceme"} title = {"Carregamentos"}/>
        <Indicator dataQuery={dataQuery} isLoading={isLoading} type={"ceme"} title = {"Energia"}/>
        <Indicator dataQuery={dataQuery} isLoading={isLoading} type={"ceme"} title = {"Duração"}/>
        <Indicator dataQuery={dataQuery} isLoading={isLoading} type={"ceme"} title = {"Preço Total"}/>
      </div> */}
      
      <Table
        columns={columns}
        data={ dataQuery ? dataQuery.cemes.filter(x=>!x.mes_referencia) : [] }
        isLoading={isLoading}
        boxtitle="CEME Dia"
        mode="allEnabled"
      ></Table>

      <Table
        columns={columnsMes}
        data={ dataQuery ? dataQuery.cemes.filter(x=>x.mes_referencia) : [] }
        isLoading={isLoading}
        boxtitle="CEME Mês"
        mode="allEnabled"
      ></Table>
{/* 
      <span className="chartParentHolder">
        <Chart dataQuery={dataQuery} isLoading={isLoading} entityType="ceme" title="Preço Total" type="bar"/>
        <Chart dataQuery={dataQuery} isLoading={isLoading} entityType="ceme" title="Duração" type="bar"/>
      </span>
      
      <Chart dataQuery={dataQuery} isLoading={isLoading} entityType="ceme" title="Carregamentos" type="bar"/>
      <Chart dataQuery={dataQuery} isLoading={isLoading} entityType="ceme" title="Energia" type="line"/> */}
    </span>
)}

export default CemePage
