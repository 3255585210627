import React from 'react'
import MagnifyingGlass from '../../static/magnifying-glass.png'
import TableArrowUp from '../../static/table_arrow_up.png'
import TableArrowDown from '../../static/table_arrow_down.png'

const TableIconsStyle = () =>
(<style>
    {
        `
        .tabulator-header-filter::before {
            content: url(`+MagnifyingGlass+`);
          }

        .tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=asc] .tabulator-col-content .tabulator-col-sorter{
            background-image: url(`+TableArrowUp+`);
        }
        
        .tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=desc] .tabulator-col-content .tabulator-col-sorter{
            background-image: url(`+TableArrowDown+`);
        }
        

          `
    }
</style>)

export default TableIconsStyle