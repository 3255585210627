import React, {useState, useContext} from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton, Typography, Avatar, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import cogoToast from 'cogo-toast';
import { AppContext, checkIfUnauthorized } from "../../context/AppContext";
import { PARTNERS_QUERY } from "../../queries/partners";
import { useLazyQuery } from "@apollo/client";
import "./create.css";
import { useHistory } from "react-router-dom";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { baseEndpoint, urlToFile } from "../../utils/utils"
import { createPartner } from '../../utils/requests/createPartner'
import { hasLength, hasValue, isValidNIF ,isValidName , isValidAddress} from "../../utils/validator"
import Constants from '../../constants';

const axios = require('axios').default;

function CreatePartner() {
    let history = useHistory()
    let query = PARTNERS_QUERY()
    const [getPartners, { loading, data, error }] = useLazyQuery(query);
    const [state, setState] = useContext(AppContext);
    const [showingDetails, setShowingDetails] = useState(false);
    const [hasEntity, setHasEntity] = useState(false);
    const [isWaitingLoading, setIsWaiting] = useState(false);
    const [alreadyPartner, setAlreadyPartner] = useState(false);
    const [nif, setNIF] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [nifError, setNIFError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [partnerImg, setPartnerImg] = useState(null);
    const [partnerImgError, setPartnerImgError] = useState(false);
    const [fileObj, setFileObj] = useState(null);
    const [partnerImgPath, setPartnerImgPath] = useState(null);
    const [partnerType, setPartnerType] = useState(Constants.partnerTypeIncome)
    let alreadyExistingPartner = null;

    function addPartner() {
        console.log("AQUI")
        setNameError(false)
        setAddressError(false)

        let hasError = false

        // Check name
        if(!hasLength(name) || !isValidName(name)) {
            setNameError(true)
            hasError = true
            console.log("AQUI2")
        } else {
            setAddressError(false)
        }

        // Check address
        if(!hasLength(address) || !isValidAddress(address)) {
            setAddressError(true)
            hasError = true
            console.log("AQUI3")
        } else {
            setAddressError(false)
        }

        // Check image
        console.log(partnerImg)
        console.log(fileObj)
        
        /*
        if(!partnerImgPath || (!hasValue(partnerImg)|| !fileObj) && partnerImgPath) {
            setPartnerImgError(true)
            hasError = true
            console.log(alreadyExistingPartner)
            console.log("AQUI4")
            console.log(partnerImgPath)
        } else {
            setPartnerImgError(false)
        }
        */
        if(hasError) {
            console.log("has error")
            return
        }

        let clientObject = alreadyExistingPartner ? alreadyExistingPartner : {}
        const formData = new FormData();
        
        
        /*
        if(!alreadyExistingPartner) {
            console.log("AQUI")
            // We prepare the client object 
            
        } else {
            let data = {}
            if(clientObject.clients == null) {
                clientObject.clients = []
            }
            data.clients = []
            data.clients.push(state.currentClient)
        }
        */
        console.log("YEAHH")
        let data = {}
        data.name = name
        data.address = address
        data.nif = nif
        data.clients = []
        data.clients.push(state.currentClient)
        data.users = []
        data.partnershipType = partnerType
        if(partnerImgPath) {
            data.logo = partnerImgPath
        }
        clientObject.data = data
        formData.append('data', JSON.stringify(data))
        if(partnerImg && fileObj) {
            formData.append('files.logo',urlToFile(partnerImg, fileObj.name, fileObj.type), fileObj.name)
        }
        

        setState(state => ({ ...state, isLoading: true }))
        createPartner(formData, state, (success) => {
            goBack(history)
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success('Parceiro adicionado com sucesso');
        }, (error) => {
            setState(state => ({ ...state, isLoading: false }))
            let wasUnauthorized = false
            if (error && error.response) {
              wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
            }
            if (!wasUnauthorized) {
              cogoToast.error('Erro ao adicionar parceiro');
            }

        })
    }

    var fileSelectedHandler = (event) => {
        let file = event.target.files[0]
        setFileObj({
            name: file.name,
            type: file.type
        })  
        // Check if the file is an image.
        if (file.type && file.type.indexOf('image') === -1) {
            cogoToast.error("Formato inválido!")
            return;
        }

        
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            setPartnerImg(event.target.result)
        });
        reader.readAsDataURL(file);
    }

    function goBack() {
        history.goBack()
    }

    

    function checkEntity() {
        alreadyExistingPartner = null;
        
        // Check for nif
        let partners = data.partners.filter(x => { return x.nif == nif })
        alreadyExistingPartner  = partners.length > 0 ? partners[0] : null
        if(alreadyExistingPartner) {
            setName(alreadyExistingPartner.name)
            setAddress(alreadyExistingPartner.address)
            console.log(alreadyExistingPartner)
            if(alreadyExistingPartner.logo) {
                //setPartnerImg(baseEndpoint+alreadyExistingPartner.logo.url)
            }
            
            // Check if already is client partner
            setAlreadyPartner(alreadyExistingPartner.clients.filter(x => x.id == state.currentClient.id).length > 0)

            //setPartnerImgPath(alreadyExistingPartner.logo)
        } else {

        }
        console.log(alreadyExistingPartner)
        setState(state => ({ ...state, isLoading: false }))
        setIsWaiting(false)
        setShowingDetails(true)
        setHasEntity(partners.length > 0)
    }

    function searchEntity() {
        
        // Validate nif
        if(!isValidNIF(nif)) {
            setNIFError("NIF inválido")
            return
        }

        setNameError(false)
        setAddressError(false)
        setName('')
        setAddress('')
        setShowingDetails(false)
        setHasEntity(false)
        setPartnerImg(null)
        setFileObj(null)
        setAlreadyPartner(false)
        if(!data) {
            setState(state => ({ ...state, isLoading: true }))
            setIsWaiting(true)
            getPartners()
            return
        }
        checkEntity()
    }

    if(isWaitingLoading && data) {
        checkEntity();
    }

    // Text state and helpers
    var initNIF = ((nif => {
        setNIF(nif)
        setNIFError(false)
    }))
    var initAddress = ((pass) => {
        setAddress(pass)
        setAddressError(false)
    })
    var initName = ((pass) => {
        setName(pass)
        setNameError(false)
    })
    
    const handleTypeChange = (event) => {
        setPartnerType(event.target.value);
      };

    return (
        <span>
            <h2>Insira número de contribuinte</h2>
            <span className="firstLine">
                <TextField
                    className="input"
                    id="partnerNIF"
                    label="NIF"
                    variant="outlined"
                    value={nif}
                    onInput={e => initNIF(e.target.value)}
                    error={nifError}
                    helperText={nifError ? nifError : null}
                />
                <Button className="confirmBtn" onClick={searchEntity} color="primary">Confirmar</Button>
            </span>
            
            {
                showingDetails ?
                <>
                    { hasEntity ? 
                        <>
                            {
                            alreadyPartner ? 
                            <h2 className="subtitle">Parceiro já adicionado</h2>
                            :
                            <h2 className="subtitle">Entidade já existente</h2>
                        }
                        </>
                        :
                        <h2 className="subtitle">Adicione os detalhes da entidade</h2>
                    }

                    { /* Name */ }
                    <TextField
                        className="input"
                        id="partnerName"
                        label="Nome"
                        variant="outlined"
                        value={name}
                        onInput={e =>  alreadyPartner ? null : initName(e.target.value)}
                        error={nameError}
                        helperText={nameError ? nameError : null}/>

                    { /* Endereço */ }
                    <TextField
                        className="input subtitle"
                        id="partner"
                        label="Endereço"
                        variant="outlined"
                        value={address}
                        onInput={e =>  alreadyPartner ? null : initAddress(e.target.value)}
                        error={addressError}
                        helperText={addressError ? addressError : null}/>

                    { /* Tipo de parceria */ }
                    <FormControl variant="outlined" className="input subtitle">
                        <InputLabel id="type-partner-label">Tipo de Parceria</InputLabel>
                        <Select
                            labelId="type-partner-label"
                            id="type-partner"
                            value={partnerType}
                            onChange={handleTypeChange}
                            label="Tipo de Parceria">
                            <MenuItem value={Constants.partnerTypeIncome}>{Constants.partnerTypeIncomeTitle}</MenuItem>
                            <MenuItem value={Constants.partnerTypeUsage}>{Constants.partnerTypeUsageTitle}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Partner image  */}
                    {
                        <span className="flexSpan2">
                            {
                                partnerImg == null ?
                                <span className="flexSpan">
                                    <div className="divPhotoPartner">
                                        <Avatar fontSize="large" className="photoPartner" />
                                    </div>
                                    {
                                        partnerImgError ? <span className="noPhotoPartner">Insira logo do parceiro</span> : <></>
                                    }
                                </span>
                                :
                                <img className="photoPartner" src={partnerImg}></img>
                            }
                            {
                                !alreadyPartner ? 
                                <>
                                    <input accept="image/*" style={{ display: "none" }} onChange={fileSelectedHandler} id="upload-photo" type="file"></input>
                                    <label className="upload-img" htmlFor="upload-photo">
                                        <IconButton className="camera-icon-partner" aria-label="upload picture" component="span">
                                            <PhotoCamera/>
                                        </IconButton>
                                    </label>
                                </>
                                :
                                <></>
                            }
                            
                        </span>
                        
                        
                    }

                    <span className="buttonDiv">
                        <Button onClick={goBack} color="primary">Voltar</Button>
                        {
                            alreadyPartner ? <></> :
                            <Button onClick={addPartner} color="primary">Adicionar parceiro</Button>
                        }
                    </span>
                </>
                
                :
                <></>
            }
        </span>
    )
}

export default CreatePartner