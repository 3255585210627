import React, { useContext, useState } from "react"
import Layout from "../../components/layout"
import TopButtons from "../../components/topBtn/top-btn"
import CustomModal from "../../components/modal/modal"
import Table from "../../components/table/table"
import USERS_QUERY from "../../queries/crud_users";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext"

const CRUDUsers = () => {
  var dateFormatter = function (cell) {
    let date = new Date(cell.getValue()).toLocaleString("pt-PT");
    return date;
  }

  let columns = [
    { title: "Nome do Utilizador", field: "username", headerFilter: true, isActive: true },
    { title: "Data de criação", field: "created_at", headerFilter: true, isActive: true, formatter: dateFormatter },
    { title: "Ativo", field: "confirmed", align: "center", formatter: "tickCross", resizable: false, isActive: true }
  ];

  const [state, setState] = useContext(AppContext);
  let clientId = state.currentClient.id
  let query = USERS_QUERY(clientId)
  const { data, loading, error } = useQuery(query);
  
  return (

        <span>
          {
            /* <TopButtons buttons={['Exportar PDF', 'Novo User']} />*/
          }
          <TopButtons buttons={['Exportar PDF', 'Novo User']} />
          <Table
          isLoading={loading}
          mode="allEnabled"
          boxtitle="Lista de Utilizadores"
          columns={columns}
          data={data ? data.users.map(node => {
            return JSON.parse(JSON.stringify(node))
          }) : []}>
        </Table>
        </span>
  
  )
}

export default CRUDUsers