import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;

export function createPartner(formData, state, success, failure){

    // We are going to update the partner
    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
        headers: {
            Authorization: currentToken,
            'content-type': 'multipart/form-data'
        }
    }

    console.log(formData.get("data"))
    axios.post(baseEndpoint + endpoints.CREATE_PARTNER_ENDPOINT.value, formData, config)
        .then((data) => {
            success()
        })
        .catch((error) => {
            failure()
        })
}