import React, {useState, useContext, useEffect} from "react"
import { useQuery } from "@apollo/client";
import axios from 'axios'
import { baseEndpoint } from "../../utils/utils";
import { AppContext, checkIfUnauthorized } from "../../context/AppContext";
import Table from "../../components/table/table";
import { addPCPartner } from '../../utils/requests/addPcPartner'
import { useHistory } from "react-router-dom";
import CHARGERS_QUERY from '../../queries/opcchargers'
import cogoToast from 'cogo-toast';
import { Button } from "@material-ui/core";
import "./addpc.css";


function PartnerPcs({location}) {
    let history = useHistory()
    const [state, setState] = useContext(AppContext);
    let clientId = state.currentClient.id
    const { data, loading, error } = useQuery(CHARGERS_QUERY(clientId));
    var partner = location.state.partner
    var dataQuery = data ? JSON.parse(JSON.stringify(data.pcs)).filter(pc => {
      return !pc.partner
    }).map(pc => {
      pc.isSelected = false
      return pc
    }) : [];
    console.log(data)

    var selectedList = {}
    useEffect(() => {
      window.updateCheckbox = (e) => {
        dataQuery.forEach(element => {
          if(element.IdEVSE == e) {
            if(selectedList[element.IdEVSE]) {
              console.log("selectedList[element.IdEVSE]")
              console.log(selectedList[element.IdEVSE])
              selectedList[element.idEVSE] = null;
            } else {
              selectedList[element.IdEVSE] = element
            }
          }
        })
      }
  
    });

    let columns = [
      { title: "Nome", field: "name", headerFilter:true },
      { title: "Boca", field: "IdEVSE", headerFilter:true },
      { title: "   Adicionar", field: "isSelected",align: 'center', formatter: (cell) => {
          const value = cell.getRow().getData().IdEVSE
          return `
                  <input type="checkbox" onclick="updateCheckbox('`+value+`');"/>
              `;
        } 
      },
    ]

    function goBack() {
      history.goBack()
    }

    function updatePartner() {
      console.log(selectedList)
      let arrayTemp = Object.values(selectedList)
      arrayTemp.forEach(item => {
        delete item.isSelected
      })

      arrayTemp.map(item => {
        return item.id
      })
      partner.pcs.forEach(pc => {
        console.log(pc)
        arrayTemp.push(pc.id)
      })

      console.log(arrayTemp)

      setState(state => ({ ...state}))

      addPCPartner(partner, { "pcs": arrayTemp }, state, (success) => {
          goBack(history)
          cogoToast.success('Carregadores adicionados com sucesso');
      }, (error) => {
          let wasUnauthorized = false
          if (error && error.response) {
            wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
          }
          if (!wasUnauthorized) {
            cogoToast.error('Erro ao adicionar carregadores');
          }
      })
        
    }

    return (
        <span>
          { /* PCS Table */ }
          <Table
            isLoading={loading}
            columns={columns}
            data={dataQuery}
            boxtitle="Carregadores">
          </Table>
  
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <Button className="cancelAddBtn" onClick={goBack} color="primary">Voltar</Button>
            <Button className="addPCPageBtn" onClick={updatePartner} color="primary">Adicionar Carregadores</Button>
          </span>
        </span>
    )
}

export default PartnerPcs