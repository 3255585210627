import gql from "graphql-tag";

export const PARTNER_ID = (partnerId) => gql`
query partnerspcs {
    partners
    (where:{
        id: ${partnerId}
        }
    )
    {
        name
        nif
        address
        id
        logo {
            url
        }
        date_percentages(where: {partner:${partnerId}}) {
        percentage
            date
            pc {
                id
                IdEVSE
            }
        }
        partnershipType
        users {
            id
            username
            email
            profileImg {
                url
            }
            phoneNumber
            userID
            firstName
            lastName
            department
        }
        clients {
            id
            name
            logo {
                url
            }
        }
        pcs (where :  {date_percentages: {partner: ${partnerId}}}){
            name
            IdEVSE
            id
            date_percentages {
                percentage
                date
            }
        }
    }   
}
`;

export const PARTNERS_QUERY = () => gql`
query partners {
    partners {
        address
        nif
        name
        logo {
            url
        }
        clients {
            id
        }
    }
}
`;

export const PARTNERS_PCS_QUERY = (partnerId) => gql`
query partnerspcs {
    partners
    (where: 
        id: ${partnerId}
    )
    {
        pcs {
            name
            IdEVSE
            id
        }
    }
}
`;

export const PARTNERS_COMERCIAL_QUERY = (clientId) => gql`
query partnersComercial {
    partners
    (where: 
        { clients: 
            {
              id: ${clientId}
            }
        }
      )
    {
        name
        nif
        address
        id
        logo {
            url
        }
        date_percentages {
            percentage
            date
        }
        partnershipType
        users {
            id
            username
            email
            profileImg {
                url
            }
            phoneNumber
            userID
            firstName
            lastName
            department
        }
        clients {
            id
            name
            logo {
                url
            }
        }
        pcs {
            name
            IdEVSE
            id
        }
    }
}
`;

