import gql from "graphql-tag";

const USERS_QUERY = (clientId) => gql`
query clientsQuery {
  users
  (where: 
    { clients: 
        {
          id: ${clientId}
        }
    }
  ){
      email
      username
      created_at
      confirmed
  }
}
`;

export default USERS_QUERY;