

  export const endpoints = {
    PARTNER_ENDPOINT: {
      value: '/partners/',
    },
    CREATE_PARTNER_ENDPOINT: {
      value: '/partners',  
    },
    CREATE_USER_ENDPOINT: {
      value: '/auth/local/register',   
    },
    CREATE_CEME : {
      value: '/ceme-entities',
    },
    DATE_PRICE : {
      value: '/opc-date-prices',
    },
    DATE_PERCENTAGE: {
      value: '/date-percentages',
    },
    CREATE_OPC : {
      value: '/opc-entities',
    },
    UPDATE_OPC : {
      value: '/opc-entities/',
    },
    REGISTER_ENDPOINT: {
      value: '/auth/local/register',
    },
    FORGOT_ENDPOINT: {
        value: '/forgot',
    },
    RESET_PASS_ENDPOINT: {
        value: '/auth/reset-password',
    },
    TOKEN_ENDPOINT: {
        value: '/auth/local',
    },
    DATASYNC_ENDPOINT: {
        value:'/datasync',
    }
  }