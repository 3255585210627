import { baseEndpoint } from '../utils';
import { endpoints } from '../endpoints';
const axios = require('axios').default;


export function createDatePercentage(data, state, success, failure) {
    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
        headers: {
            Authorization: currentToken
        }
    }
    
    axios.post(baseEndpoint + endpoints.DATE_PERCENTAGE.value, data, config)
    .then((data) => {
        success(data)
    }).catch((error) => {
        failure()
    })
}