const axios = require('axios').default;
export function insertImage(imageFile, success, failure){
    axios.put('', {
        image: imageFile,
    })
        .then((data) => {
            success()
        })
        .catch((error) => {
            failure()
        })
}