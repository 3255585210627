import React, { useContext } from "react"
import Layout from "../components/layout"
import TopButtons from "../components/topBtn/top-btn"
import Table from "../components/table/table"

import { baseEndpoint } from "../utils/utils"
import { Switch, withStyles } from "@material-ui/core"
import DetailHeader from "../components/detail/detailHeader"
import { AppContext } from "../context/AppContext"
import PROFILE_QUERY  from "../queries/profile" 
import { useQuery } from "@apollo/client"


Date.prototype.printDateWithHours = function () {
  var testMonth = this.getMonth();

  if (testMonth < 10) {
    testMonth = '0' + testMonth;
  }

  var testDay = this.getDate();
  if (testDay < 10) {
    testDay = '0' + testDay;
  }

  var testHours = this.getHours();
  if (testHours < 10) {
    testHours = '0' + testHours;
  }
  return [this.getFullYear(), testMonth, testDay, testHours].join('-');
}

function getDateFromValue(value) {
  return new Date(value.slice(0, -10), // year
    value.slice(4, -8), // month
    value.slice(6, -6), // day
    value.slice(8, -4), // hours
    value.slice(10, -2), // minutes
    value.slice(12, 0) // seconds
  )
}

var dateFormatter = function (cell) {
  let date = new Date(cell.getValue()).toLocaleString("pt-PT");
  return date;
}

let columnData = [
  {
    title: "Título", field: "title", width: "20%", isActive: true
  },
  {
    title: "Descrição", field: "description", width: "60%", align: "left", isActive: true
  },
  {
    title: "Data de Envio", field: "Date", align: "left", width: "20%", isActive: true,formatter: dateFormatter
  }]


let clientMainItems = []
let clientItems = {}
function prepareHolders(client) {
  
  if (client.firstName != null && client.lastName != null) {
    let fullName = client.firstName + " " + client.lastName
    clientMainItems = [fullName]
  }
  clientItems["Email"] = client.email != null ? client.email : "N/D"
  clientItems["Phone"] = client.phoneNumber != null ? client.phoneNumber : "N/D"
  clientItems["UserID"] = client.userID != null ? client.userID : "N/D"
  clientItems["Departamento"] = client.department != null ? client.department : "N/D"
}

const Profile = () => {
  const [state, setState] = useContext(AppContext);
  let client = state.currentUser
  console.log(client)
  let profileImg = client.profileImg ? baseEndpoint+ client.profileImg.url : null
  const { data, loading, error } = useQuery(PROFILE_QUERY, {fetchPolicy: "no-cache"});
 
  prepareHolders(client)
  return (
    <>
        <div>
          <DetailHeader img={profileImg} mainItems={clientMainItems} items={clientItems} haveMargin={true}></DetailHeader>
          {/* <Table
            isLoading={loading}
            columns={columnData}
            boxtitle="Notificações"
            mode="allEnabled"
            data={data && data.notifications.length > 0  ? data.notifications.map( node => {
              return JSON.parse(JSON.stringify(node))
            }) : []}>
          </Table> */}
        </div>
    </>
  )
}

export default Profile