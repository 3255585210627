import React, { useState, useContext } from "react"
import { useQuery } from 'react-query'
import OPCSCHARGERS_QUERY from '../../queries/opcchargers'
import { baseEndpoint, convertDayString, parseFloatNew, topCalcFormatNumber, UnitSymbols } from "../../utils/utils";
import Table from "../../components/table/table"
import { AppContext } from "../../context/AppContext";
import axios from 'axios'
import { Card } from "@material-ui/core";

const ChargerDetails = ({match, location}) => {
  const [state, setState] = useContext(AppContext);
  let charger = location.state;

  let chargerid = charger.id || match.params.chargerid;

  
    // Get charger
    const { isLoading, error, data } = useQuery(['pc', {chargerId: chargerid}], () =>
    axios(baseEndpoint + '/pcopcquery?chargerId='+chargerid, state.queryAuthHeaders()))

    var chargerusages = data ? data.data.pcs : [];

    // vai buscar as bocas pelo set de nomes idEVSE
    var evsenames = [...new Set(chargerusages.map(x=> x.idEVSE))];

    var evses = evsenames.map(name => 
      {
        //usages da boca
        var usages = chargerusages.filter(x=> x.idEVSE == name);

        //ids unicos de usage
        var usageIds = [...new Set(usages.map(x=> x.idSubUsage))];

        // var uniques =  usageIds.map(id => {return usages.find(x=>x.idusage == id)});

        //para cada idUsage, ir buscar as desse id
        //fazer pop para obter o ultimo da lista
        var uniques = usageIds.map(idSubUsage => {
          var latest = usages.filter(x=> x.idSubUsage == idSubUsage).pop();
          return latest;
        })

        //tirados porque agora calculamos na tabela
        
        // var energia_total_transacao_sum = usages.map(u=> u.energia_total_transacao).reduce(sumFunc);
        // var totalDuration_sum = usages.map(u=> u.energia_total_transacao).reduce(sumFunc);
        
        return {name:name, usages: uniques, 
          //tirados porque agora calculamos na tabela
          //energia_total_transacao_sum: energia_total_transacao_sum, totalDuration_sum: totalDuration_sum
        };
      }
    )
    var allUsages = [];
    evses.forEach(x => allUsages = allUsages.concat(x.usages));

    var cemenames = [...new Set(chargerusages.map(x=> x.idServiceProvider))];
    return (
    <>

    <div>
          <Table
            groupBy={"idEVSE"}
            isLoading={!data}
            columns={getcolumns(cemenames)}
            boxtitle={charger.name}
            mode="allEnabled"
            data={allUsages}
            >
          </Table>
    </div>
    </>)
}

export default ChargerDetails;

//colunas comentadas podem só levar visible:false para poderem ser escolhidas
let getcolumns = (cemes) => [
  // {"title":"id","field":"id"},
  // {"title":"idEVSE","field":"idEVSE"},
  {"title":"ID Utilização","field":"idUsage", isActive: true},
  {"title":"Provedor","field":"idServiceProvider", headerFilter:"select", "headerFilterParams": cemes, isActive: true},
  // {"title":"Data Hora","field":"startTimestamp"},
  {"title":"Data","field":"stopTimestamp"},
  // {"title":"idExternalNumber","field":"idExternalNumber"},
  // {"title":"idInternalNumber","field":"idInternalNumber"},
  // {"title":"idNetworkOperator","field":"idNetworkOperator"},
  // {"title":"idChargingStation","field":"idChargingStation"},
  {"title":"Energia"+ UnitSymbols.energy,"field":"energia_total_transacao", topCalc:topCalcFormatNumber},
  {"title":"Duração"+ UnitSymbols.duration,"field":"totalDuration", topCalc:topCalcFormatNumber},
  {"title":"Valor"+ UnitSymbols.value,"field":"preco_opc", topCalc:topCalcFormatNumber},
  {"title":"Ficheiro","field":"file_name"},
  
]

const sumFunc = (accumulator, currentValue) => accumulator + currentValue;
function arrayMax(arr) {
  var len = arr.length, max = -Infinity;
  while (len--) {
    if (Number(arr[len]) > max) {
      max = Number(arr[len]);
    }
  }
  return max;
};