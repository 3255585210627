import React from "react"

import Layout from "../components/layout"
import { AppContext } from "../context/AppContext";
import logo from "../static/Logo.png"
import CemePage from "../pages/ceme";
import { Route } from "react-router-dom";
import "../pages/404.css";

const NotFoundPage = () => (
  <span >
    <div className="containerGrid">
      <div id="imgContainer">
        <img style={{ width: "200px", height: "200px" }} src={logo}></img>
      </div>
      <h2>Página não encontrada</h2>
      <div>
        <span style={{ textAlign: "center" }}>Lamentamos muito. O endereço da web que você especificou não é uma página ativa no nosso site.</span>
      </div>
    </div>
  </span>
)
export default NotFoundPage
