/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO() {

  let data = {
    siteMetadata : {
      description : "OPC Gest é um gestor de conteúdos de opcs",
      title : "OPC Gest",
      author: "GappIT"
    },
    lang: 'pt'
  }
  const metaDescription = data.siteMetadata.description
  const defaultTitle = data.siteMetadata?.title
  const meta = "meta"
  return (
    <Helmet>
      <script type="text/javascript" src="https://oss.sheetjs.com/sheetjs/xlsx.full.min.js"></script>
    </Helmet>    
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
