import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Moment from "moment";

export var UnitSymbols = {energy: " (kW)", duration: " (mins)", value: " (€)"}

export var baseEndpoint = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL || "http://localhost:5000"

export function urlToFile(dataurl, filename, mimeType) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mimeType});
}


export function exportPDFPage() {
    if (typeof window !== `undefined`) {
        var source = window.document.getElementsByClassName("main")[0].firstChild;
        html2canvas(source, {
            scale: 1.5
        }).then(canvas => {
            var image = canvas.toDataURL("image/png");
            var doc = new jsPDF('p', 'px', 'a4', true);
            var length = source.childNodes.length
            var width = doc.internal.pageSize.getWidth() - 80;
            var height = 0;
            if (length < 3) {
                height = length * 50;
            } else {
                height = doc.internal.pageSize.getHeight();
            }
            doc.addImage(image, 'PNG', 35, 10, width, height);
            doc.save();
        });
    }
}

/*
    Receive a string in '2011-04-11T10:20:30Z' and return as DD/MM/YYYY
*/
export function convertDayString(date) {
    return Moment(date).format("DD/MM/YYYY")
}

export function parseFloatNew(stringValue) {
    return stringValue.toString().replace('.', ',')
}

export function formatCellNumber(c) {
    return formatNumber(c.getValue())
}

export function formatNumber(numberValue, decimalpoints) {
    //separator comes in as '.'
    if (typeof (numberValue) == "number" && numberValue != undefined) {
        let separator = '.';
        var str = numberValue.toFixed(decimalpoints || 2)
        var result = str.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var split = result.split(separator);
        if (split.length == 2) {
            //separator comes back out as '.'
            result = split[0] + separator + split[1].replace(" ", "");
        }

        // result = result.replace(".",",");
        return result;
    }

}


export function topCalcFormatNumber(values, data, calcParams) {
    //values - array of column values
    //data - all table data
    //calcParams - params passed from the column definition object

    var result = values.reduce && values.reduce((a, b) => a + parseFloat(b.toString().replace(",", ".")), 0) || 0


    result = formatNumber(result);
    return result;
}

String.prototype.getHashCode = function () {
    var hash = 0;
    if (this.length == 0) return hash;
    for (var i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};


export function getDateFromUsage(usageDate) {
    return new Date(usageDate.slice(0, -10), // year
        usageDate.slice(4, -8), // month
        usageDate.slice(6, -6), // day
        usageDate.slice(8, -4), // hours
        usageDate.slice(10, -2), // minutes
        usageDate.slice(12, 0) // seconds
    )
}

// Hour related
export function getHours() {
    var today = new Date();
    let currentHour = today.getHours()
    let array = []
    var i;

    for (i = 24; i > 0; i--) {
        let hour = (currentHour + i) % 24
        var d = new Date(new Date().setDate(today.getHours() - i));
        array.push({date: d, toString: hour + "h", dateFormat: d.printDateWithHours()})
    }
    return array.reverse();
}

export function getWeekdays() {
    var result = [];
    var today = new Date();
    for (var i = 0; i < 7; i++) {
        var d = new Date(new Date().setDate(today.getDate() - i));
        let day = d.toLocaleString('default', {weekday: 'short'})
        let month = d.toLocaleString('default', {month: 'short'}).slice(0, -1)
        result.push({date: d, toString: day + " " + month, dateFormat: d.standardPrint()})
    }
    return result
}

export function getLastMonth() {
    var result = [];
    var today = new Date();
    for (var i = 0; i < 30; i++) {
        var d = new Date(new Date().setDate(today.getDate() - i));
        let day = d.getDate();
        let month = d.toLocaleString('default', {month: 'short'}).slice(0, -1)
        result.push({date: d, toString: day + " " + month, dateFormat: d.standardPrint()})
    }
    return result.reverse();
}

export function getLastYear() {
    var result = [];
    var today = new Date();
    for (var i = 0; i < 365; i++) {
        var d = new Date(new Date().setDate(today.getDate() - i));
        let day = d.getDate();
        let month = d.toLocaleString('default', {month: 'short'}).slice(0, -1)
        //result.push({date: d, toString: day + " " + month, dateFormat: d.standardPrint()})
        result.push({date: d, toString: day + " " + month, dateFormat: d.standardPrint()})
    }
    return result.reverse();
}

Number.prototype.intToHSL = function () {
    return "hsl(" + this % 345 + ",100%,40%)";
};

Date.prototype.standardPrint = function () {
    var testMonth = this.getMonth() + 1;

    if (testMonth < 10) {
        testMonth = '0' + testMonth;
    }

    var testDay = this.getDate();
    if (testDay < 10) {
        testDay = '0' + testDay;
    }
    return [this.getFullYear(), testMonth, testDay].join('-');
}

Date.prototype.printDateWithHours = function () {
    var testMonth = this.getMonth();

    if (testMonth < 10) {
        testMonth = '0' + testMonth;
    }

    var testDay = this.getDate();
    if (testDay < 10) {
        testDay = '0' + testDay;
    }

    var testHours = this.getHours();
    if (testHours < 10) {
        testHours = '0' + testHours;
    }
    return [this.getFullYear(), testMonth, testDay, testHours].join('-');
}


