import React, { useState } from "react"
import Layout from "../../components/layout"
import TopButtons from "../../components/topBtn/top-btn"
import CustomModal from "../../components/modal/modal"
import Table from "../../components/table/table"
import { blue } from "@material-ui/core/colors"
import { Switch, withStyles } from "@material-ui/core"
import ALERTS_QUERY from "../../queries/alerts";
import { useQuery } from "@apollo/client";
import { AppContext } from "../../context/AppContext"
import "../../css/alerts.css"




const AlertsPage = () => {
  const [isShowingModal, setModalShowing] = useState(false)
  var makeVisible = (isVisible) => {
    setModalShowing(isVisible)
  }

  const [actualTitle, setTitle] = useState("")
  var titulo = (title) => {
    setTitle(title)
  }

  let columnData = [
    {
      title: "Título", field: "title", width: "95%", resizable : false, isActive: true
    },
    {
      title: "Ativo", field: "isActive", align:"center", formatter:"tickCross", resizable : false, marginLeft: "55px", isActive: true
    }]
  
  let query = ALERTS_QUERY()
  const { data, loading, error } = useQuery(query);

  return (
  
        <div>
          {
            /* <TopButtons buttons={['Exportar PDF', 'Novo Alerta']} changeTitle={titulo} setModalVisible={makeVisible} />*/
          }
          <TopButtons buttons={['Exportar PDF']} changeTitle={titulo} setModalVisible={makeVisible} />
          <CustomModal title={actualTitle} showModal={isShowingModal} setModalShow={setModalShowing} />
          <Table
          isLoading={loading}
          boxtitle="Definições de Alertas"
          mode=""
          columns={columnData}
          data={data ? data.notificationSettings.map(node => {
            return JSON.parse(JSON.stringify(node))
          }) : []}>
        </Table>
        </div>
  
  )
}

export default AlertsPage