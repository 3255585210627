import React, {useState, useEffect} from "react"
import arrow_down from "../../static/selector_arrow_down.svg"
import './customDropdown.css'
import { Paper, Popover, Popper } from "@material-ui/core";

// popoveranchor -> left / right
function CustomDropDown({currentOption, options, updateOption, showPopover}) {
    const [option, selectOption] = useState(currentOption);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isPopover, setIsPopover] = useState(showPopover);
    const showDrop = Boolean(anchorEl);
    const id = showDrop ? 'simple-popover' : undefined;
    const[showList, setShowList] = useState(false)

    function toggleDrop(event){
        setAnchorEl(event.currentTarget);
    }

    const titleClick = () => { 
        setShowList(!showList)
    };

    function selectionDropItem(option) {
        handleClose()
        setShowList(!showList)
        setAsCurrent(option)
    }

    function setAsCurrent(option) {
        selectOption(option)
        updateOption(option)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const list = (
        <span className="listDropHolder">
            <span style={{ display: 'flex' }}>
                <span onClick={titleClick} className="drop-title" > {option} </span>
                <img className={showList ? "rotate180 arrow-img" : "arrow-img"} src={showList ? arrow_down : arrow_down}></img>
            </span>
            {
                options.map(listOption => (
                    <div key={listOption} style={{display: showList ? "inline-block" : "none"}}>

                        <div className='option-item' style={{ display: listOption === option ? 'none' : 'block' }} onClick={() => selectionDropItem(listOption)}>
                            <span className="option-title-list">{listOption}</span>
                        </div>
                    </div>
                ))
            }
        </span>
    );

    const popover = (
        <span className="dropHolder">
            <span onClick={toggleDrop} style={{ display: 'flex', cursor: 'pointer' }}>
                <span  className="drop-title" > {option} </span>
                <img style={{display: options.length === 0 ? "none" : "block"}} className={showDrop ? "rotate180 arrow-img" : "arrow-img"} src={showDrop ? arrow_down : arrow_down}></img>
            </span>
                <Popper
                    id={id}
                    anchorEl={anchorEl}
                    open={showDrop}
                    className="simple-popover"
                    onClose={handleClose}
                    placement="bottom-start"
                    disablePortal={true}
                    modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    },
                    }}>
                        <Paper>
                            {
                                options.map(listOption => (
                                    <div key={listOption} className='option-item' style={{ display: listOption === option ? 'none' : 'block' }} onClick={() => selectionDropItem(listOption)}>
                                        <span className="option-title">{listOption}</span>
                                    </div>
                                ))
                            }  
                        </Paper>
                    
                </Popper>
        </span>

    );

    useEffect(() => {
        if (anchorEl) {
          document.addEventListener("mousedown", handleClick)
        } else {
          document.removeEventListener("mousedown", handleClick)
        }
       // Specify how to clean up after this effect:
       return function cleanup() {
          document.removeEventListener("mousedown", handleClick)
       }
    }, [anchorEl])
    
    const handleClick = event => {
        if (!document.getElementById(id).contains(event.target)) {
           setAnchorEl(null)
           //setCustomOpen(false)
        }
     }

    return (
        <>
            {
                isPopover ? popover : list
            }
        </>
    )
}

export default CustomDropDown
