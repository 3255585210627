import React, { useState, useContext } from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import cogoToast from 'cogo-toast';
import "../comercial/create.css";
import { baseEndpoint, urlToFile } from "../../utils/utils"
import { createCeme } from "../../utils/requests/createCeme"
import { validEmail, validPhoneNumber, validPassword, hasLength, hasValue , isValidName , isValidAddress , isValidPostalCode , isValidNIF ,isValidCountry } from  "../../utils/validator";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
const axios = require('axios').default;


function CreateCemePage({location}) {
    let history = useHistory()


    const [state, setState] = useContext(AppContext);
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [personalContact, setPersonalContact] = useState('')
    const [postalCodeString, setpostalCodeString] = useState('')
    const [country, setCountry] = useState('')
    const [place, setPlace] = useState('')
    const [code, setCode] = useState ('')
    const [email, setEmail] = useState('')
    const [localization, setLocalization] = useState('')
    const [nif, setNIF] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [hasError, setError] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [postalCodeStringError, setpostalCodeStringError] = useState(false);
    const [personalContactError, setPersonalContactError] = useState(false);
    const [countryError, setCountryError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [placeError, setPlaceError] = useState(false)
    const [localizationError, setLocalizationError] = useState(false);
    const [nifError, setNifError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [fileObj, setFileObj] = useState(null);



    function goBack() {
        history.goBack()
    }

    function addCEMEEntity() {
        var registerError = false
        
        if (!hasLength(name) || !isValidName(name)) {
            setNameError('Insira um nome por favor')
            registerError = true
        } else {
            setNameError(null)
        }

        if (!hasLength(address) || !isValidAddress(address)) {
            setAddressError("Insira morada válida por favor")
            registerError = true
        } else {
            setAddressError(null)
        }
        if (!hasLength(email) || !validEmail(email)) {
            setEmailError('Insira um email válido por favor')
            registerError = true
        } else {
            setEmailError(null)
        }
        if (!hasLength(code) || !isValidName(code)) {
            setCodeError("Insira um código válido")
            registerError = true
        } else {
            setCodeError(null)
        }

        if (!hasLength(localization) || !isValidName(localization)) {
            setLocalizationError("Insira uma localização válida")
            registerError = true
        } else {
            setLocalizationError(null)
        }

        if(!hasLength(nif) || !isValidNIF(nif)) {
            setNifError("Indique um NIF")
            registerError = true
        }else {
            setNifError(null)
        }

        if(!hasLength(personalContact) || !isValidName(personalContact)){
            setPersonalContactError("Indique uma pessoa para contacto")
            registerError = true
        }else {
            setPersonalContactError(null)
        }
        
        if(!hasLength(postalCodeString) || !isValidPostalCode(postalCodeString)){
            setpostalCodeStringError("Indique um código postal válido")
            registerError = true
        }else {
            setpostalCodeStringError(null)
        }

        if(!hasLength(country) || !isValidCountry(country)){
            setCountryError("Indique um país válido")
            registerError = true
        }else {
            setCountryError(null)
        }
        if(!hasLength(phoneNumber) || !validPhoneNumber(phoneNumber)){
            setCountryError("Indique um país válido")
            registerError = true
        }else {
            setCountryError(null)
        }

        if(!hasLength(place) || !isValidName(place)){
            setPlaceError("Indique um local válido")
            registerError = true
        }else {
            setPlaceError(null)
        }

        if (registerError) {
            setError(true)
            return;
        }

        const formData = new FormData();
        let cemeObject = {}
        cemeObject.name = name
        cemeObject.address = address
        cemeObject.code = code
        cemeObject.email = email
        cemeObject.localization = localization
        cemeObject.nif = nif
        cemeObject.contactName = personalContact
        cemeObject.phoneNumber = phoneNumber
        cemeObject.postalCodeString = postalCodeString.replace(/-/g,'')
        cemeObject.place = place
        cemeObject.country = country
        cemeObject.client = state.currentClient

        formData.append('data', JSON.stringify(cemeObject))
        
        setState(state => ({ ...state, isLoading: true }))
        createCeme(formData, state, (success) => {
            history.goBack()
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success('Entidade CEME registada com sucesso!');
        }, (failure) => {
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.error('Erro ao fazer registar entidade');
        })
    }

    // Text state and helpers
    var initCode = ((value => {
        setCode(value)
        setError(false)
    }))

    var initName = ((value => {
        setName(value)
        setError(false)
    }))
    var initEmail = ((value) => {
        setEmail(value)
        setError(false)
    })
    
    var initAddress = ((value => {
        setAddress(value)
        setError(false)
    }))

    var initLocalization = ((value => {
        setLocalization(value)
        setError(false)
    }))

    var initNIF = ((value => {
        setNIF(value)
        setError(false)
    }))

    var initPersonalContact = ((value => {
        setPersonalContact(value)
        setError(false)
    }))

    var initpostalCodeString = ((value => {
        setpostalCodeString(value)
        setError(false)
    }))

    var initCountry = ((value => {
        setCountry(value)
        setError(false)
    }))

    var initPlace = ((value => {
        setPlace(value)
        setError(false)
    }))

    var initPhoneNumber = ((value => {
        setPhoneNumber(value)
        setError(false)
    }))

    return (
        <span>
            {
                <>
                    <h2>Dados CEME</h2>
                    { /* Name */}
                    <TextField
                        className="inputField"
                        id="name"
                        label="Nome"
                        variant="outlined"
                        value={name}
                        onInput={e => initName(e.target.value)}
                        error={nameError ? true : null}
                        helperText={nameError ? nameError : null}
                    />

                    { /* Address */}
                    <TextField
                        className="inputField"
                        id="address"
                        label="Morada"
                        variant="outlined"
                        value={address}
                        onInput={e => initAddress(e.target.value)}
                        error={addressError ? true : null}
                        helperText={addressError ? addressError : null}
                    />

                    

                    { /* Email */}
                    <TextField
                        className="inputField"
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onInput={e => initEmail(e.target.value)}
                        error={emailError ? true : null}
                        helperText={emailError ? emailError : null} />

                    { /* Code */}
                    <TextField
                        className="inputField"
                        id="code"
                        label="Código"
                        variant="outlined"
                        value={code}
                        onInput={e => initCode(e.target.value)}
                        error={codeError ? true : null}
                        helperText={codeError ? codeError : null} />

                    { /* Localization */}
                    <TextField
                        className="inputField"
                        id="localization"
                        label="Localização"
                        variant="outlined"
                        value={localization}
                        onInput={e => initLocalization(e.target.value)}
                        error={localizationError ? true : null}
                        helperText={localizationError ? localizationError : null} />

                    { /* Personal Contact */}
                    <TextField
                        className="inputField"
                        id="personalContact"
                        label="Pessoa Contacto"
                        variant="outlined"
                        value={personalContact}
                        onInput={e => initPersonalContact(e.target.value)}
                        error={personalContactError ? true : null}
                        helperText={personalContactError ? personalContactError : null} />

                    { /* Postal Code */}
                    <TextField
                        className="inputField"
                        id="postalCodeString"
                        label="Código Postal"
                        variant="outlined"
                        value={postalCodeString}
                        onInput={e => initpostalCodeString(e.target.value)}
                        error={postalCodeStringError ? true : null}
                        helperText={postalCodeStringError ? postalCodeStringError : null} />

                    { /* Country */}
                    <TextField
                        className="inputField"
                        id="country"
                        label="País"
                        variant="outlined"
                        value={country}
                        onInput={e => initCountry(e.target.value)}
                        error={countryError ? true : null}
                        helperText={countryError ? countryError : null} />

                    { /* PhoneNumber */}
                    <TextField
                        className="inputField"
                        id="country"
                        label="Número de Telefone"
                        variant="outlined"
                        value={phoneNumber}
                        onInput={e => initPhoneNumber(e.target.value)}
                        error={phoneError ? true : null}
                        helperText={phoneError ? phoneError : null} />

                    { /* Place */}
                    <TextField
                        className="inputField"
                        id="place"
                        label="Local"
                        variant="outlined"
                        value={place}
                        onInput={e => initPlace(e.target.value)}
                        error={placeError ? true : null}
                        helperText={placeError ? placeError : null} />


                    { /* Nif */}
                    <TextField
                        className="inputField"
                        id="nif"
                        label="NIF"
                        variant="outlined"
                        value={nif}
                        onInput={e => initNIF(e.target.value)}
                        error={nifError ? true : null}
                        helperText={nifError ? nifError : null} />
                    {
                        <div style={{marginBottom: "20px"}}>
                            <Button className="simple-button" onClick={goBack}>Voltar</Button>
                            <Button style={{ float: "right" }} className="simple-button" onClick={addCEMEEntity}>Adicionar Entidade CEME</Button>
                        </div>
                    }
                </>
            }
        </span>
    )
}


export default CreateCemePage