import React, { useState, useContext, useEffect } from "react"
import './forgot.css'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import logo from "../static/Logo.png"
import { FormGroup, Paper } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import cogoToast from 'cogo-toast';
import Constants from '../constants';
import { Redirect, useHistory } from "react-router-dom";
import { initState } from "../context/AppContext";
import { forgotPass } from "../utils/requests/forgotPassword"


const axios = require('axios').default;



function ForgotPage({ }) {
    const [state, setState] = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [hasError, setError] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [approved, setApproved] = useState(false);

    var initEmail = ((pass) => {
        setEmail(pass)
        setError(false)
    })

    function handleSubmit(event) {
        event.preventDefault();
        var forgotError = false

        if (email.length == 0) {
            setEmailError("Insira email")
            forgotError = true
        } else {
            setEmailError(null)
        }

        if (forgotError) {
            setError(true)
            return
        }

        setState(state => ({ ...state, isLoading: true }))
        forgotPass(email, (success) => {
            setError(false)
            setApproved(true)
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success("Successo! Verifique o seu email")
        }, (failure) => {
            cogoToast.error("Erro. Tente novamente")
            setState(state => ({ ...state, isLoading: false }))
        })

        
    }

    useEffect(() => {
    });

    if(approved) {
        return <Redirect to="/" />
    }

    return (
        <>
            <div className="formContainerPassword">
                <form margin='dense' id="formLogin" autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl>
                        {/* Email */}
                        <TextField
                            className="inputLogin"
                            id="loginEmail"
                            label="Email"
                            variant="outlined"
                            value={email}
                            onInput={e => initEmail(e.target.value)}
                            error={hasError ? true : null}
                            helperText={hasError ? emailError : null}
                        />

                        <Button
                            type="submit"
                            id="loginBtn"
                            variant="contained"
                            color="primary">Enviar Email</Button>
                    </FormControl>
                </form>
            </div>
        </>
    )
}


export default ForgotPage