import React, { useContext, useState } from "react"
import Layout from "../../components/layout"
import Indicator from "../../components/entityindicators/indicator"
import TopButtons from "../../components/topBtn/top-btn"
import Table from "../../components/table/table"
import CustomModal from "../../components/modal/modal"
import CEME_ENTITIES_QUERY from "../../queries/ceme_entities";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { AppContext, checkIfUnauthorized } from "../../context/AppContext"
import { removeCEME } from "../../utils/requests/removeCeme"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import cogoToast from "cogo-toast"

const CemeEntities = () => {
  let history = useHistory()
  const [state, setState] = useContext(AppContext);
  let clientId = state.currentClient.id
  let query = CEME_ENTITIES_QUERY(clientId)
  const { data, loading, error, refetch } = useQuery(query);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cellDelete, setCellDelete] = useState(null);

  let columns = [
    { title: "Código", field: "code", headerFilter: true, isActive: true },
    { title: "Nome", field: "name", headerFilter: true, isActive: true },
    { title: "Email", field: "email", isActive: true },
    { title: "NIF", field: "nif", isActive: true },
    { title: "Pessoa Contacto", field: "contactName", isActive: true },
    { title: "Morada", field: "address", headerFilter: true, isActive: true },
    { title: "Código Postal", field: "postalCodeString", headerFilter: true, isActive: true },
    { title: "Localidade", field: "place", headerFilter: true, isActive: true },
    { title: "País", field: "country", headerFilter: true, isActive: true },
    /*{ formatter:"buttonCross", width:40, align:"center", cellClick:function(e, cell){
      setCellDelete(cell)
      setDialogOpen(true);
      e.stopPropagation();
    }}*/
  ];

  function deleteCEME() {
    setState(state => ({ ...state, isLoading: true }))

    // We are going to update the partner client list
    // We don't delete the partner, just cut the relation 
    let ceme = cellDelete.getRow().getData()
    removeCEME(state, ceme,  (success) => {
      cellDelete.getRow().delete();
      setCellDelete(null)
      setState(state => ({ ...state, isLoading: false }))
      cogoToast.success('CEME removido com sucesso');
      refetch()
    }, (error) => {
      setCellDelete(null)
      setState(state => ({ ...state, isLoading: false }))
      let wasUnauthorized = false
      if (error.response) {
        wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
      }
      if (!wasUnauthorized) {
        cogoToast.error('Erro ao remover CEME');
      }
    })

    setDialogOpen(false);
  }

  
  
  const handleClose = () => {
    setDialogOpen(false);
  };

  const deleteCemeDialog = (
    <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">Tem a certeza que deseja remover este CEME?</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">Não</Button>
        <Button onClick={deleteCEME} color="primary" autoFocus>Sim</Button>
        </DialogActions>
    </Dialog>
  );


  function showCemeEntity(cemeEntity){
    history.push('/admin/ceme_entities/ceme_details', cemeEntity)
  }
  console.log(data ? data.cemeEntities.map( node => {
    return JSON.parse(JSON.stringify(node))
  }) : [])
  return (
   <>
      <span>
        <TopButtons buttons = {['Exportar PDF', 'Adicionar Entidade CEME']}/>
        <Table
          isLoading={loading}
          mode="allEnabled"
          columns={columns}
          itemClicker={showCemeEntity}
          data={data ? data.cemeEntities.map(node => {
            return JSON.parse(JSON.stringify(node))
          }) : []}>
        </Table>
      </span>

      {/* Delete partner dialog */}
      {deleteCemeDialog}
   </>
  )
}

export default CemeEntities