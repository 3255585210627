import React, { useState, graphql, useEffect } from "react"
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./index.css";
import CemePage from "./pages/ceme";
import ChargerPage from "./pages/chargers/chargers";
import ChargerDetailsPage from "./pages/chargers/details";
import OPCPage from "./pages/opc";
import CemeEntitiesPage from "./pages/admin/ceme_entities"
import OPCEntitiesPage from "./pages/admin/opc_entities"
import BalanceCheckPage from "./pages/admin/balance_check";
import CrudUsersPage from "./pages/admin/crud_users";
import AlertsPage from "./pages/admin/alerts";
import NotFoundPage from "./pages/404";
import ComercialPage from "./pages/comercial";
import ProfilePage from "./pages/profile";
import ForgotPage from "./pages/forgot";
import DetailPartnerPage from "./pages/comercial/detail";
import CemeDetailsPage from "./pages/admin/ceme_details";
import OpcDetailsPage from "./pages/admin/opc_details";
import CreatePartnerPage from "./pages/comercial/create";
import CreateUsersPage from "./pages/admin/crud_users/create";
import AddPartnerPcs from "./pages/comercial/addpc";
import CreateOPCPage from "./pages/admin/create_opc";
import CreateCemePage from "./pages/admin/create_ceme";
import "./index.css"
import { LinearProgress } from "@material-ui/core";
import Layout from "./components/layout";
import { AppContext, getApolloInstance, getQueryClientInstance } from "./context/AppContext";
import Login from "./pages/login"
import { QueryClient, QueryClientProvider } from 'react-query'

import LoginPage from "./pages/login";
import ConfirmPage from "./pages/confirm";
import DefinePasswordPage from "./pages/definepassword";

import { createBrowserHistory } from "history";

function App() {
  const [state, setState] = useState(AppContext._currentValue);

  let isForgotOrPassword = (() => {
    let path = window.location.pathname
    return path == "/forgot" || path == "/define-password"
  })()

  // let clientId = state.currentClient.id

  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <AppContext.Provider value={[state, setState]}>
        {state.isLoading ? <span className="loadingBar"><LinearProgress /> </span> : <></>}
        {state.currentUser != null && !isForgotOrPassword ?
        <QueryClientProvider client={getQueryClientInstance()}>
          <ApolloProvider client={getApolloInstance(state, setState)}>
                  {
                    state.isPartnerEmployee ? 
                    <Switch>
                          <Route exact path="/chargers">
                            <Redirect to="/" />
                          </Route>
                          <RouteWrapper path="/" exact={true} component={DetailPartnerPage}/>
                          <RouteWrapper path="/profile" exact={true} component={ProfilePage}/>
                          <RouteWrapper path="*" component={NotFoundPage} />
                    </Switch>
                    :
                    <Switch>
                          <RouteWrapper path="/ceme" exact={true} component={CemePage}/>
                          <RouteWrapper path="/opc" exact={true} component={OPCPage}/>
                          <RouteWrapper path="/chargers" exact={true} component={ChargerPage}/>
                          <RouteWrapper path="/chargers/detail/:chargerid" exact={true} component={ChargerDetailsPage}/>
                          <RouteWrapper path="/comercial" exact={true} component={ComercialPage}/>
                          <RouteWrapper path="/comercial/detail/:partnerid" exact={true} component={DetailPartnerPage}/>
                          <RouteWrapper path="/admin/opc_entities/opc_details/:opcid" exact={true} component={OpcDetailsPage}/>
                          <RouteWrapper path="/admin/ceme_entities/ceme_details" exact={true} component={CemeDetailsPage}/>
                          <RouteWrapper path="/comercial/detail/:partnerid/create" exact={true} component={CreateUsersPage}/>
                          <RouteWrapper path="/comercial/create" exact={true} component={CreatePartnerPage}/>
                          // TODO make it as a sub of details WITH partner id
                          // /comercial/detail/:partnerid/addpc
                          <RouteWrapper path="/comercial/addpc"  component={AddPartnerPcs}/>
                          <RouteWrapper path="/admin/crud_users/create" exact={true} component={CreateUsersPage}/>
                          <RouteWrapper path="/admin/opc_entities/create_opc" exact={true} component={CreateOPCPage}/>
                          <RouteWrapper path="/admin/ceme_entities/create_ceme" exact={true} component={CreateCemePage}/>
                          <RouteWrapper path="/admin/ceme_entities" exact={true} component={CemeEntitiesPage}/>
                          <RouteWrapper path="/admin/opc_entities" exact={true} component={OPCEntitiesPage}/>
                          <RouteWrapper path="/admin/balance_check" exact={true} component={BalanceCheckPage}/>
                          <RouteWrapper path="/admin/crud_users" exact={true} component={CrudUsersPage} />
                          <RouteWrapper path="/admin/alerts" exact={true} component={AlertsPage}/>
                          <RouteWrapper path="/profile" exact={true} component={ProfilePage}/>
                          <Route exact path="/">
                            <Redirect to="/chargers" />
                          </Route>
                          <RouteWrapper path="*" component={NotFoundPage} />
                        </Switch>
                      }
          </ApolloProvider>
          </QueryClientProvider>
          :
          <Switch>
            <Route path="/" exact={true} component={LoginPage}/>
            <Route path="/forgot" exact={true} component={ForgotPage}/>
            <Route path="/define-password" component={DefinePasswordPage}/> 
            <Redirect from="/*" to="/" exact /> 
          </Switch>
        }
      </AppContext.Provider>
    </Router>
  );
}
// http://localhost:5000/confirm?confirmation=b369a44924e9fd8a9177435e863a53f2c57a16b7
// Se não tiver o ?confirm atira para o NotFoundPage

// leva layout como parametro para meter à volta, senão usa o Layout default
function RouteWrapper({
  component: Component, 
  layout: LayoutParam, 
  ...rest
}) {
  LayoutParam = LayoutParam || Layout;
  return (
    <Route {...rest} render={(props) =>
      <LayoutParam {...props}>
        <Component {...props} />
      </LayoutParam>
    } />
  );
}

export default App
ReactDOM.render(
  <App />,
  document.getElementById('root')
);