import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;


export function createOPC(formData, state, success, failure) {
    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
        headers: {
            Authorization: currentToken,
            'Content-Type': 'multipart/form-data'
        }
    }
    
    axios.post(baseEndpoint + endpoints.CREATE_OPC.value, formData, config)
    .then((data) => {
        success()
    }).catch((error) => {
        console.log(error)
        failure()
    })
}