// Period -> grafico A ultimas 24 ultima semana ultimo mes escolher mes em específico
// QUando é mes em específico aparece outro drop paa escolher o mes

// Dataset -> Popup para esoclher um ceme ou um conjunto de cemes

// Aparece sempre legenda com os cemes que estão a ser visualizados
// Types -> "bar", "line"
// Titles CEME -> "Carregamentos" "Energia" "Preço Total" "Duração"
// Titles OPC -> "Energia Total" "Duração Total"
// EntityTypes -> "opc", "ceme"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {useRef, useState} from "react"
import './chart.css'
import '../../components/entityindicators/indicator.css'
import '../../components/table/table.css'
import CustomDropDown from "../drop/customDropdown"
import ChartGraph from "./chartGraph"
import {IconButton} from "@material-ui/core"
import CheckboxList from '../checkboxlist/checkboxlist';
import DoneIcon from '@material-ui/icons/Done';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import {getDateFromUsage, getHours, getLastMonth, getLastYear, getWeekdays} from "../../utils/utils";
import seedColor from 'seed-color';

function Chart({dataQuery, isLoading, entityType, title, type, showDropdownDate}) {
    const last24 = "Últimas 24H";
    const lastWeek = "Última semana";
    const lastMonth = "Último mês";
    const lastYear = "Último ano";
    const options = [lastWeek, lastMonth, lastYear]
    const [currentOption, setCurrentOption] = useState(options[0]);
    const [showingEntities, setShowingEntities] = useState(false);
    const [filteredEntityList, setFiltered] = useState({})

    let data = [];
    let graphKeys = [];
    let entitiesList = [];

    function toggleSeeingEntities() {
        setShowingEntities(!showingEntities)
    }

    function getXFromCurrentOption() {
        switch (currentOption) {
            case last24:
                return getHours();
            case lastWeek:
                return getWeekdays();
            case lastMonth:
                return getLastMonth();
            case lastYear:
                return getLastYear();
        }
    }

    function getQuantityFromTitleType(chargeNode) {
        if (entityType === "opc") {
            switch (title) {
                case "Energia Total":
                    return chargeNode.energia_total_transacao
                case "Duração Total":
                    return chargeNode.totalDuration
            }
        } else if (entityType === "ceme") {
            switch (title) {
                case "Carregamentos":
                    return chargeNode.numero_carregamentos
                case "Energia":
                    return chargeNode.energia_total
                case "Duração":
                    return chargeNode.duracao_total
                case "Preço Total":
                    return chargeNode.preco_opc_total
            }
        }

    }


    function timeArrayForEach(timeArray, dateDictionary, mostQuantityEntity, selectedObjectList, keyArray, newDataArray) {
        timeArray.forEach(timeNode => {
            if (dateDictionary[timeNode.dateFormat] != null) {
                let newObj = {}
                newObj["time"] = timeNode.toString

                dateDictionary[timeNode.dateFormat].forEach(cemeCounter => {

                    if (mostQuantityEntity != null) {
                        if (mostQuantityEntity == cemeCounter.name) {
                            selectedObjectList[cemeCounter.name] = true
                            keyArray[cemeCounter.name] = {name: cemeCounter.name, color: cemeCounter.nameColor}
                            newObj[cemeCounter.name] = cemeCounter.quantity
                        }
                    } else {
                        if (filteredEntityList[cemeCounter.name] === true) {
                            keyArray[cemeCounter.name] = {name: cemeCounter.name, color: cemeCounter.nameColor}
                            newObj[cemeCounter.name] = cemeCounter.quantity

                        }
                    }
                    selectedObjectList[cemeCounter.name] = filteredEntityList[cemeCounter.name]
                })
                newDataArray.push(newObj);
            }
        })

        if (mostQuantityEntity != null) {
            selectedObjectList[mostQuantityEntity] = true
        }
    }

    function chargersData(dateDictionary, timeArray, keyArray, newDataArray) {
        dataQuery.chargers.forEach(charger => {

            let dateString = moment(charger.idDay).format("MM/YYYY").toString();

            if (dateDictionary[dateString] == null)
                dateDictionary[dateString] = []
            if (charger.idChargingStation)
                dateDictionary[dateString].push({
                    name: charger.idChargingStation,
                    nameColor: seedColor(charger.idChargingStation.toString()).toHex(),
                    quantity: charger.preco_opc
                })

        })

        timeArray.forEach(timeNode => {
            let dateFormatted = moment(timeNode).format("MM/YYYY").toString();
            if (dateDictionary[dateFormatted] != null) {
                let newObj = {}
                newObj["time"] = dateFormatted
                dateDictionary[dateFormatted].forEach((usage) => {
                    var amount = 0;
                    dateDictionary[dateFormatted].forEach(x => {
                        if (x.name == usage.name)
                            amount += x.quantity
                    })

                    keyArray[usage.name] = {name: usage.name, amount: amount, color: usage.nameColor}
                    newObj[usage.name] = Math.round(amount * 100) / 100
                })
                newDataArray.push(newObj);
            }
        })
        graphKeys = Object.values(keyArray).sort((a, b) => (a.amount <= b.amount) ? 1 : ((b.amount <= a.amount) ? -1 : 0));
    }

    function cemeData(dateDictionary, timeArray, selectedObjectList, keyArray, newDataArray) {
        dataQuery.cemes.forEach(ceme => {
            let dateString = (ceme.dia_referencia || "").slice(0, 10)
            if (dateDictionary[dateString] == null) {
                dateDictionary[dateString] = []
            }

            let name = ceme.ceme_id
            if (name) {
                let color =  seedColor(name).toHex()
                let quantity = getQuantityFromTitleType(ceme)
                dateDictionary[dateString].push({name: name, nameColor: color, quantity: quantity})
            }
        })


        // check for the most quantity value in case filteredEntityList is empty
        // so this variables here are state for this check
        let mostQuantityEntity = null; // This variable will have the name of the entity with highest global records
        if (filteredEntityList == null || Object.keys(filteredEntityList).length === 0) {
            // We have to choose the entity with the most quantity of the metric
            let counter = {} // We'll keep here a record of entity : Quantity
            timeArray.forEach(timeNode => {
                if (dateDictionary[timeNode.dateFormat] != null) {
                    dateDictionary[timeNode.dateFormat].forEach(cemeCounter => {
                        if (!(cemeCounter.name in counter)) {
                            counter[cemeCounter.name] = 0
                        }

                        counter[cemeCounter.name] = Number(counter[cemeCounter.name]) + Number(cemeCounter.quantity)
                    })
                }
            })

            var currentHighestValue = 0

            for (var [key, value] of Object.entries(counter)) {

                if (value > currentHighestValue) {
                    currentHighestValue = value
                    mostQuantityEntity = key
                }
            }
        }
        timeArrayForEach(timeArray, dateDictionary, mostQuantityEntity, selectedObjectList, keyArray, newDataArray);
        graphKeys = Object.values(keyArray).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    function opcData(dateDictionary, timeArray, selectedObjectList, keyArray, newDataArray) {
        dataQuery.opcs.forEach(usage => {
            let date = getDateFromUsage(usage.startTimestamp) // TODO: Em vez de criar a date, criar logo a string como fazes nos passos seguintes
            let startTimeStamp = null
            if (currentOption === last24) {
                startTimeStamp = date.printDateWithHours()
            } else {
                startTimeStamp = date.standardPrint()
            }

            if (dateDictionary[startTimeStamp] == null) {
                dateDictionary[startTimeStamp] = []
            }

            let name = usage.idServiceProvider
            let color = seedColor(name).toHex()
            let quantity = getQuantityFromTitleType(usage)
            dateDictionary[startTimeStamp].push({name: name, nameColor: color, quantity: quantity})
        })

        // check for the most quantity value in case filteredEntityList is empty
        // so this variables here are state for this check
        var mostQuantityEntity = null // This variable will have the name of the entity with highest global records
        if (filteredEntityList == null || Object.keys(filteredEntityList).length === 0) {

            // We have to choose the entity with the most quantity of the metric
            let counter = {} // We'll keep here a record of entity : Quantity
            timeArray.forEach(timeNode => {
                if (dateDictionary[timeNode.dateFormat] != null) {
                    dateDictionary[timeNode.dateFormat].forEach(usage => {
                        if (counter[usage.name] == null) {
                            counter[usage.name] = 0
                        }
                        counter[usage.name] = Number(counter[usage.name]) + Number(usage.quantity)
                    })
                }
            })
            var currentHighestValue = 0
            for (var [key, value] of Object.entries(counter)) {
                if (value > currentHighestValue) {
                    currentHighestValue = value
                    mostQuantityEntity = key
                }
            }
        }


        timeArrayForEach(timeArray, dateDictionary, mostQuantityEntity, selectedObjectList, keyArray, newDataArray);
        graphKeys = Object.values(keyArray).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    function prepareChart() {
        if (!dataQuery)
            return;

        let timeArray = (showDropdownDate === undefined || showDropdownDate) ? getXFromCurrentOption() : dataQuery.months;
        let selectedObjectList = {}
        let dateDictionary = {}
        let keyArray = {}
        let newDataArray = []

        switch (entityType) {
            case "ceme":
                cemeData(dateDictionary, timeArray, selectedObjectList, keyArray, newDataArray);
                break;
            case "opc":
                opcData(dateDictionary, timeArray, selectedObjectList, keyArray, newDataArray);
                break;
            case "charger":
                chargersData(dateDictionary, timeArray, keyArray, newDataArray);
                break;
        }

        data = newDataArray;
        entitiesList = selectedObjectList;

    }


    prepareChart()
    const childRef = useRef();
    return (
        <>
            <div className="chart-card box-shadow chart">
                {
                    isLoading || (!dataQuery) ?
                        <Skeleton className="chartSkeleton" variant="text"/>
                        :
                        <>

                            <div className="topHolder">
                                {showingEntities ?
                                    <IconButton className="backBtnChart" onClick={toggleSeeingEntities}>
                                        <ArrowBackIcon/> </IconButton>
                                    :
                                    <></>
                                }
                                <div
                                    className="table-title-text chart-title">{showingEntities ? "Entidades" : title}</div>

                                {showingEntities ?
                                    <IconButton onClick={() => childRef.current.getAlert()}> <DoneIcon/> </IconButton>
                                    :
                                    showDropdownDate ?
                                        <span className="chartTitleANdIcon">
                                <CustomDropDown currentOption={currentOption} options={options}
                                                updateOption={setCurrentOption} showPopover={true}/>
                                            {
                                                (entitiesList == null || Object.entries(entitiesList).length == 0) ?
                                                    <></>
                                                    :
                                                    <IconButton onClick={toggleSeeingEntities}> <SettingsIcon/>
                                                    </IconButton>
                                            }
                            </span>
                                        : ""
                                }
                            </div>

                            <div className="chart-holder">
                                {showingEntities ?
                                    <CheckboxList ref={childRef} viewType={"graph"} type="ceme" entities={entitiesList}
                                                  toggleList={toggleSeeingEntities} setFiltered={setFiltered}/>
                                    :
                                    (data == null || data.length == 0) ?
                                        <div className="noContentHolder">
                                            <span className="noContentTitle">Sem conteúdo a apresentar</span>
                                        </div>
                                        :
                                        <ChartGraph data={data} graphKeys={graphKeys} type={type}/>
                                }
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default Chart