import React, { useContext, useState, useEffect } from "react"
import Table from "../../components/table/table";
import { AppContext, checkIfUnauthorized,  } from "../../context/AppContext";
import "../../utils/text_field.css"
import { Button, Input, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createDatePrice } from "../../utils/requests/createDatePrice"
import { convertDayString } from "../../utils/utils"
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt";
import { OPC_ENTITY } from "../../queries/opc_entities";
import { useQuery } from "@apollo/client";
import Moment from "moment";
import cogoToast from "cogo-toast";
import "./opc_details.css";
import { baseEndpoint, UnitSymbols } from "../../utils/utils"

function OpcDetails({ match, location }) {
    const [state, setState] = useContext(AppContext);
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [dateObject, setDateObject] = useState({date: '', preco_unitario_opc_tempo: ''})
    const [dateError, setDateError] = useState("");
    const [priceError, setUnitPriceError] = useState(false);
    const [hasError, setError] = useState(false);

    let opcObject = location.state;

    let opcid = opcObject.id || match.params.opcid;
  
    let datePriceColumns = [
        { title: "Data", field: "date", headerFilter: true, isActive: true, sorter:"date", sorterParams:{format:"YYYY-MM-DD"}},
        { title: "Preço Unitário" + UnitSymbols.value, field: "preco_unitario_opc_tempo", headerFilter: true, isActive: true },
    ]

    const { data, loading, error , refetch} = useQuery(OPC_ENTITY(opcid), {fetchPolicy: "no-cache" });
    
    if(!opcObject && !data){
        return (<></>)
    }
    
    opcObject = (data && data.opcEntities.length) ? data.opcEntities[0] : opcObject;
      
    

    let datePrices = JSON.parse(JSON.stringify(opcObject.date_prices ? opcObject.date_prices : []))
    let datePricesTable = JSON.parse(JSON.stringify(opcObject.date_prices ? opcObject.date_prices : [])).map(dateP => {
        dateP.date = convertDayString(dateP.date);
        return dateP
    })
    
    
    function updateDatePrice() {
        var updateError = false

        if(!dateObject.date.length > 0){
            setDateError("Por favor insira uma data válida")
            updateError = true
        } else if (!checkDateAfterLastDate(dateObject.date)) {
            setDateError("Por favor insira uma data válida")
            updateError = true
        } else {
            setDateError(null)
        }

        if(!dateObject.preco_unitario_opc_tempo > 0){
            setUnitPriceError("Por favor insira um valor válido")
            updateError = true 
        }else {
            setUnitPriceError(null)
        }
        
        if (updateError) {
            return;
        }

        //opcObject.date_prices.push(dateObject)
        let dataObject = {}
        dataObject.date = dateObject.date
        dataObject.preco_unitario_opc_tempo = dateObject.preco_unitario_opc_tempo
        dataObject.opc_entity = opcObject.id
        setState(state => ({ ...state, isLoading: true }))
        createDatePrice(dataObject, state, (success) => {
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success('Valores adicionados com sucesso');
            refetch()
        }, (error) => {
            setState(state => ({ ...state, isLoading: false }))
            let wasUnauthorized = false
            if (error && error.response) {
                wasUnauthorized = checkIfUnauthorized(error.response.status, state, setState)
            }
            if (!wasUnauthorized) {
                cogoToast.error('Erro ao adicionar valores. Tente outra vez.');
            }
        })
    }

    

    function getCurrentDate() {
        return Moment().format("YYYY/MM/DD");
    }

    var initUnitPrice = ((value => {
        dateObject.preco_unitario_opc_tempo = parseFloat(value)
        setDateObject(dateObject)
    }))

    function checkDateAfterLastDate(date) {
        let lastDate = datePrices && datePrices.length > 0 ? datePrices[datePrices.length - 1].date : null
        if (lastDate) {
          return date > lastDate
        } else {
          return true
        }
    }

    const handleDateChange = (date) => {
        let dateObjectFormatted = Moment(new Date(date)).format("YYYY-MM-DD");
        dateObject.date = dateObjectFormatted
        if(!checkDateAfterLastDate(dateObjectFormatted)) {
            setDateError("Data tem de ser posterior")
        } else {
            setDateError(null)
            setDateObject(dateObject)
            setSelectedDate(dateObjectFormatted);
        }
    };

    return (
        <span>
            <TextField className="custom-text-margin" readOnly label="Código" value={opcObject.code !== undefined && opcObject.code !== "" ? opcObject.code : "N/A"} />
            <TextField className="custom-text-margin" readOnly label="Nome" value={opcObject.name !== undefined && opcObject.name !== "" ? opcObject.name : "N/A"} />
            <TextField className="custom-text-margin" readOnly label="País" value={opcObject.country !== undefined && opcObject.country !== "" ? opcObject.country : "N/A"} />
            <TextField className="custom-text-margin" readOnly label="Localidade" value={opcObject.localization !== undefined && opcObject.localization !== "" ? opcObject.localization : "N/A"} />
            <TextField className="custom-text-margin" readOnly label="Morada" value={opcObject.address !== undefined && opcObject.address !== "" ? opcObject.address : "N/A"} />
            <TextField className="custom-text-margin" readOnly label="Pessoa Contacto" value={opcObject.contactName !== undefined && opcObject.contactName !== "" ? opcObject.contactName : "N/A"} />
            <TextField className="custom-text-margin" readOnly label="NIF" value={opcObject.nif !== undefined && opcObject.nif !== "" ? opcObject.nif : "N/A"} />

            <span style={{ marginTop: "25px"}}>
            <Table
                boxtitle="Lista de DatePrices"
                columns={datePriceColumns}
                data={datePricesTable}
                mode="">
            </Table>
        
            </span>
            
            <span className="dateLine">
                { /* Date */}
                {/* <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label="Data"
                        className="dateDate"
                        
                        value={selectedDate}
                        error={dateError ? dateError : null}
                        helperText={dateError ? dateError : null}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        autoOk/>
                </MuiPickersUtilsProvider> */}

                { /* Unite Price */}
                {/* <TextField
                    className="textDate"
                    id="unitPrice"
                    label="Preço Unitário"
                    variant="standard"
                    type="number"
                    value={dateObject.price}
                    onInput={e => initUnitPrice(e.target.value)}
                    error={ priceError? true : null}
                    helperText={priceError ? priceError : null}/>

                <Button className="addDateBtn" onClick={updateDatePrice} color="primary">Adicionar Valores</Button> */}
            </span>
        </span>
    )
}
export default OpcDetails