import gql from "graphql-tag";

const CEMES_QUERY = (clientId) => gql`
query cemes {
  cemes(where: 
    { client: 
        {
          id: ${clientId}
        }
    }
  )
  {
    id
    metricas_ceme {
        ceme_id
        duracao_total
        energia_total
        id
        numero_carregamentos
        preco_opc_total
    }
    opc_id
    dia_referencia
    file_name
  }
}
`;

export default CEMES_QUERY;