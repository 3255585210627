import React, {useState, useContext} from "react"
import ptLocale from "date-fns/locale/pt"; // sorry no pt-PT
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import format from "date-fns/format";
import cogoToast from "cogo-toast";

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "dd MMMM yyyy", {locale: ptLocale});
    }
    dateFormat = "dd MMMM yyyy"
}


// example: <DatePicker name="searchdate" label="Mês e Ano" defaultValue={new Date()}   change={changeSearch}/>
const MyDatePicker = ({defaultValue, change, compareDate, ...props}) => {
    const [selectedDate, handleDateChange] = useState(defaultValue);
    //defaultValue && selectedDate && defaultValue.toString() != selectedDate.toString() && handleDateChange(defaultValue);
    let date = selectedDate;

    return (<MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptLocale}>
        <input name={props.name + "input"} hidden /*value={date && date.toISOString()}*//>
        <DatePicker
            {...props}
            variant="inline"
            openTo="month"
            views={["year", "month"]}
            value={date}
            onChange={(x) => {
                if ((props.isTo && x < compareDate) || (!props.isTo && x > compareDate) || props.isTo == undefined) {
                    cogoToast.error("Por favor insira uma data válida")
                } else {
                    handleDateChange(x);
                    document.querySelector("[name=" + props.name + "input" + "]").value = x.toISOString();
                    change && change(x.toISOString());
                }
            }}
        />
    </MuiPickersUtilsProvider>)

}

export default MyDatePicker;