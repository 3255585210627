import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;

export function removePartner(state , partner, success, failure) {

    let currentToken = state.currentToken
    currentToken = currentToken ? `Bearer ${currentToken}` : ""
    let config = {
      headers: {
        Authorization: currentToken,
      } 
    }

    console.log(currentToken)
    axios.delete(baseEndpoint + endpoints.PARTNER_ENDPOINT.value + partner.id, config)
    .then((response) => {
        success()
    })
    .catch((error) => {
        failure(error)
    })
}