import { baseEndpoint } from '../utils';
import { endpoints } from '../../utils/endpoints';
const axios = require('axios').default;

export function resetPassword(token, password, secondPassword, success, failure){
    axios.post(baseEndpoint + endpoints.RESET_PASS_ENDPOINT.value, {
        code: token, // code contained in the reset link of step 3.
        password: password,
        passwordConfirmation: secondPassword,
    }).then((data) => {
        success()
    }).catch((error) => {
        failure(error)
    })
}