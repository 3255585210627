/**
 * Layout component that display top bar
 */

import '../../css/variables.css'
import "./top-bar.css"
import notification_icon from "../../static/notification.png"
import logout_icon from "../../static/logout.png"
import search_icon from "../../static/search-btn.png"
import Constants from '../../constants';
import React, {useState, useEffect} from "react"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AppContext, logoutUser } from "../../context/AppContext";
import { useContext } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { AppBar, Icon, IconButton, Toolbar, Typography } from '@material-ui/core'
function TopBar({title, toggleMenu, logoutUser}) {
    const [state, setState] = useContext(AppContext);
    
    const logoutIcon = (
        <Icon>
          <img src={logout_icon}  />
        </Icon>
      );
      
    return (
        <AppBar className="top-bar">
            <Toolbar>
                <IconButton className="hamburguer" onClick={toggleMenu} edge="end"> <MenuIcon/> </IconButton>   
                <Typography variant="h6" className="topBarTitle">{title}</Typography>
                
                <div className="spacer"></div>
                <IconButton className="logout-icon" onClick={logoutUser} edge="end"> {logoutIcon} </IconButton>   
            </Toolbar>
        </AppBar>
    )
}



export default TopBar

