import gql from "graphql-tag";

const LAYOUT_QUERY = gql`
query LayoutQuery {
    clients {
      id
      logo {
          url
      }
    }
    menuHolders {
      Order
      id
      image {
          url
      }
      title
      pathName
      submenus {
          id
          pathName
          title
      }
      showPartner
    }
}
`
export default LAYOUT_QUERY;